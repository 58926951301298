<input class="form-control input-number__input" type="number"
       [ngClass]="{'form-control-sm': size === 'sm', 'form-control-lg': size === 'lg'}"
       [min]="options.min"
       [max]="options.max"
       [value]="value"
       [disabled]="options.disabled"
       [readOnly]="options.readonly"
       (input)="input()"
       (blur)="onTouched()" #inputElement>

<div class="input-number__add" (mousedown)="add()"></div>
<div class="input-number__sub" (mousedown)="sub()"></div>
