import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CartService } from '../../services/cart.service';
import { ProductModel } from '../../interfaces/product';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { QuickviewService } from '../../services/quickview.service';
import { RootService } from '../../services/root.service';
import { CurrencyService } from '../../services/currency.service';
import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AccountLoginService } from '../../../modules/account/services/account-login.service';
import { ThrowStmt } from '@angular/compiler';


interface ProductImage {
    id: string;
    url: string;
    active: boolean;
}

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy {
    private destroy$: Subject<void> = new Subject();

    @Input() product: ProductModel;
  
    @Input() layout: 'grid-sm'|'grid-nl'|'grid-lg'|'list'|'horizontal'|null = null;
    
    @Input() showStock: boolean = true;

    @Input() id:string;

   
    
    quantityControl: FormControl;
    quantityInCart : number;
    openShop:boolean;
    
    addingToCart = false;
    removingFromCart = false;
    toggleProducts: ProductModel[] = [];
    togglingWishlist = false;
    productInWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    updateCartCount : number = 0;
    stock :number;
    user={} as UserDataModel;
    showInventory:boolean;
    stockInfo:string ="";
    addToCartBtn=false;
    showMessage=true;
    imageUrl="";
    productName="";
    offerPrice;
    actualPrice;
    loading:boolean=false;
    catalogPreview:boolean=false;
    
    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private router: Router,
        private accountService: AccountLoginService,
    ) { }

    ngOnInit(): void {

        if(sessionStorage.getItem('catalogId') === null)
        
            this.catalogPreview = false 
            else
            this.catalogPreview = true;
        

        if(sessionStorage.getItem('openShop') ==="true")
        
            this.openShop=true;

        else 
       
        this.openShop=false;
        this.user =  this.accountService.getLocalUser(); 
        this.productName = this.productName;
        this.checkIfProductIsOutOfStock();
        
        this.findName();
        this.findPrice();
        this.findImage();
        this.checkIfProductIsFavourite();
        this.getCurrentQuantity(this.product);
        this.cartChangeDetector();
        
        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
        
    }

    findImage(){
        
        if(this.product.isThisSku)
        {
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.skuId === this.product.isThisSku)
                    if(sku.skuImage)
                       this.imageUrl = sku.skuImage;
                       
                    else if(!sku.skuImage && this.product.productImage)
                    this.imageUrl =this.product.productImage[0];  
                    
                    })
            }
            
        }
        else{
        if(this.product.activeSku && this.product.productImage){
            if(this.product.activeSku.skuImage)
            this.imageUrl = this.product.activeSku.skuImage
            else
            this.imageUrl = this.product.productImage[0];
            
        }
        else
        {
            if(this.product.images.length > 0)
            this.imageUrl = this.product.images[0]; 
        }
        }
       
         if(this.imageUrl.includes('no_Img.png'))
         this.imageUrl="invalidURL"
    }

    findName(){

        if(this.product.isThisSku)
        {
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.skuId === this.product.isThisSku)
                    
                       this.productName= sku.skuShortName;
                    
                    })
            }
            
        }
        if(!this.product.isThisSku && this.id === "Favourites"){
            
                this.productName = this.product.activeSku.skuShortName;
            }
                    
        else if(!this.product.isThisSku)
            this.productName= this.product.name; 
        
        this.loading=true;

    }

    removeSpecialCharacters(name:string){
        name=name.replace(/  +/g, ' ')
        var regExp = /[a-zA-Z]/g;
        if(regExp.test(name))
        return name.replace(/[^a-zA-Z ]/g,"")
        else
        return name.replace(/[^0-9 ]/g,"")
    }

    findPrice(){
        
        if(this.product.isThisSku)
        {
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.skuId === this.product.isThisSku)
                    
                       this.offerPrice= sku.skuPrice.offer;
                       this.actualPrice = sku.skuPrice.actual;
                    
                    })
            }
            
        }
        else if(this.product.activeSku && this.product.activeSku.skuPrice){
            this.offerPrice= this.product.activeSku.skuPrice.offer;
            this.actualPrice=this.product.activeSku.skuPrice.actual;
        }
        else
        {
            
            this.offerPrice= this.product.offerPrice;
            this.actualPrice = this.product.actualPrice;
        }

    }

    checkIfProductIsOutOfStock(){
        
        if(this.product.isThisSku)
        {
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.skuId === (this.product.isThisSku))
                    
                       this.stock =Number(sku.stock)

                    })
                }
            
        }
        
        else if (this.product.sku.length === 1)
        {
        this.stock = Number(this.product.sku[0].stock);
        }
        if(!this.product.isThisSku && this.id === "Favourites"){
            
            this.stock = Number(this.product.activeSku.stock);
        }
        if (this.user.warehouses[0].enableInventory)
        {
            this.showInventory=true;
            if (this.product.minimumquantity === 0 && this.stock === this.product.minimumquantity){
                this.stockInfo = "Out-of-stock"
                this.addToCartBtn =true;    
            }
            else
            if(this.stock < this.product.minimumquantity){
                 this.stockInfo = "Out-of-stock"
                 this.addToCartBtn =true;
            }
            
            else
                this.stockInfo = "";
        }
        else 
        this.showInventory = false;
    

    }

    checkIfProductIsFavourite(){
        const items = sessionStorage.getItem('wishlistItems');
       
        if(items){
            let wishlistItems = JSON.parse(items);
            let item = wishlistItems.find(product=> 
                {

                if(this.product.isThisSku)
                {
                    if(this.product.isThisSku !== product.activeSku.skuId)
                    return false;
                }
                    
                if(this.product.activeSku && product.activeSku)

                {
                    if (product.activeSku.skuId !== this.product.activeSku.skuId) {
                       
                        return false;
                    }
                    
                }
                else if (product.id !== this.product.id) {
                        return false;

                    }
                    
                
               return true;
            });
           
            if(item)
                this.productInWishlist = true;
            else 
                this.productInWishlist = false;  
             
        }
    }

    cartChangeDetector(){
        this.cart.items$.subscribe(items=>
        {
            if(this.updateCartCount === 0)
            {
                this.getCurrentQuantity(this.product);
                this.cd.markForCheck();
            }
        });
    }   

    getCurrentQuantity(product: ProductModel){
       if(product)
       {
              this.quantityInCart = this.cart.getProductQuantityInCart(product);
              this.quantityControl =  new FormControl((this.quantityInCart>0?this.quantityInCart: (product.minimumquantity==0 ? 1 : product.minimumquantity)), Validators.required)
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    addToCart(): void {

      
       
        
        if (this.addingToCart) {
            return;
        }

        if(this.product.activeSku.skuShortName != this.product.name){
        this.router.navigate(['/classic/shop/product/' + this.product.id]);
        }
        else
        {
            this.addingToCart = true;
            this.cart.add(this.product, this.quantityControl.value).subscribe(response =>{
                    this.addingToCart = false;
                    this.getCurrentQuantity(this.product);
                    this.cd.markForCheck();
            }),
            error => {
                this.addingToCart = false;
                console.log('HTTP Error', error)},

            () => {
                this.addingToCart = false;
                console.log('HTTP request completed.')};
        }
    
    }

    removeFromCart(product: ProductModel): void {
        this.removingFromCart = true;
       
        let item = this.cart.items.find(x=>x.product.id === product.id);
        this.cart.remove(item).subscribe({
            complete: () => {
                this.removingFromCart = false;
                this.getCurrentQuantity(this.product);
                this.cd.markForCheck();
            }
        });
    }

    updateCart(product:ProductModel, quantity : number): void { 
       this.showMessage=true;
        this.updateCartCount++;
        if(!quantity)
            return;
        if(quantity < product.minimumquantity){
            this.showMessage=false;
                return;
            }
        if (this.user.warehouses[0].enableInventory)
       {
           if( quantity > Number(this.product.activeSku.stock))
           return; 
       }

            this.cart.update([{
                item: this.cart.items.find(x=>x.product.id === product.id),
                quantity : quantity
            }])
            .subscribe({
                complete: () => {
                    this.updateCartCount--;
                }
            });
    }

    toggleWishlist(): void {

        if(!this.togglingWishlist && this.product){
            this.togglingWishlist = true;
          
            this.toggleProducts.push(this.product);
            this.wishlist.toggle(this.product).subscribe({
                complete: () => {
                    this.toggleProducts = this.toggleProducts.filter(eachProduct => eachProduct !== this.product);
                    this.togglingWishlist = false;
                    this.checkIfProductIsFavourite();
                    this.cd.markForCheck();  
                    
                }
            });
        }
}

    addToCompare(): void {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        this.compare.add(this.product).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }
}
