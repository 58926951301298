<!--<div class="site-footer__widget footer-contacts">-->
<div>
    <!-- <a routerLink="./home">
        <div width="100px" height="15px">
            <img class="logo"  src="../../../../../assets/images/logos/storimart-icon.png" width="60%" height="60%" padding-top="10px">
        </div>
    </a> -->

    <div class="row">
        <div class="site-header__logo" *ngIf="warehouselogo!='https://storimart.bytetale.com/images/mart/2/shop_logo.png'">
            <a routerLink="/classic/home">
                <div *ngIf="warehouselogo">
                    <img src={{warehouselogo}} alt=""  style="max-width:100px;height:auto;" text-align="left"
                    onerror="this.src='/assets/images/logos/warehouse_logo.jpg'"> <br>
                   <!--  {{warehouselogo}} -->
  
                </div> 
                
            </a>
        </div>
        <div *ngIf="warehouselogo === 'https://storimart.bytetale.com/images/mart/2/shop_logo.png'">
            <ngx-avatar name={{warehousename}} value="28%" initialsSize="3" bgColor="#000000" fgColor="#58D3F7" size="60"
        textSizeRatio="2.5" [round]="true"></ngx-avatar>
        </div>
        <div class="site-header__name p-4">
            <div >
                <a routerLink="/classic/home">
                     <h4><b>{{warehousename}}</b></h4>
                </a>
             </div>     
        </div>
    </div>
    <div>
        {{adLine1}},{{adLine2}}<br>
        {{street}},{{city}}<br>  
        {{state}}<br>
        {{country}}-{{zip}}  
    </div>
   

    

    <div class="footer-contacts__text">
        {{aboutUs}}
    </div>

   

    <ul class="footer-contacts__contacts">
        <li><i *ngIf="email" class="footer-contacts__icon far fa-envelope"></i>
            <a *ngIf="email" href="mailto:{{email}}">{{email}}</a>
           
            <!-- <a *ngIf="phone" href={{phone}} ><i class="fab fa-whatsapp fa-lg" aria-hidden="true"></i></a> -->
        </li>
    </ul>
</div>
