import { Component, OnInit } from '@angular/core';
import { Country, CountryResponseModel } from '../../../../shared/interfaces/api/service-response/country-response-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountLoginService } from '../../services/account-login.service'
import { ValidateotpResponseModel,ValidateOtpModel, LoginRequestModel, ResetOtpRequest } from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { ResetOtpResponseModel } from 'src/app/shared/interfaces/api/service-response/resetotp-response-model';
import { CartService } from 'src/app/shared/services/cart.service';
import { windowProvider, WINDOW_PROVIDERS } from 'ngx-owl-carousel-o/lib/services/window-ref.service';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})

export class PageLoginComponent implements OnInit {
    loginForm: FormGroup;
    resetOtpForm: FormGroup;
    logo:string="";
    resetPasswordForm :FormGroup;
    verificationForm :FormGroup;
    isforgetPasswordView: boolean = false;
    isforgetOtpView: boolean = false;
    loggedInUser : string;
    verification:string="";
    sessionTimedOut = false;
    isVerification =false;
    isLoading = false;
    hidePin =true;
    hidePassword=true;
    hideConfirmPassword=true;
    isEmail:boolean;
    countries: Country[] =[];
    validateotpresponse : ValidateotpResponseModel;
    openShop:boolean;
    validateOtp :ValidateOtpModel ={
      phone:undefined,
      otp:undefined,
      appName:undefined,
      password:undefined,
      source:undefined,
      webPassword:undefined,
      email:undefined,
      isWeb:undefined,
    };

    loginRequest : LoginRequestModel ={
      phone:undefined,
      appName:undefined,
      password:undefined,
      source:undefined,
      webPassword:undefined,
      email:undefined,
      isWeb:undefined,
    };

    resetOtpRequest : ResetOtpRequest ={
      phone:undefined,
      email:undefined,
    }
    resetotpresponse : ResetOtpResponseModel;
    websiteUrl:string = environment.website;
    twitterUrl:string = environment.twitterUrl;
    linkedinUrl: string = environment.linkedinUrl;
    facebookUrl:string = environment.facebookUrl;
    facebookStyle:boolean=true;
    twitterStyle:boolean=true;
    linkedinStyle:boolean=true;
    validationPattern="/^([_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,5}))|(\d+$)$/"
     website:string='';
     
    constructor(public formBuilder: FormBuilder,
      public router: Router,
      public activateRoute: ActivatedRoute,
      public accountloginService: AccountLoginService, 
      private toastr: ToastrService,
      public wishlist: WishlistService,
      public cart: CartService){
      }
    
  
    ngOnInit() {
      localStorage.clear();
      this.logo=environment.logo;
      this.website=environment.website;
      console.log("Date::" + new Date())
      if(sessionStorage.getItem('openShop') ==="true")
      this.openShop = true;
      else
      this.openShop =false;
      this.verification = this.activateRoute.snapshot.queryParamMap.get('verifyPin');
      if(this.verification === "true")
      {
      this.isVerification=true;
      this.sessionTimedOut = false; 
      this.isforgetPasswordView =false;
      this.isforgetOtpView = false;
      }
      this.loginForm = this.formBuilder.group({
        //'countryCode': ['', Validators.compose([Validators.required])],
        'phoneNumber': ['', Validators.compose([Validators.required,Validators.pattern(/^(\d{6,14}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
        'otp': ['', Validators.compose([Validators.required])]
      })
      this.resetOtpForm = this.formBuilder.group({
        
        'phoneNumber': ['', Validators.compose([Validators.required,Validators.pattern(/^(\d{6,14}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      })
     
      
      const sessionTimedOutQueryParam = this.activateRoute.snapshot.queryParamMap.get('sessionTimedOut'); 
      if (sessionTimedOutQueryParam)
      {
        this.sessionTimedOut = sessionTimedOutQueryParam.toLowerCase() === 'true';
      }

      this.verificationForm = this.formBuilder.group({
        'phoneNumber': ['', Validators.compose([Validators.required,Validators.pattern(/^(\d{6,14}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/),])],
        'otp': ['', Validators.compose([Validators.required])],
        'password' :['', Validators.compose([Validators.required])],
        'confirmPassword':['', Validators.compose([Validators.required])]
      },{
        validator: this.mustMatch('password', 'confirmPassword'),
      }
      );

      if(this.facebookUrl!="")
      this.facebookStyle=false;
      if(this.linkedinUrl != "")
      this.linkedinStyle=false;
      if(this.twitterUrl!="")
      this.twitterStyle=false;
      
     

    }
 
  doLogin() {

    if (this.loginForm.valid)
    {

      let email = this.loginForm.get('phoneNumber').value;
      if(email.indexOf("@") > 0)
      {
        this.loginRequest.email = email.trim();
        this.loginRequest.phone ="";
      }
      else{
        this.loginRequest.email="";
        this.loginRequest.phone=this.loginForm.get('phoneNumber').value.trim();
      }
      
		
		
		this.loginRequest.appName=environment.appName;
		
		this.loginRequest.webPassword =this.loginForm.get('otp').value.trim();
    this.loginRequest.source =environment.source;
    this.loginRequest.isWeb = "true";

      this.accountloginService.validateOtp(this.loginRequest).subscribe(response=>{
        if (response)
        { 
         
          this.cart.syncServerCartToLocal();
          this.wishlist.syncWishlist();
          this.router.navigate(['/classic/home']); 
          this.toastr.success('', 'Logged In Successfully');
        } 
        else 
        { 
          this.toastr.error('', 'Oops! Please check the credentials entered');
        }
      },
      (error) => {
        //this.toastr.error('', "Sorry, Please try again later");
      });
    }
  }

  doVerification(){
    
    if(this.verificationForm.valid)
    {
      let email = this.verificationForm.get('phoneNumber').value.trim();
      if(email.indexOf("@") > 0)
      {
        this.validateOtp.email = this.verificationForm.get('phoneNumber').value.trim();
        this.validateOtp.phone ="";
      }
      else {
        this.validateOtp.email ="";
        this.validateOtp.phone = this.verificationForm.get('phoneNumber').value.trim();
      }

      var randomPassword = Math.random().toString(36).slice(-8);
		
		this.validateOtp.otp=this.verificationForm.get ('otp').value.trim();
		this.validateOtp.appName=environment.appName;
		this.validateOtp.password=randomPassword;
		this.validateOtp.webPassword =this.verificationForm.get ('password').value.trim();
		this.validateOtp.source =environment.source;
    this.validateOtp.isWeb = "true";

    this.accountloginService.verifyOtp(this.validateOtp).subscribe(response=>{
      if (response)
      { 
       
        this.isVerification=false;
        this.isforgetPasswordView =false;
        this.isforgetOtpView =false;
        this.toastr.success("New password set successfully. Please login to continue.")
        this.loginForm.controls['phoneNumber'].setValue(email);
      } 
      else 
      { 
        this.toastr.error('', 'Oops! Please check the credentials entered');
      }
    },
    (error) => {
      //this.toastr.error('', "Sorry, Please try again later");
    });
       
    }



  }

  mustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}
    
  backToLoginPage(){
    this.loginForm.reset();
    this.isforgetPasswordView =false;
    this.isforgetOtpView =false;
    this.isVerification=false;
    this.sessionTimedOut = false; 
  }

  public logout () {
      this.accountloginService.logout().subscribe(response =>{
        if(response.status.success == true ){
        this.accountloginService.removeLocalUser();
        this.router.navigate(['login']);
        this.toastr.success('', 'Logged Out Successfully');
        }
        
    },
      (error) => {
        console.log(error);
        this.toastr.error('', 'Sorry, logout failed, Please try again later');
      });
  }

  forgotOtp(){
    this.resetOtpForm.reset();
     this.isforgetOtpView = true;
    this.sessionTimedOut = false; 
    this.isVerification=false;
    this.isforgetPasswordView =false;
   
  }
  forgotPassword(){
    this.resetOtpForm.reset();
    this.isforgetPasswordView =true;
    this.isforgetOtpView =false;
    this.sessionTimedOut = false;
    this.isVerification=false;
  }
  verifyOtp(){
    this.verificationForm.reset();
    this.isVerification=true;
    this.sessionTimedOut = false; 
    this.isforgetPasswordView =false;
    this.isforgetOtpView = false;
  }

  
  
  resetOtp(resetOtpForm){

    let email = this.resetOtpForm.get('phoneNumber').value.trim();
      if(email.indexOf("@") > 0)
      {
        this.resetOtpRequest.email = this.resetOtpForm.get('phoneNumber').value.trim();
        this.resetOtpRequest.phone ="";
      }
      else {
        this.resetOtpRequest.email ="";
        this.resetOtpRequest.phone = this.resetOtpForm.get('phoneNumber').value.trim();
      }


    this.accountloginService.resetOtp(this.resetOtpRequest).subscribe((data:ResetOtpResponseModel) =>{
      
        if(data.status.success)
        {
          this.toastr.success("A new Otp has been send to your phone number");
          this.isforgetPasswordView = false;
          this.isforgetOtpView =false;
          this.sessionTimedOut = false;
          this.isVerification=false;
        }
        else
        {
          this.toastr.error('Failed', data.status.message);
        }
      },
      (error) => {
        this.toastr.error('Failed', 'Sorry we cannot process your request at this time, please try again after some time');
      });
    }
}


