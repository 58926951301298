import { Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ProductModel } from '../../interfaces/product';
import { CarouselComponent, SlidesOutputData } from 'ngx-owl-carousel-o';
import { FormControl, Validators } from '@angular/forms';
import { CartService } from '../../services/cart.service';
import { WishlistService } from '../../services/wishlist.service';
import { CompareService } from '../../services/compare.service';
import { isPlatformBrowser } from '@angular/common';
import { OwlCarouselOConfig } from 'ngx-owl-carousel-o/lib/carousel/owl-carousel-o-config';
import { PhotoSwipeService } from '../../services/photo-swipe.service';
import { DirectionService } from '../../services/direction.service';
import { Attribute } from '../../interfaces/api/service-response/productdetail-response-model';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AccountLoginService } from '../../../modules/account/services/account-login.service';
import { Subject } from 'rxjs';
import { Router,ActivatedRoute } from '@angular/router';
import { AvatarModule } from 'ngx-avatar';

interface ProductImage {
    id: string;
    url: string;
    active: boolean;
    name?:string
}
interface SkuCombinations {
   name:string;
    value:string;
};

export type Layout = 'standard'|'sidebar'|'columnar'|'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {
  
    private dataProduct : ProductModel;
    private dataLayout: Layout = 'standard';
    toggleProducts: ProductModel[] = [];
    stock :number;
    user={} as UserDataModel;
    showInventory:boolean;
    stockInfo:string ="";
    showGallery = true;
    showGalleryTimeout: number;
    skuselection : Attribute[] = [];
    addToCartBtn =false;
    skuImageCount =1;
    skuCombns:SkuCombinations[]=[];
    multipleLevelSkus:boolean;
    clickedVal:string="";
    isActive=false;
    @ViewChild('featuredCarousel', { read: CarouselComponent, static: false }) featuredCarousel: CarouselComponent;
    @ViewChild('thumbnailsCarousel', { read: CarouselComponent, static: false }) thumbnailsCarousel: CarouselComponent;
    @ViewChildren('imageElement', {read: ElementRef}) imageElements: QueryList<ElementRef>;
    taxIncluded: boolean;

    @Input() set layout(value: Layout) {
        this.dataLayout = value;

        if (isPlatformBrowser(this.platformId)) {
            // this dirty hack is needed to re-initialize the gallery after changing the layout
            clearTimeout(this.showGalleryTimeout);
            this.showGallery = false;
            this.showGalleryTimeout = window.setTimeout(() => {
                this.showGallery = true;
            }, 0);
        }
    }
    get layout(): Layout {
        return this.dataLayout;
    }

    @Input() set product(value: ProductModel) {
        this.dataProduct = value;
       
        this.images = value ? this.dataProduct.images.map((url, index) => {
            return {
                id: index.toString(),
                url:url.includes("no_Img.png")?"invalidURL":url,
                active: index === 0,
                name:this.dataProduct.name?this.dataProduct.name:""
            };
        }) : [];

        if(this.product.additionalImages.length > 0)
        for(var i=0;i<this.product.additionalImages.length;i++){
            if(this.product.additionalImages[i]!==null && this.product.additionalImages[i].imageUrl != null)
            {
             this.images.push(
                 {
                     id: (i+1).toString(),
                     url : this.product.additionalImages[i].imageUrl.includes("no_Img.png")? "invalidURL":this.product.additionalImages[i].imageUrl,
                     active: true,
                     name:this.product.name

                 }
             )
            }
         }  



        
        if(this.product)
        {
  
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.attributes && sku.attributes.attribute)
                    {
                        sku.attributes.attribute.forEach(attribute=> {
                            let option = this.product.options.find(x=>x.name== attribute.name);
                            if(option)
                            {
                                if(!option.value.includes(attribute.value))
                                    this.product.options.find(x=>x.name== attribute.name).value.push(attribute.value);
                            }
                            else
                            {
                                this.product.options.push(
                                     {
                                        name : attribute.name,
                                        value : [attribute.value],
                                        skuDefining : attribute.skuDefining,
                                        
                                     }
                                )       
                            }
                        })
                       
                    }
                })

                

                if(this.product.options.length > 1){
                    for(var i=0;i<this.product.options[0].value.length;i++)
                    {
                        for(var j=0;j<this.product.sku.length;j++)
                        {
                           if(this.product.options[0].name === this.product.sku[j].attributes.attribute[0].name)
                           {
                           if (this.product.options[0].value[i] === this.product.sku[j].attributes.attribute[0].value)
                           {
                            this.skuCombns.push({
                                name : this.product.sku[j].attributes.attribute[0].value,
                                value : this.product.sku[j].attributes.attribute[1].value
                           })
                        }
                           }
                           
                           else  if(this.product.options[0].name !== this.product.sku[j].attributes.attribute[0].name)
                           {
                            if(this.product.options[0].name === this.product.sku[j].attributes.attribute[1].name)
                            {
                                if (this.product.options[0].value[i] === this.product.sku[j].attributes.attribute[1].value)
                           {
                            this.skuCombns.push({
                                name : this.product.sku[j].attributes.attribute[1].value,
                                value : this.product.sku[j].attributes.attribute[0].value
                           })
                            
                           }
                            }
                           }
                        }
                    } 

                    if(this.product.options.length > 1){
                        this.multipleLevelSkus=true;
                        this.clickedVal =this.product.options[0].value[0];
                    }
                }
            }
            
           
            this.getCurrentQuantity(this.product);
            this.cartChangeDetector();
            this.checkIfProductIsFavourite();
            this.checkIfProductIsOutOfStock();
            
            if(this.skuId)
            {
            
            if(this.product.sku)
            {
                this.product.sku.forEach(sku=>{

                    if(sku.skuId === (this.skuId.toString()))
                    {
                        sku.attributes.attribute.forEach(attribute=> {
                            
                            this.makeSkuActive(attribute.name,attribute.value);
                    })
                }
                })
            }
        

            }
         }
    }

    get product(): ProductModel {
        return this.dataProduct;
    }

    removeSpecialCharacters(name:string){
        name=name.replace(/  +/g, ' ')
        var regExp = /[a-zA-Z]/g;
        if(regExp.test(name))
        return name.replace(/[^a-zA-Z ]/g,"")
        else
        return name.replace(/[^0-9 ]/g,"")
    }

    makeSkuActive1(optionName: string, value: string){

        if(this.product.options.length > 1){
            this.multipleLevelSkus=true;
            this.clickedVal = value;
        }
        this.makeSkuActive(optionName,value);

    }
    getFocosed(value){
        
        if(this.product.activeSku.attributes.attribute.length > 1){
       if(value == this.product.activeSku.attributes.attribute[0].value){
           return true
       }else if(value == this.product.activeSku.attributes.attribute[1].value){
           return true
       }
       else{return false}}else{
        if(value == this.product.activeSku.attributes.attribute[0].value){
            return true
        }else{return false}
       }
    }  
   
    makeSkuActive(optionName: string, value: string)

    {
        
       this.isActive=true;
        if(this.skuselection.find(s=>s.name == optionName))
        {
            this.skuselection.find(s=>s.name == optionName).value= value;
        }
        else{
            this.skuselection.push({ name: optionName, value: value, skuDefining: false});
        }

        let skus = this.product.sku;

        this.skuselection.forEach(skuOption=> {
            skus = skus.filter(sku=>sku && sku.attributes && sku.attributes.attribute.filter(a=>a.name == skuOption.name && a.value== skuOption.value).length>0);
            if(skus.length>0)
            {
                this.product.activeSku = skus[0];
                this.images=[];
            }
        })

        if ((this.product.activeSku.skuImage !==null) || (this.product.activeSku.additionalImages.length > 0)){

            this.images=[];

            if (this.product.activeSku.skuImage !==null){
            this.images.push(
                {
                    id: '0',
                    url : this.product.activeSku.skuImage,
                    active: true,
                    name:this.product.name
                }
            )
            }

            else if ((this.product.activeSku.skuImage===null) && this.product.productImage!== null){
                this.images.push(
                    {
                        id: '0',
                        url : this.product.productImage[0],
                        active: true,
                        name:this.product.name
                    }
                )
                }

           

            if(this.product.activeSku.additionalImages.length > 0){
                for(var i=0;i<this.product.activeSku.additionalImages.length;i++){ 
                   
                    if(this.product.activeSku.additionalImages[i]!==null && this.product.activeSku.additionalImages[i].imageUrl != null)
                   {
                    this.images.push(
                        {
                            id: (i+1).toString(),
                            url : this.product.activeSku.additionalImages[i].imageUrl.includes("no_Img.png")? "invalidURL" :this.product.activeSku.additionalImages[i].imageUrl,
                            active: true,
                            name:this.product.name
                        }
                    )
                   }
                }
               /*  (this.product.activeSku.additionalImages).forEach((obj,index) => {
                  (obj.imageUrl != null) && ( this.images.push(
                    {
                      id: (index).toString,
                      url :obj.imageUrl.includes("no_Img.png")? "invalidURL" :obj.imageUrl,
                      active: true,
                      name:this.product.name
                    }
              ))
                 
              }
           
                ) */}

                else if((this.product.activeSku.additionalImages.length === 0)&& (this.product.additionalImages.length > 0)){
                for(var i=0;i<this.product.additionalImages.length;i++){
                   if(this.product.additionalImages[i]!==null && this.product.additionalImages[i].imageUrl != null)
                   {
                    this.images.push(
                        {
                            id: (i+1).toString(),
                            url : this.product.additionalImages[i].imageUrl.includes("no_Img.png")? "invalidURL" :this.product.additionalImages[i].imageUrl,
                            active: true,
                            name:this.product.name
                        }
                    )
                   }
                }
                       
                    }
                 
                      
    }
    else if(this.product.productImageLarge !==null || (this.product.additionalImages.length > 0)){
        this.images=[];

        if(this.product.productImageLarge  !==null){
            this.images.push(
                {
                    id: '0',
                    url : this.product.productImageLarge.includes("no_Img.png")? "invalidURL":this.product.productImageLarge,
                    active: true,
                    name:this.product.name
                }
            )
        }

        if(this.product.additionalImages.length > 0)
        {
            for(var i=0;i<this.product.additionalImages.length;i++){
                if(this.product.additionalImages[i]!==null && this.product.additionalImages[i].imageUrl != null)
                {
                 this.images.push(
                     {
                         id: (i+1).toString(),
                         url : this.product.additionalImages[i].imageUrl.includes("no_Img.png")? "invalidURL":this.product.additionalImages[i].imageUrl,
                         active: true,
                         name:this.product.name

                     }
                 )
                }
             }  

        }


    }
    

        this.getCurrentQuantity(this.product);
        this.checkIfProductIsFavourite();
        this.checkIfProductIsOutOfStock();
        

    }

    makeSkuImageActive(){
       
    }

    images: ProductImage[] = [];

    carouselOptions: Partial<OwlCarouselOConfig> = {
        dots: false,
        autoplay: false,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions: Partial<OwlCarouselOConfig> = {
        margin: 10,
        items: 5,
        nav:false,
        dots: false,
        loop: true,
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        
        
        responsive: {
            480: {items: 5},
            380: {items: 4},
              0: {items: 3}
        },
        
        rtl: this.direction.isRTL()
    };

    thumbnailsCarouselOptions1: Partial<OwlCarouselOConfig> = {
        margin: 10,
        items: 5,
        nav:false,
        dots: false,
        loop: false,
               
        
        responsive: {
            480: {items: 5},
            380: {items: 4},
              0: {items: 3}
        },
        
        rtl: this.direction.isRTL()
    };

    quantity: FormControl;
    quantityInCart : number;
    
    addingToCart = false;
    removingFromCart = false;
    togglingWishlist = false;
    productInWishlist = false;
    addingToCompare = false;
    openShop:boolean;
    updateCartCount : number = 0;
    skuId="";
    catalogPreview:boolean=false;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        private photoSwipe: PhotoSwipeService,
        private direction: DirectionService,
        private accountService: AccountLoginService,
        private router: Router,
        private activatedRoute:ActivatedRoute,
    ) {}

    ngOnInit(): void {

    this.skuId = this.activatedRoute.snapshot.queryParamMap.get('skuId');
        
    if(sessionStorage.getItem('catalogId') === null)
        
            this.catalogPreview = false 
            else
            this.catalogPreview = true;
         
    if (this.layout !== 'quickview' && isPlatformBrowser(this.platformId)) {
            this.photoSwipe.load().subscribe();
        }
     if(sessionStorage.getItem('openShop') ==="true")
        
            this.openShop=true;

        else 
       
        this.openShop=false;
    }

    checkIfProductIsOutOfStock(){
        console.log(this.product);
        this.taxIncluded = this.product.taxIncluded;
        console.log( this.taxIncluded)
        this.addToCartBtn =false;
        this.stock = Number(this.product.activeSku.stock);
        this.user =  this.accountService.getLocalUser();
        if (this.user.warehouses[0].enableInventory)
        {
            this.showInventory=true;
            if (this.product.minimumquantity === 0 && this.stock === this.product.minimumquantity){
                this.stockInfo = "Out-of-stock"
                this.addToCartBtn =true;    
            }
            else if(this.stock < this.product.minimumquantity){
                 this.stockInfo = "Out-of-stock"
                 this.addToCartBtn =true;
            }
            
            else
                this.stockInfo = ""
        }
        else 
        this.showInventory = false;

    }
     checkIfProductIsFavourite(){
        
        const items = sessionStorage.getItem('wishlistItems');
       
       
        if(items){
            let wishlistItems = JSON.parse(items);
            let item = wishlistItems.find(product=> 
                {
                    
                if(this.product.activeSku && product.activeSku)
                {
                    if (product.activeSku.skuId !== this.product.activeSku.skuId) {
                        return false;
                    }
                }
                else
                {
                    if (product.id !== this.product.id) {
                        return false;
                    }
                }

                return true;
            });
            if(item)
                this.productInWishlist = true;
            else 
                this.productInWishlist = false;
                
        }

    }

    cartChangeDetector(){
        this.cart.items$.subscribe(items=>
        {
            if(this.updateCartCount === 0)
            {
                this.getCurrentQuantity(this.product);
            }
        });
    } 
    
    
    getCurrentQuantity(product: ProductModel){
        if(product)
        {
               this.quantityInCart = this.cart.getProductQuantityInCart(product);
               this.quantity =  new FormControl((this.quantityInCart>0?this.quantityInCart: (product.minimumquantity==0 ? 1 : product.minimumquantity)), Validators.required);
         }
     }
     

    setActiveImage(image: ProductImage): void {
        this.images.forEach(eachImage => eachImage.active = eachImage === image);
    }

    featuredCarouselTranslated(event: SlidesOutputData): void {
        if (event.slides.length) {
            const activeImageId = event.slides[0].id;

            this.images.forEach(eachImage => eachImage.active = eachImage.id === activeImageId);

            if (!this.thumbnailsCarousel.slidesData.find(slide => slide.id === event.slides[0].id && slide.isActive)) {
                this.thumbnailsCarousel.to(event.slides[0].id);
            }
        }
    }

    addToCart(): void {

       
      
        if (!this.addingToCart && this.product.activeSku && this.quantity.value > 0) {
            this.addingToCart = true;

            let currentProduct = Object.assign({}, this.product);

            this.cart.add(currentProduct,this.quantity.value).subscribe(response => {
                        this.addingToCart = false;
                        this.getCurrentQuantity(this.product);
                    }
            ),
            error => {
                this.addingToCart = false;
                console.log('HTTP Error', error)},

            () => {
                this.addingToCart = false;
                console.log('HTTP request completed.')};
        }
        
        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            this.addingToCart = true;

            this.cart.add(this.product,this.quantity.value).subscribe(response => {
                        this.addingToCart = false;
                        this.getCurrentQuantity(this.product);
                    }
            ),
            error => {
                this.addingToCart = false;
                console.log('HTTP Error', error)},

            () => {
                this.addingToCart = false;
                console.log('HTTP request completed.')};
            
        }
    
    }

    removeFromCart(product: ProductModel): void {
        this.removingFromCart = true;
       

        let item = this.cart.items.find(eachItem => {

            if(product.activeSku)
            {
                if (eachItem.product.activeSku.skuId !== product.activeSku.skuId) {
                    return false;
                }
            }
            else
            {
                if (eachItem.product.id !== product.id) {
                    return false;
                }
            }
            return true;
        });

        this.cart.remove(item).subscribe({
            complete: () => {
                this.removingFromCart = false;
                this.getCurrentQuantity(this.product);
                
            }
        });
    }

    updateCart(product:ProductModel, quantity : number): void { 
       
        this.updateCartCount++;
        if(!quantity)
            return;
        if (this.user.warehouses[0].enableInventory){
        if(quantity > Number(product.activeSku.stock)){
            return;
        }
    }

            let item = this.cart.items.find(eachItem => {

                if(product.activeSku)
                {
                    if (eachItem.product.activeSku.skuId !== product.activeSku.skuId) {
                        return false;
                    }
                }
                else
                {
                    if (eachItem.product.id !== product.id) {
                        return false;
                    }
                }
                return true;
            });
    

            this.cart.update([{
                item: item,
                quantity : quantity
            }])
            .subscribe({
                complete: () => {
                    this.updateCartCount--;
                    
                }
            });
    }

    toggleWishlist(): void {
        
        if(!this.togglingWishlist && this.product){
             this.togglingWishlist = true;

          let currentProduct = Object.assign({}, this.product);
            this.toggleProducts.push(currentProduct);
            
            this.wishlist.toggle(currentProduct).subscribe({
                complete: () => {
                    this.toggleProducts = this.toggleProducts.filter(eachProduct => eachProduct !== currentProduct);
                    this.togglingWishlist = false;
                    this.checkIfProductIsFavourite();
                    
                }
            });
         }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({complete: () => this.addingToCompare = false});
        }
    }

    getIndexDependOnDir(index) {
        // we need to invert index id direction === 'rtl' because photoswipe do not support rtl
        if (this.direction.isRTL()) {
            return this.images.length - 1 - index;
        }

        return index;
    }

    openPhotoSwipe(event: MouseEvent, image: ProductImage): void {
        if (this.layout !== 'quickview') {
            event.preventDefault();

            const images = this.images.map(eachImage => {
                return {
                    src: eachImage.url,
                    msrc: eachImage.url,
                    w:  500,
                    h: 700
                };
            });

            if (this.direction.isRTL()) {
                images.reverse();
            }

            const options = {
                getThumbBoundsFn: index => {
                    const imageElements = this.imageElements.toArray();
                    const dirDependentIndex = this.getIndexDependOnDir(index);

                    if (!imageElements[dirDependentIndex]) {
                        return null;
                    }

                    const imageElement = imageElements[dirDependentIndex].nativeElement;
                    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop;
                    const rect = imageElement.getBoundingClientRect();

                    return {x: rect.left, y: rect.top + pageYScroll, w: rect.width};
                },
                index: this.getIndexDependOnDir(this.images.indexOf(image)),
                bgOpacity: .9,
                history: false
            };

            this.photoSwipe.open(images, options).subscribe(galleryRef => {
                galleryRef.listen('beforeChange', () => {
                    this.featuredCarousel.to(this.images[this.getIndexDependOnDir(galleryRef.getCurrentIndex())].id);
                });
            });
        }
    }
}
