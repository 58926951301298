<!-- .dropcart -->
<div class="dropcart">
    <div class="dropcart__empty" *ngIf="!(cart.items$|async).length && noOfItemsInViewCart === 0">
        Your shopping cart is empty!
        
    </div>
    <div class="dropcart__empty" *ngIf="(cart.inActive$|async)">
       <span class="has-error"><i>Showing only active products,for more details go to </i><a routerLink="./shop/cart" (click)="toggleCartPop()">View Cart</a></span>
        
    </div>
    <div>
    <ng-container *ngIf="(cart.items$|async).length">
        <div class="dropcart__products-list">
            <div *ngFor="let item of cart.items$|async" class="dropcart__product">
                <div class="dropcart__product-image">
                   <div *ngIf="!item.product.activeSku"> <a [routerLink]="root.product(item.product.id)" *ngIf="item.product.images?.length && !item.product.images[0].includes('no_Img') && item.product.images"><img [src]="item.product.images[0]" alt=""></a>
                   </div>
                   <div *ngIf="!item.product.activeSku"> <a [routerLink]="root.product(item.product.id)" *ngIf="item.product.images?.length && item.product.images[0].includes('no_Img') "><ngx-avatar name={{removeSpecialCharacters(item.product.name)}} initialsSize="3"></ngx-avatar></a>
                   </div>
                   <div *ngIf="item.product.activeSku && item.product.activeSku.skuImage && !item.product.activeSku.skuImage.includes('no_Img')"> <a [routerLink]="root.product(item.product.id)"><img [src]="item.product.activeSku.skuImage" alt=""></a>
                   </div>
                   <div *ngIf="item.product.activeSku && item.product.activeSku.skuImage && item.product.activeSku.skuImage.includes('no_Img')"> <a [routerLink]="root.product(item.product.id)"><ngx-avatar name={{removeSpecialCharacters(item.product.activeSku.skuShortName)}} initialsSize="3"></ngx-avatar></a>
                   </div>
                   <div *ngIf="item.product.activeSku && !item.product.activeSku.skuImage && item.product.productImage && !item.product.productImage[0].includes('no_Img')"> <a [routerLink]="root.product(item.product.id)"><img [src]="item.product.productImage[0]" alt=""></a>
                   </div>
                   <div *ngIf="item.product.activeSku && !item.product.activeSku.skuImage && item.product.productImage && item.product.productImage[0].includes('no_Img')"> <a [routerLink]="root.product(item.product.id)" ><ngx-avatar name={{removeSpecialCharacters(item.product.activeSku.skuShortName)}} initialsSize="3"></ngx-avatar></a>
                   </div>
                </div>
                <div class="dropcart__product-info">
                    <div *ngIf="item.product.activeSku" class="dropcart__product-name"><a [routerLink]="root.product(item.product.id)">{{ item.product.activeSku.skuShortName}}</a></div>
                    <div *ngIf="!item.product.activeSku" class="dropcart__product-name"><a [routerLink]="root.product(item.product.id)">{{ item.product.name}}</a></div>

                    <!--<ul *ngIf="item.options.length" class="dropcart__product-options">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul>-->
                    <div class="dropcart__product-meta">
                        <span class="dropcart__product-quantity">{{ item.quantity | number:'1.0':'en-IN' }}</span> ×
                        <span *ngIf="item.product.activeSku" class="dropcart__product-price">{{item.product.currency}} {{ (item.product.activeSku.skuPrice.offer?item.product.activeSku.skuPrice.offer:item.product.activeSku.skuPrice.actual) | number:'1.2-2':'en-IN' }}</span>
                        <span *ngIf="!item.product.activeSku" class="dropcart__product-price">{{item.product.currency}} {{ (item.product.price.offer? item.product.price.offer: item.product.price.actual) | number:'1.2-2':'en-IN'}}</span>
                    </div>
                </div>

                <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                    <app-icon name="cross-10" size="10"></app-icon>
                </button>
            </div>
        </div>

        <div class="dropcart__totals">
            <table>
                <tr *ngIf="(cart.totals$|async).length">
                    <th>Subtotal</th>
                    <td>{{cart.currency$|async}} {{ cart.subtotal$|async | number:'1.2-2':'en-IN' }}</td>
                </tr>
                <!-- <tr *ngFor="let total of cart.totals$|async">
                    <th>{{ total.title }}</th>
                    <td>{{total.currency}} {{ total.price | number:'1.2-2':'en-IN' }}</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{{cart.currency$|async}} {{ cart.total$|async | number:'1.2-2':'en-IN' }}</td>
                </tr> -->
            </table>
        </div>
        <div  class="dropcart__buttons">
            <a class="btn btn-secondary" routerLink="./shop/cart" (click)="toggleCartPop()">View Cart</a>
            <a class="btn btn-primary white"   routerLink="./shop/checkout" (click)="toggleCartPop()">Checkout</a>
        </div>
    </ng-container>
</div>
</div>
<!-- .dropcart / end -->