<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <button *ngIf="!openShop" class="btn btn-light btn-pc-svg-icon btn-svg-icon--fake-svg product-card__wishlist" type="button" appClick (click)="toggleWishlist()" [ngClass]="{'btn-loading': togglingWishlist , 'active': productInWishlist}">
        <app-icon name="wishlist-16" size="16"></app-icon>
        <span class="fake-svg-icon fake-svg-icon--wishlist-16"></span>
    </button>

    <!--<div *ngIf="product.badges.includes('offer')">Ends on </div>-->

    <div *ngIf="product.badges.length" class="product-card__badges-list">
        <!--<div *ngIf="product.badges.includes('sale')" class="product-card__badge product-card__badge--sale">Sale</div>
        <div *ngIf="product.badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>-->
        <div *ngIf="product.badges.includes('offer')" class="product-card__badge product-card__badge--new">Offer</div>
        <!--<div *ngIf="product.badges.includes('new')" class="product-card__badge product-card__badge--new">New</div>-->
    </div>


    <div *ngIf="!product.activeSku && !product.isThisSku" class="product-card__image">
        
    <ngx-avatar [routerLink]="root.product(product.id)" class="pointer" name="{{removeSpecialCharacters(productName)}}" initialsSize="3" src="{{imageUrl}}" size="220" [round]="false"></ngx-avatar>

    </div>
    <div *ngIf="product.activeSku && !product.isThisSku" class="product-card__image">
        
        <ngx-avatar [routerLink]="'/classic/shop/product/'+product.id"  [queryParams]="{ skuId:product.activeSku.skuId }" class="pointer" name="{{removeSpecialCharacters(productName)}}" initialsSize="3" src="{{imageUrl}}" size="220" [round]="false"></ngx-avatar>
    
        </div>
        <div *ngIf="product.isThisSku" class="product-card__image">
        
            <ngx-avatar [routerLink]="'/classic/shop/product/'+product.id"  [queryParams]="{ skuId:product.isThisSku }" class="pointer" name="{{removeSpecialCharacters(productName)}}" src="{{imageUrl}}" initialsSize="3" size="220" [round]="false"></ngx-avatar>
        
            </div>
    <div class="product-card__info">
    <div class="product-card__name">
            
        <div *ngIf="product.activeSku && !product.isThisSku"><a [routerLink]="'/classic/shop/product/'+product.id"  [queryParams]="{ skuId:product.activeSku.skuId }"> {{productName}} </a></div>
            <div *ngIf="!product.activeSku && !product.isThisSku"><a [routerLink]="root.product(product.id)" >{{productName}} </a></div>
            <div *ngIf="product.isThisSku"><a [routerLink]="'/classic/shop/product/'+product.id"  [queryParams]="{ skuId:product.isThisSku }"> {{productName}} </a></div>

       </div>
       <br />
   
        <div *ngIf="product.activeSku && product.activeSku.skuShopId">Id: {{ product.activeSku.skuShopId }} </div>
        <div *ngIf="product.activeSku && !product.activeSku.skuShopId && product.activeSku.skuErpId">Id: {{ product.activeSku.skuErpId }} </div>
        <div *ngIf="product.activeSku && !product.activeSku.skuShopId && !product.activeSku.skuErpId">Id: {{ product.activeSku.skuId }} </div>

    
</div>     
        <!-- <div class="product-card__rating">
            <app-rating [value]="product.rating"></app-rating>
            <div class="product-card__rating-legend">{{ product.reviews }} Reviews</div>
        </div>
        <ul *ngIf="product.features.length" class="product-card__features-list">
            <li *ngFor="let feature of product.features">{{ feature.name }}: {{ feature.value }}</li>
        </ul> -->
    
    <div class="product-card__actions">
         <div  *ngIf="showInventory && showStock" class="outofstock">
            <span>{{stockInfo}}</span>
        </div>
       
        <div class="product-card__prices">
            <ng-container *ngIf="offerPrice">
                <span class="product-card__new-price"> {{ product.currency }} {{ offerPrice | number:'1.2-2':'en-IN'}}</span>{{ ' ' }}
                <span class="product-card__old-price"> {{ product.currency }} {{ actualPrice | number:'1.2-2':'en-IN'}}</span>
            </ng-container>
            <ng-container *ngIf="!offerPrice">
                {{ product.currency }} {{ actualPrice | number:'1.2-2':'en-IN' }}
            </ng-container>
            <div *ngIf="product.taxIncluded === true" style="font-size: smaller;color:black;font-weight: normal;">Incl tax.
            </div>
            <div *ngIf="product.taxIncluded === false" style="font-size: smaller;color:black;font-weight: normal;">Excl tax.
            </div>         
        </div>
        
            
        
        <div class="product-card__buttons">
            <button *ngIf="catalogPreview" class="btn btn-primary">Add To Cart</button>
            <button type="button" *ngIf="quantityInCart === 0 && product.activeSku && !catalogPreview && quantityControl.value" class="btn btn-primary" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" [disabled]="(quantityControl.value > product.activeSku.stock)||(quantityControl.value < product.minimumquantity)" [disabled]="addToCartBtn">Add To Cart</button>
            <button type="button" *ngIf="quantityInCart > 0 && !catalogPreview" class="btn btn-primary product-card__removefromcart" appClick (click)="removeFromCart(product)" [ngClass]="{'btn-loading': removingFromCart}">Remove</button>
            <app-input-number 
                *ngIf="quantityInCart > 0 && product.activeSku" 
                aria-label="Quantity" 
                class="product__quantity-product-card" 
                size="sm" 
                [min]="product.minimumquantity==0? 1: product.minimumquantity" 
                [max]="showInventory ? product.activeSku.stock: 999999" 
                [formControl]="quantityControl" 
                (ngModelChange)="updateCart(product, quantityControl.value)">
            </app-input-number>
            
           
            <!--<app-input-number aria-label="Quantity" class="product__quantity-product-card" size="sm" [min]="product.minimumquantity" [formControl]="quantity"></app-input-number>
            <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">
                Add to Cart
            </button>
            <button class="btn btn-secondary product-card__addtocart product-card__addtocart--list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">
                Add to Cart
            </button>-->

            <!--<button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                <app-icon name="compare-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
            </button>-->
        </div>
        <div class="product-card__prices">
            <mat-error style="font-size:xx-small;" *ngIf="(quantityControl.value < product.minimumquantity) || quantityControl.value == 0">Min Qty:{{product.minimumquantity==0? 1: product.minimumquantity}}</mat-error>
            <mat-error style="font-size:xx-small;" *ngIf="((quantityControl.value == product.activeSku.stock) && showInventory && showMessage)">Maximum Quantity reached!</mat-error>
            <mat-error style="font-size:xx-small;" *ngIf="((quantityControl.value > product.activeSku.stock) && showInventory && !showMessage)">Specified Quantity Not Available</mat-error>
            
        </div>
    </div>
</div>