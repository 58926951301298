import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

// modules (angular)
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { RouterModule } from '@angular/router';

// modules (third-party)
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ModalModule } from 'ngx-bootstrap/modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

// directives
import { ClickDirective } from './directives/click.directive';
import { CollapseContentDirective, CollapseDirective, CollapseItemDirective } from './directives/collapse.directive';
import { DepartmentsAreaDirective } from './directives/departments-area.directive';
import { DropdownDirective } from './directives/dropdown.directive';
import { FakeSlidesDirective } from './directives/fake-slides.directive';
import { OwlPreventClickDirective } from './directives/owl-prevent-click.directive';

// components
import { AlertComponent } from './components/alert/alert.component';
import { IconComponent } from './components/icon/icon.component';
import { InputNumberComponent } from './components/input-number/input-number.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { PageHeaderComponent } from './components/page-header/page-header.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { PostCardComponent } from './components/post-card/post-card.component';
import { ProductCardComponent } from './components/product-card/product-card.component';
import { ProductComponent } from './components/product/product.component';
import { QuickviewComponent } from './components/quickview/quickview.component';
import { RatingComponent } from './components/rating/rating.component';
import { PageAddressesListComponent } from '../modules/account/pages/page-addresses-list/page-addresses-list.component';
import { PageLoginComponent } from '../modules/account/pages/page-login/page-login.component';
import {OpenShopComponent} from '../modules/account/pages/open-shop/open-shop.component'
import {CatalogPreviewComponent} from '../modules/account/pages/catalog-preview/catalog-preview.component'


// pipes
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';

//Angular Material modules
import {
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MAT_DATE_LOCALE,
    MatPaginatorModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    MAT_SNACK_BAR_DEFAULT_OPTIONS,
    MatTabsModule,
    MatToolbarModule,
    MatBottomSheetModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    MatGridListModule,
    MatTableModule,
 
    
  } from '@angular/material'
import { PagePaymentComponent } from './components/page-payment/page-payment.component';
import { LoginDialogComponent } from './login-dialog/login-dialog.component';
import { AvatarModule } from 'ngx-avatar';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { InfodialogComponent } from './infodialog/infodialog.component';
import { BrowserModule } from '@angular/platform-browser';


  


@NgModule({
    declarations: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        PageAddressesListComponent,
        PageLoginComponent,
        PagePaymentComponent,
        OpenShopComponent,
        CatalogPreviewComponent,
        // pipes
        CurrencyFormatPipe,
        LoginDialogComponent,
        ConfirmDialogComponent,
        InfodialogComponent
    ],
    imports: [
        // modules (angular)
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        // modules (third-party)
        CarouselModule,
        ModalModule.forRoot(),
        //Angular Material 
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatBottomSheetModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        //font awesome
        FontAwesomeModule,
        AvatarModule,
        MatTableModule,
    ],
    exports: [
        // directives
        ClickDirective,
        CollapseContentDirective,
        CollapseDirective,
        CollapseItemDirective,
        DepartmentsAreaDirective,
        DropdownDirective,
        FakeSlidesDirective,
        OwlPreventClickDirective,
        // components
        AlertComponent,
        IconComponent,
        InputNumberComponent,
        LoadingBarComponent,
        PageHeaderComponent,
        PaginationComponent,
        PostCardComponent,
        ProductCardComponent,
        ProductComponent,
        QuickviewComponent,
        RatingComponent,
        PageAddressesListComponent,
        PageLoginComponent,
        PagePaymentComponent,
        OpenShopComponent,
        CatalogPreviewComponent,
        // pipes
        CurrencyFormatPipe,
        //Angular Material 
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatBottomSheetModule,
        MatDatepickerModule,
        MatDialogModule,
        MatExpansionModule,
        MatIconModule,
        MatInputModule,
        MatTooltipModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSnackBarModule,
        MatTabsModule,
        MatToolbarModule,
        MatStepperModule,
        MatProgressSpinnerModule,
        MatGridListModule,
        //font awesome
        FontAwesomeModule
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
      ],
    entryComponents:[LoginDialogComponent,ConfirmDialogComponent,InfodialogComponent]

})
export class SharedModule { }
