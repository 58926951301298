import { Component } from '@angular/core';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { FormControl ,ReactiveFormsModule} from '@angular/forms';
import { DepartmentsService } from '../../../../shared/services/departments.service';



@Component({
    selector: 'app-header-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent {
    constructor(private router: Router,private departmentsService: DepartmentsService) {
      
       
     }

    
    typeahead: FormControl = new FormControl();
    suggestions: string[] = [];

     
    products:string[]=[];
    filteredOptions: Observable<string[]>;

  ngOnInit() {
    this.products=[];

 
      this.departmentsService.getproductNames().subscribe(response=> {
        if(response.status === "200")
        {
          this.products = response.productNames;
        }
      });
      
      






    this.filteredOptions = this.typeahead.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );


  }

  getProductNames(){
    this.departmentsService.getproductNames().subscribe(response=> {
      if(response.status === "200")
      {
        this.products = response.productNames;
      }
    });
   
  }

  private _filter(value: string): string[] {
    if(value)
    {
    const filterValue = value.toLowerCase();

    return this.products.filter(option => option.toLowerCase().includes(filterValue));
    }
    else 
    return [];
  }

    goToSearchResults(value){
      this.typeahead.reset();
      value=value.replace(/%/g,"%25").trim();
        this.router.navigate(["classic/shop/search-results"] ,{ queryParams: { search : value } }); 
    }

    callApiNow(value){
      if(value.length >= 3)
      this.router.navigate(["classic/shop/search-results"] ,{ queryParams: { search : value } }); 
    }
   
}
