<div class="nav-panel">
    <div class="nav-panel__container container">
        <div class="nav-panel__row">
            <div *ngIf="logo" class="nav-panel__logo">
                <a [routerLink]="root.url('/home')">
                </a>
            </div>

            <div *ngIf="departments" class="nav-panel__departments">
                <app-header-departments></app-header-departments>
            </div>

            <!-- .nav-links -->
            <app-header-links class="nav-panel__nav-links nav-links"></app-header-links>
            <!-- .nav-links / end -->

            <div class="nav-panel__indicators">
                <div *ngIf="search" class="indicator indicator--trigger--click" appDropdown="indicator--opened" #search="appDropdown">
                    <button type="button" class="indicator__button" (click)="$event.preventDefault(); search.toggle(); search.isOpen ? searchField.focus() : '';">
                        <span class="indicator__area">
                            <app-icon class="indicator__icon" name="search-20" size="20"></app-icon>
                            <app-icon class="indicator__icon indicator__icon--open" name="cross-20" size="20"></app-icon>
                        </span>
                    </button>

                    <div class="indicator__dropdown">
                        <div class="drop-search">
                           <form>
                                <input class="drop-search__input" name="search"  (keydown.escape)="search.close()" placeholder=" products" [formControl]="typeahead"  matInput aria-label="Site search" type="text"[matAutocomplete]="auto" #searchField>
                                
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
                                <button class="drop-search__button drop-search__button--submit" type="submit" (click)="goToSearchPage(searchField.value)">
                                    <app-icon name="search-20" size="20"></app-icon>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="indicator">
                    <a [routerLink]="root.url('/shop/wishlist')" class="indicator__button">
                        <span class="indicator__area">
                            <app-icon name="heart-20" matTooltip="Favourites" size="20"></app-icon>
                            <span class="indicator__value">{{ wishlist.count$|async }}</span>
                        </span>
                    </a>
                </div>

                <div class="indicator indicator--trigger--click" appDropdown="indicator--opened" #cartDropdown="appDropdown">
                    <a href="#" class="indicator__button" (click)="$event.preventDefault(); cartDropdown.toggle()">
                        <span class="indicator__area">
                            <app-icon name="cart-20" matTooltip="View-Cart" size="20"></app-icon>
                            <span class="indicator__value">{{ cart.quantity$|async }}</span>
                        </span>
                    </a>

                    <div class="indicator__dropdown">
                        <app-header-dropcart (toggleCart)="toggleCart()"></app-header-dropcart>
                    </div>
                </div>

                <div *ngIf="!openShop  && !catalogPreview" class="indicator">
                   
                    <a class="indicator__button" (click)="openLogoffModal(template)">
                        <span class="indicator__area">
                            <span class="material-icons" matTooltip="Log Out" size="20">
                                power_settings_new
                                </span>
                                
                        </span>
                    </a>
                </div>
                <div *ngIf="catalogPreview" class="indicator">
                   
                    <a class="indicator__button disable-anchor-tag" >
                        <span class="indicator__area">
                            <span class="material-icons" matTooltip="Log Out" size="20">
                                power_settings_new
                                </span>
                                
                        </span>
                    </a>
                </div>



                <div *ngIf="openShop">
                    <button mat-raised-button class="mt-2" (click)="openLogin()">Login</button>
                </div>

            </div>
        </div>
    </div>
</div>




<ng-template #template>
    <div class="modal-body text-center">
        <p>Do you want to logout?</p>
        <button type="button" class="btn btn-default" (click)="confirmLogoff()">Yes</button>
        <button type="button" class="btn btn-primary" (click)="cancelLogoff()">No</button>
    </div>
</ng-template>