import { Component, OnInit } from '@angular/core';
import {ValidateOtpModel} from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { AccountLoginService } from '../../services/account-login.service'
import { ValidateotpResponseModel } from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-catalog-preview',
  templateUrl: './catalog-preview.component.html',
  styleUrls: ['./catalog-preview.component.sass']
})
export class CatalogPreviewComponent implements OnInit {
  
 
  catalogPreviewToken ="";
  catalogId="";
  constructor(private accountloginService:AccountLoginService, 
    private router:Router,
    private route:ActivatedRoute,
    private toastr:ToastrService) { }


  ngOnInit() {
    localStorage.clear();
    this.catalogPreviewToken=this.route.snapshot.params.token;
    this.catalogId = this.route.snapshot.params.catalogId;
     console.log (this.catalogId);
     sessionStorage.setItem('catalogToken',this.catalogPreviewToken);
     sessionStorage.setItem('catalogId',this.catalogId);
     this.accountloginService.previewCatalog().subscribe(response=>{
       if (response)
       { 
        
         sessionStorage.setItem('catalogPreview','true');
         this.router.navigate(['/classic/home']); 
         this.toastr.success('', 'Welcome');
       } 
       else 
       { 
         this.toastr.error('', 'Error');
       }
     },
     (error) => {
       //this.toastr.error('', "Sorry, Please try again later");
     });
  }

}
