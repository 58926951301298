
<div class="confirm-container">
   
    <div mat-dialog-content style="text-align:center">
        <h6>{{ message }}</h6>
        <p>{{ message1}}</p> 
    </div>
    
    <div mat-dialog-actions style="float:right">
        <button mat-button  [mat-dialog-close]="{clicked:'Ok'}">OK</button>
    </div>
</div>