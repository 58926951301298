import { Component, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { CartItem } from '../../../../shared/interfaces/cart-item';
import { RootService } from '../../../../shared/services/root.service';
import { NavComponent } from '../nav/nav.component';
import { ActivatedRoute, Router,NavigationEnd,Event as NavigationEvent } from '@angular/router';
import {LoginDialogComponent} from 'src/app/shared/login-dialog/login-dialog.component';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-header-dropcart',
    templateUrl: './dropcart.component.html',
    styleUrls: ['./dropcart.component.scss']
})
export class DropcartComponent {
    removedItems: CartItem[] = [];
    openShop:boolean;
    grandTotal:string="";
    netTaxAmount:string="";
    netShippingCost:string="";
    subTotal:string="";
    currency: string;
    event$: any;
    items: any=[]
    noOfItemsInViewCart;
    result: number;
    loading:boolean=false;
    constructor(
        public cart: CartService,
        public root: RootService,
        private router: Router,
        private route: ActivatedRoute,
        public matDialog:MatDialog,
        private cd:ChangeDetectorRef
    ) {
       
     }

    ngOnInit(){
        if(sessionStorage.getItem('openShop') ==="true")
        this.openShop = true;
        else
        this.openShop =false;
        
        this.cart.viewServerCartItems().subscribe(cartResponse => {
            this.grandTotal=cartResponse.order[0].header.grandTotal;
            this.netShippingCost=cartResponse.order[0].header.shippingCost;
            this.netTaxAmount=cartResponse.order[0].header.netTaxAmount;
            this.subTotal=cartResponse.order[0].header.totalAmount.value;
            this.currency=cartResponse.order[0].header.totalAmount.currency;
            this.items = cartResponse.order[0].items.item;
            this.noOfItemsInViewCart= cartResponse.order[0].items.item.length;
            this.cd.markForCheck();
            this.cd.detectChanges();
        });

    }
   // @ViewChild('toggleCart',{static:false}) toggleCart:NavComponent;
    @Output() toggleCart= new EventEmitter<number>();
    remove(item: CartItem): void {
        if (this.removedItems.includes(item)) {
            return;
        }

        this.removedItems.push(item);
        this.cart.remove(item).subscribe({complete: () => 
            {
                this.noOfItemsInViewCart = 0; 
                this.removedItems = this.removedItems.filter(eachItem => eachItem !== item)
                
                this.cart.calc();
                console.log(this.router.url);
                if(this.router.url.includes('/classic/shop/cart')){
                    this.reload();
                }
            }    
            });
    }
    toggleCartPop(){
       this.toggleCart.emit();
    }

    removeSpecialCharacters(name:string){
        name=name.replace(/  +/g, ' ')
        var regExp = /[a-zA-Z]/g;
        if(regExp.test(name))
        return name.replace(/[^a-zA-Z ]/g,"")
        else
        return name.replace(/[^0-9 ]/g,"")
    }

    reload(){
        window.location.reload();
      }

    openLogin(){
        const dialogRef = new MatDialogConfig;
        this.matDialog.open(LoginDialogComponent,dialogRef);
    }
    
        async redirectTo(url: string): Promise<boolean> {
            this.toggleCart.emit();
            await this.router.navigateByUrl('.', { skipLocationChange: true });
            return this.router.navigateByUrl(url);
          }
     

}
