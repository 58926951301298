import { CatalogueResponseModel} from '../../app/shared/interfaces/api/service-response/catalogue-response-model'

export const catalogueResponseData: CatalogueResponseModel = 
{
    
       "catalogue" : [ {
          "catHead" : {
             "status" : "A",
             "catName" : "DubaiWholeSale",
             "catId" : "1",
             "catOwnerName" : "DubaiWholeSale",
             "catOwnerId" : "3"
          },
          "categories" : {
             "category" : [ {
                "status" : "A",
                "categoryName" : "Chemicals",
                "categoryId" : "1",
                "categoryImage" : "http://storimart.bytetale.com/images/mart/2/Dubaibanner.jpeg",
                "productsExistDownCategoryTree" : false,
                "products" : {
                   "product" : [ ]
                },
                "category" : [ {
                   "status" : "A",
                   "categoryName" : "Floorcare",
                   "categoryId" : "2",
                   "categoryImage" : "http://storimart.bytetale.com/images/mart/2/Dubaibanner.jpeg",
                   "productsExistDownCategoryTree" : true,
                   "products" : {
                      "product" : [ {
                         "status" : "A",
                         "productShortName" : "Star Glaze Crystallization Powder",
                         "productLongName" : "Star Glaze Crystallization Powder",
                         "productId" : "1",
                         "productMfrPartnum" : "10001",
                         "showProductIcon" : true,
                         "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/4/1/1542905075047_tiny.jpg",
                         "productImage" : "http://storimart.bytetale.com/images/mart/2/1/4/1/1542905075047_med.jpg",
                         "productImageLarge" : "",
                         "productDescription" : "- Marble Crystallizer for Marble and Terazzo, specially formulated for crystallizing, vitrifying big surfaces.\r\n- Designed for renovation of existing marbles. ",
                         "hasFavoriteSku" : true,
                         "isFavoriteProduct" : true,
                         "applicableTaxPercentage" : 0.0,
                         "productShippingCost" : 0.0,
                         "uom" : {
                            "description" : "count",
                            "isqttyfloat" : false,
                            "value" : "item"
                         },
                         "productPrice" : {
                            "actual" : 82.0,
                            "offer" : 80.0,
                            "currency" : "AED"
                         },
                         "minimumquantity" : "0.000",
                         "sku" : [ {
                            "status" : "A",
                            "skuShortName" : "Star Glaze Crystallization Powder-12",
                            "skuLongName" : "Star Glaze Crystallization Powder-12",
                            "skuId" : "1",
                            "skuShopId" : "10001-1",
                            "isFavorite" : true,
                             "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                            "skuPrice" : {
                               "actual" : 82.0,
                               "offer" : 80.0,
                               "currency" : "AED"
                            },
                            "attributes" : {
                               "attribute" : [ {
                                  "name" : "Count",
                                  "skuDefining" : true,
                                  "value" : "12"
                               } ]
                            }
                         } ],
                         "ingredient" : [ ]
                      } ]
                   }
                }, {
                   "status" : "A",
                   "categoryName" : "Washroom Care Chemicals",
                   "categoryId" : "5",
                   "categoryImage" : "http://storimart.bytetale.com/images/mart/2/Dubaibanner.jpeg",
                   "productsExistDownCategoryTree" : true,
                   "products" : {
                      "product" : [ {
                         "status" : "A",
                         "productShortName" : "Antiseptic Disinfectant",
                         "productLongName" : "Antiseptic Disinfectant",
                         "productId" : "5",
                         "productMfrPartnum" : "",
                         "showProductIcon" : true,
                         "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/5/5/1543828705361_tiny.jpg",
                         "productImage" : "http://storimart.bytetale.com/images/mart/2/1/5/5/1543828705370_med.jpg",
                         "productImageLarge" : "",
                         "productDescription" : "Antiseptic Disinfectant\r\n-Concentrated multi purpose sanitiser with a fresh\r\npine fragrance\r\nKills bacteria. Passes EN 1276 at recommended\r\ndilution rate & contact time\r\n -Ideal in schools, leisure centers, clinics and\r\nwherever there is risk of cross infection\r\n-Suitable on floors, walls and a variety of hard\r\nsurfaces ",
                         "hasFavoriteSku" : false,
                         "isFavoriteProduct" : false,
                         "applicableTaxPercentage" : 0.0,
                         "productShippingCost" : 0.0,
                         "uom" : {
                            "description" : "count",
                            "isqttyfloat" : false,
                            "value" : "item"
                         },
                         "productPrice" : {
                            "actual" : 78.0,
                            "currency" : "AED"
                         },
                         "minimumquantity" : "1.000",
                         "sku" : [ {
                            "status" : "A",
                            "skuShortName" : "Antiseptic Disinfectant",
                            "skuId" : "9",
                            "skuShopId" : "",
                            "isFavorite" : false,
                            "stock":{
                              "value":"10",
                              "description":"nil"
                           },
                            "skuPrice" : {
                               "actual" : 78.0,
                               "currency" : "AED"
                            },
                            "attributes" : {
                            }
                         } ],
                         "ingredient" : [ ]
                      }, {
                         "status" : "A",
                         "productShortName" : "Deodorant Blocks",
                         "productLongName" : "Deodorant Blocks ",
                         "productId" : "6",
                         "productMfrPartnum" : "",
                         "showProductIcon" : true,
                         "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/5/6/1543832831342_tiny.jpg",
                         "productImage" : "http://storimart.bytetale.com/images/mart/2/1/5/6/1543832831342_med.jpg",
                         "productImageLarge" : "",
                         "productDescription" : "Deodorant Blocks\r\nDeodorant Toilet Blocks\r\n\r\nDetergent based, for use in urinals and channels\r\nContains residual perfume to deodorize and surfactant to clean\r\nSoluble formulation, to overcome the risk of blocking drains\r\nPacking : 3kg x 2 buckets ",
                         "hasFavoriteSku" : false,
                         "isFavoriteProduct" : false,
                         "applicableTaxPercentage" : 5.0,
                         "productShippingCost" : 0.0,
                         "uom" : {
                            "description" : "count",
                            "isqttyfloat" : false,
                            "value" : "item"
                         },
                         "productPrice" : {
                            "actual" : 121.0,
                            "currency" : "AED"
                         },
                         "minimumquantity" : "2.000",
                         "sku" : [ {
                            "status" : "A",
                            "skuShortName" : "Deodorant Blocks-Black",
                            "skuLongName" : "Deodorant Blocks-Black",
                            "skuId" : "10",
                            "skuShopId" : "PARTNUM-1",
                            "isFavorite" : false,
                            "stock":{
                              "value":"10",
                              "description":"nil"
                           },
                            "skuPrice" : {
                               "actual" : 121.0,
                               "offer" : 110.0,
                               "currency" : "AED"
                            },
                            "attributes" : {
                               "attribute" : [ {
                                  "name" : "Color",
                                  "skuDefining" : true,
                                  "value" : "Black"
                               } ]
                            }
                         } ],
                         "ingredient" : [ ]
                      } ]
                   }
                } ]
             }, {
                "status" : "A",
                "categoryName" : "Equipments",
                "categoryId" : "3",
                "categoryImage" : "http://storimart.bytetale.com/images/mart/2/Dubaibanner.jpeg",
                "productsExistDownCategoryTree" : true,
                "products" : {
                   "product" : [ {
                      "status" : "A",
                      "productShortName" : "Outdoor Garbage Bin",
                      "productLongName" : "Outdoor Garbage Bin",
                      "productId" : "2",
                      "productMfrPartnum" : "221100",
                      "showProductIcon" : true,
                      "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/2/2/1543288826298_tiny.jpg",
                      "productImage" : "http://storimart.bytetale.com/images/mart/2/1/2/2/1543288826302_med.jpg",
                      "productImageLarge" : "",
                      "productDescription" : "2.5CBM , 3.5 CBM ",
                      "hasFavoriteSku" : false,
                      "isFavoriteProduct" : false,
                      "applicableTaxPercentage" : 0.0,
                      "productShippingCost" : 0.0,
                      "uom" : {
                         "description" : "count",
                         "isqttyfloat" : false,
                         "value" : "item"
                      },
                      "productPrice" : {
                         "actual" : 200.0,
                         "offer" : 175.0,
                         "currency" : "AED"
                      },
                      "minimumquantity" : "0.000",
                      "sku" : [ {
                         "status" : "A",
                         "skuShortName" : "Outdoor Garbage Bin",
                         "skuId" : "2",
                         "skuShopId" : "",
                         "isFavorite" : false,
                         "stock":{
                           "value":"10",
                           "description":"nil"
                        },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "offer" : 175.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                         }
                      } ],
                      "ingredient" : [ ]
                   }, {
                      "status" : "A",
                      "productShortName" : "AROMATEK Diffuser",
                      "productLongName" : "AROMATEK Diffuser",
                      "productId" : "3",
                      "productMfrPartnum" : "2222222",
                      "showProductIcon" : true,
                      "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/2/3/1543369106657_tiny.png",
                      "productImage" : "http://storimart.bytetale.com/images/mart/2/1/2/3/1543369106660_med.jpg",
                      "productImageLarge" : "",
                      "productDescription" : "AROMATEK Diffuser  ",
                      "hasFavoriteSku" : true,
                      "isFavoriteProduct" : false,
                      "applicableTaxPercentage" : 0.0,
                      "productShippingCost" : 0.0,
                      "uom" : {
                         "description" : "count",
                         "isqttyfloat" : false,
                         "value" : "item"
                      },
                      "productPrice" : {
                         "actual" : 330.5,
                         "offer" : 310.0,
                         "currency" : "AED"
                      },
                      "minimumquantity" : "1.000",
                      "sku" : [ {
                         "status" : "A",
                         "skuShortName" : "AROMATEK Diffuser-Red",
                         "skuLongName" : "AROMATEK Diffuser-Red",
                         "skuId" : "7",
                         "skuShopId" : "",
                         "isFavorite" : false,
                         "stock":{
                           "value":"10",
                           "description":"nil"
                        },
                         "skuPrice" : {
                            "actual" : 56.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "Red"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "AROMATEK Diffuser-Black",
                         "skuLongName" : "AROMATEK Diffuser-Black",
                         "skuId" : "4",
                         "skuShopId" : "2222222-1",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 350.5,
                            "offer" : 320.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "10"
                            }, {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "Black"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "AROMATEK Diffuser-White",
                         "skuLongName" : "AROMATEK Diffuser-White",
                         "skuId" : "3",
                         "skuShopId" : "2222222-2",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 330.5,
                            "offer" : 310.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "10"
                            }, {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "White"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "AROMATEK Diffuser-Green",
                         "skuLongName" : "AROMATEK Diffuser-Green",
                         "skuId" : "5",
                         "skuShopId" : "4455666",
                         "isFavorite" : true,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 360.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "Green"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "10"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "AROMATEK Diffuser-Red",
                         "skuLongName" : "AROMATEK Diffuser-Red",
                         "skuId" : "6",
                         "skuShopId" : "",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 156.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "Yellow"
                            } ]
                         }
                      } ],
                      "ingredient" : [ ]
                   }, {
                      "status" : "A",
                      "productShortName" : "Cleanex Aromatic Facial Tissues",
                      "productLongName" : "Cleanex Aromatic Facial Tissues boxes various sizes.",
                      "productId" : "7",
                      "productMfrPartnum" : "",
                      "showProductIcon" : true,
                      "productIcon" : "",
                      "productImage" : "",
                      "productImageLarge" : "",
                      "productDescription" : "Cleanex Fragranced Facial Tissues boxes various sizes. Includes various fragrances of fruits and flowers. ",
                      "hasFavoriteSku" : false,
                      "isFavoriteProduct" : false,
                      "applicableTaxPercentage" : 6.75,
                      "productShippingCost" : 0.0,
                      "uom" : {
                         "description" : "count",
                         "isqttyfloat" : false,
                         "value" : "item"
                      },
                      "productPrice" : {
                         "actual" : 200.0,
                         "currency" : "AED"
                      },
                      "minimumquantity" : "1.000",
                      "sku" : [ {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Orange",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Orange",
                         "skuId" : "25",
                         "skuShopId" : "PARTNUM-12",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Orange"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Rose",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Rose",
                         "skuId" : "11",
                         "skuShopId" : "PARTNUM-6",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            }, {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Rose"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Rose",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Rose",
                         "skuId" : "20",
                         "skuShopId" : "PARTNUM-14",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            }, {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Rose"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Apple",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Apple",
                         "skuId" : "24",
                         "skuShopId" : "PARTNUM-13",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Apple"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Apple",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Apple",
                         "skuId" : "14",
                         "skuShopId" : "PARTNUM-5",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            }, {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Apple"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Aquatic",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Aquatic",
                         "skuId" : "27",
                         "skuShopId" : "PARTNUM-11",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Aquatic"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Lemon",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Lemon",
                         "skuId" : "12",
                         "skuShopId" : "PARTNUM-9",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Lemon"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Bourboned Tobacco",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Bourboned Tobacco",
                         "skuId" : "18",
                         "skuShopId" : "PARTNUM-15",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            }, {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Bourboned Tobacco"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-125-Lemon",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-125-Lemon",
                         "skuId" : "13",
                         "skuShopId" : "PARTNUM-17",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Lemon"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "125"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Lilly",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Lilly",
                         "skuId" : "15",
                         "skuShopId" : "PARTNUM-2",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            }, {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Lilly"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Chocolate Honey Love",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Chocolate Honey Love",
                         "skuId" : "21",
                         "skuShopId" : "PARTNUM-16",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Chocolate Honey Love"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Orange",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Orange",
                         "skuId" : "17",
                         "skuShopId" : "PARTNUM-4",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Orange"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Aquatic",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Aquatic",
                         "skuId" : "23",
                         "skuShopId" : "PARTNUM-3",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Aquatic"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Chocolate Honey Love",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Chocolate Honey Love",
                         "skuId" : "16",
                         "skuShopId" : "PARTNUM-8",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Chocolate Honey Love"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Bourboned Tobacco",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Bourboned Tobacco",
                         "skuId" : "19",
                         "skuShopId" : "PARTNUM-7",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Bourboned Tobacco"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-75-Lemon",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-75-Lemon",
                         "skuId" : "22",
                         "skuShopId" : "PARTNUM-1",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Lemon"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "75"
                            } ]
                         }
                      }, {
                         "status" : "A",
                         "skuShortName" : "Cleanex Fragranced Facial Tissues-100-Lilly",
                         "skuLongName" : "Cleanex Fragranced Facial Tissues-100-Lilly",
                         "skuId" : "26",
                         "skuShopId" : "PARTNUM-10",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 200.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Fragrance",
                               "skuDefining" : true,
                               "value" : "Lilly"
                            }, {
                               "name" : "Count",
                               "skuDefining" : true,
                               "value" : "100"
                            } ]
                         }
                      } ],
                      "ingredient" : [ ]
                   } ]
                }
             }, {
                "status" : "A",
                "categoryName" : "Machines",
                "categoryId" : "4",
                "categoryImage" : "http://storimart.bytetale.com/images/mart/2/Dubaibanner.jpeg",
                "productsExistDownCategoryTree" : true,
                "products" : {
                   "product" : [ {
                      "status" : "A",
                      "productShortName" : "Battery Operated Carpet Sweeper",
                      "productLongName" : "Battery Operated Carpet Sweeper",
                      "productId" : "4",
                      "productMfrPartnum" : "2109887",
                      "showProductIcon" : true,
                      "productIcon" : "http://storimart.bytetale.com/images/mart/2/1/3/4/1543549539459_tiny.jpg",
                      "productImage" : "http://storimart.bytetale.com/images/mart/2/1/3/4/1543549539463_med.jpg",
                      "productImageLarge" : "",
                      "productDescription" : "MODEL : BSW 375 ET\r\nBRAND : LAVOR\r\nORIGIN : ITALY\r\n\r\n• POWER : 25 W\r\n• PRODUCTIVITY : 1000 M2/H\r\n• CAPACITY : 2 L ",
                      "hasFavoriteSku" : false,
                      "isFavoriteProduct" : false,
                      "applicableTaxPercentage" : 5.0,
                      "productShippingCost" : 0.0,
                      "uom" : {
                         "description" : "count",
                         "isqttyfloat" : false,
                         "value" : "item"
                      },
                      "productPrice" : {
                         "actual" : 560.0,
                         "currency" : "AED"
                      },
                      "minimumquantity" : "0.000",
                      "sku" : [ {
                         "status" : "A",
                         "skuShortName" : "Battery Operated Carpet Sweeper-Black",
                         "skuLongName" : "Battery Operated Carpet Sweeper-Black",
                         "skuId" : "8",
                         "skuShopId" : "2109887-1",
                         "isFavorite" : false,
                          "stock":{
                                "value":"10",
                                "description":"nil"
                             },
                         "skuPrice" : {
                            "actual" : 610.0,
                            "currency" : "AED"
                         },
                         "attributes" : {
                            "attribute" : [ {
                               "name" : "Color",
                               "skuDefining" : true,
                               "value" : "Black"
                            } ]
                         }
                      } ],
                      "ingredient" : [ ]
                   } ]
                }
             } ]
          }
       } ]
    
 }