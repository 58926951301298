import { Component, Input } from '@angular/core';
import { ProductService } from '../../shop/services/product.service';

@Component({
    selector: 'app-block-categories',
    templateUrl: './block-categories.component.html',
    styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent {
    @Input() header = '';
    @Input() layout: 'classic'|'compact' = 'classic';
    @Input() categories: any[] = [];
    public categoryId : string;

    constructor() {
        console.log(this.categories)
     }

    removeSpecialCharacters(name:string){
        name=name.replace(/  +/g, ' ')
        var regExp = /[a-zA-Z]/g;
        if(regExp.test(name))
        return name.replace(/[^a-zA-Z ]/g,"")
        else
        return name.replace(/[^0-9 ]/g,"")
    }

}
