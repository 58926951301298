import { Inject, Injectable, OnDestroy, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { ProductModel } from '../interfaces/product';
import { map, takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { UserDataModel } from '../interfaces/user';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { ToggleWishlistRequestModel } from '../interfaces/api/service-request/togglewishlist-request-model';
import { ToggleWishlistResponseModel } from '../interfaces/api/service-response/togglewishlist-response-model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WishlistResponseModel } from '../interfaces/api/service-response/wishlist-response-model';
import { ProductService } from 'src/app/modules/shop/services/product.service';
import { ToastrService } from 'ngx-toastr';

interface WishlistData {
    items: ProductModel[];
}

@Injectable({
    providedIn: 'root'
})
export class WishlistService implements OnDestroy {
    private data: WishlistData = {
        items: []
    };

    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<ProductModel[]> = new BehaviorSubject([]);
    private onAddingSubject$: Subject<ProductModel> = new Subject();

    readonly items$: Observable<ProductModel[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
    readonly count$: Observable<number> = this.itemsSubject$.pipe(map(items => items.length));
    readonly onAdding$: Observable<ProductModel> = this.onAddingSubject$.asObservable();    

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private http: HttpClient,
        private toastr: ToastrService,
        private productService: ProductService,
        private accountloginService: AccountLoginService) {
        if (isPlatformBrowser(this.platformId)) {
            this.load();
        } 
    }

    toggle(product: ProductModel): Observable<ProductModel> {
    
        return new Observable<ProductModel>(observer => {
            this.ToggleWishlist(product).subscribe((response: ToggleWishlistResponseModel) => {
                //this.onAddingSubject$.next(product);
                if (response.status.success === true) {
                    const index = this.data.items.findIndex(item =>
                        {
                        if(product.activeSku && item.activeSku)
                        {
                            if (item.activeSku.skuId !== product.activeSku.skuId) {
                                return false;
                            }
                        }
                        else
                        {
                            if (item.id !== product.id) {
                                return false;
                            }
                        }

                        return true;
                    });

                if (index === -1) {
                   this.data.items.push(product);
                   this.toastr.success(`Product "${product.name}" added to Favourites!`);
                   this.save();
                   observer.next(product);
                   observer.complete();
                }
                else if (index !== -1) {
                    this.data.items.splice(index, 1);
                    this.toastr.success(`Product "${product.name}" removed from Favourites!`);
                    this.save();
                    observer.complete();
                }
                else {
                    observer.error();
                }
            }
        },
           (error) => {
              observer.error();
            });
        });
    }


    syncWishlist(){
        let user: UserDataModel = {};
        
		sessionStorage.removeItem('wishlistItems');
        this.data.items = [];
        this.itemsSubject$.next(this.data.items);
        user = this.accountloginService.getLocalUser();

          return this.http.get<WishlistResponseModel>(environment.serviceUrl + 'favprodsbyuser')
                .subscribe((response: WishlistResponseModel) => {
                    if (response.order.length > 0  && response.order[0].header.action != "ERRORED") {
                         this.data.items = [];
                              response.order[0].items.item.map(item => {
                            this.productService.getProductDetail(item.productId).subscribe(product =>{
                                product.activeSku =  product.sku.find(x => x.skuId== item.skuId);
                                let wishlistItem: ProductModel = product;
                                wishlistItem.activeSku =  product.sku.find(x => x.skuId== item.skuId);
                                this.data.items.push(wishlistItem);
                                this.save();
                            });
                        })
                }
            },
            (error) => {                              //Error callback
              console.error('error caught in component');        
              this.save();
              //throw error;   //You can also throw the error to a global error handler
            });
    }

    



    ToggleWishlist(product: ProductModel){

        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let togglewishlistrequest: ToggleWishlistRequestModel= {
            qty: "0",
            skuId: product.activeSku.skuId == "PRD"?product.id.toString() : product.activeSku.skuId,
            currency: product.currency,
            skuPrice: (product.activeSku.skuPrice.offer?product.activeSku.skuPrice.offer: product.activeSku.skuPrice.actual).toString(),
            uom: product.uom.value,
            action: "ADD"
        };

        return this.http.post<ToggleWishlistResponseModel>(environment.serviceUrl + 'togglefavourite', togglewishlistrequest, {});
    }
    

    remove(product: ProductModel): Observable<void> {
        return new Observable<void>(observer => {
            this.ToggleWishlist(product).subscribe((response: ToggleWishlistResponseModel) => {
                if (response.status.success === true) {
                     const index = this.data.items.findIndex(item => 
                     {
                        if(product.activeSku)
                        {
                            if (item.activeSku.skuId !== product.activeSku.skuId) {
                                return false;
                            }
                        }
                        else
                        {
                            if (item.id !== product.id) {
                                return false;
                            }
                        }

                        return true;
                    });
                    if (index !== -1) {
                        this.data.items.splice(index, 1);
                        this.save();
                    }
                    else {
                        observer.error();
                    }
                }
            },
                (error) => {
                    observer.error();
                });
            });
    }

    private save(): void {
        /* let user: UserDataModel = {};
        user = this.accountloginService.getLocalUser();
        console.log(user);
        if(user != 'null'){
        let wish_user=user.status.userId+'wishlistItems'
        localStorage.setItem(wish_user, JSON.stringify(this.data.items));
        } */
       
        sessionStorage.setItem('wishlistItems', JSON.stringify(this.data.items));
        this.itemsSubject$.next(this.data.items);
    }

    private load(): void {
        /* let user: UserDataModel = {};
        user = this.accountloginService.getLocalUser();
        if(user != null){
        let wish_user=user.status.userId+'wishlistItems'
        var items = localStorage.getItem(wish_user);
        } */
       
        const items = sessionStorage.getItem('wishlistItems');
        if (items) {
            this.data.items = JSON.parse(items);
            this.itemsSubject$.next(this.data.items);
           
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }               
}