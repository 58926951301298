<!-- .block-products-carousel -->
<div class="block block-products-carousel" [ngClass]="{'block-products-carousel--loading': loading}"
    [attr.data-layout]="layout">
    <div [ngClass]="{'container': !withSidebar}">
        <ng-container *ngIf="enableCarousel" >
            <app-block-header [header]="header" [groups]="groups" [arrows]="true" (next)="carousel.next()"
                (prev)="carousel.prev()" (groupChange)="groupChange.emit($event)"></app-block-header>

            <div class="block-products-carousel__slider">
                <div class="block-products-carousel__preloader"></div>
                <owl-carousel-o [options]="carouselOptions" appOwlPreventClick #carousel>
                    <ng-container *ngFor="let column of columns">
                        <ng-template carouselSlide>
                            <div class="block-products-carousel__column">
                                <div *ngFor="let product of column" class="block-products-carousel__cell">
                                    <app-product-card [product]="product" showStock=false [id]="id"></app-product-card>
                                </div>
                            </div>
                        </ng-template>
                    </ng-container>
                </owl-carousel-o>
            </div>    
        </ng-container>
        <ng-container *ngIf="!enableCarousel">
            <app-block-header [header]="header" [groups]="groups" [arrows]="false"></app-block-header>
            <ng-container *ngFor="let column of columns">
                <div *ngFor="let product of column" class="block-products-no-carousel__cell">
                    <app-product-card [product]="product" showStock=false [id]="id"></app-product-card>
                </div>
            </ng-container>
        </ng-container>            
    </div>
</div>
<!-- .block-products-carousel / end -->