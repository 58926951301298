


<div class="authentication-bg pb-0" >
    <div class="auth-fluid">
        <!--Auth fluid left content -->
        
        <div class="auth-fluid-right" style="background-color: white;">
            
            <img class="victor" src="../../../../../assets/images/slides/login-4.png">
            <div class="child">
                <img src="../../../../../assets/images/slides/login-12.png" width="200" > 
            
            <div class ="row">
                <div style="color: gray; padding-top:40px;">
                    <h1>ORDER.</h1>
                    <h1>PROCESS.</h1>
                    <h1>DELIVER.</h1>

                </div>
                <img src="../../../../../assets/images/slides/login-2.png" width="200">
            </div>
            <div><img src="../../../../../assets/images/slides/login-3.png" width="200"></div>
        </div>  

        </div>
        
        <!-- Logo -->
        <div class="auth-brand text-lg-right d-flex">
            <div class="title-block">
                <div>
                    <a href="" class="logo">
                        <span><img src={{logo}} alt=""
                                width="200"></span>
                    </a>
                </div>
            </div>
        </div>
      
        <div class="auth-fluid-form-box">
            <div class="align-items-center d-flex">            
                <div class="card-body">
                    <!-- form -->

                    <div *ngIf="isVerification">
                        <h4 class="pt-3 pb-5">Verify Pin and Set Password</h4>
                        <form [formGroup]="verificationForm">
                            <div>
                                    
                                <mat-form-field class="mt-2" appearance="outline">
                                    <mat-label>Email/Phone</mat-label>
                                    <input matInput placeholder="Email/Phone"
                                        (keydown.space)="$event.preventDefault();" formControlName="phoneNumber"
                                        required>
                                    <mat-icon tabindex="-1" matSuffix>account_circle</mat-icon>
                                    <mat-error *ngIf="!!verificationForm.controls.phoneNumber?.errors?.required">
                                       Please enter Phone/Email</mat-error>
                                    <mat-error
                                    *ngIf="verificationForm.controls.phoneNumber?.errors?.pattern">
                                        Invalid phone/Email</mat-error>
                                </mat-form-field>
                            
                        </div>
                        <div>
                        <mat-form-field class="mt-2" appearance="outline">
                            <mat-label>PIN</mat-label>
                            <input matInput type="password" placeholder="PIN" (keydown.space)="$event.preventDefault();" formControlName="otp" required [type]="hidePin? 'password':'text'">
                            <button mat-icon-button tabindex="-1" matSuffix (click)="hidePin = !hidePin" >
                                <mat-icon>{{hidePin ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>
                            <mat-error *ngIf="!!verificationForm.controls.otp?.errors?.required">Please enter Pin
                            </mat-error>
                        </mat-form-field>

                        </div>

                        <div>
                            <mat-form-field  class="mt-2" hintLabel="Minimum 8 characters" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" minlength="8" placeholder="Password" formControlName="password" required [type]="hidePassword? 'password':'text'">
                                <button mat-icon-button tabindex="-1" matSuffix (click)="hidePassword = !hidePassword" >
                                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
     
                                <mat-error *ngIf="!!verificationForm.controls.otp?.errors?.required || verificationForm.get('password').errors?.minlength" >Password is required and must be of minimum 8 characters
                                </mat-error>
                            </mat-form-field>
    
                            </div>

                            <div>
                                <mat-form-field class="mt-2" appearance="outline">
                                    <mat-label>Retype Password</mat-label>
                                    <input matInput type="password"  minlength="8" placeholder="Confirm Password" formControlName="confirmPassword" required [type]="hideConfirmPassword? 'password':'text'">
                                    <button mat-icon-button tabindex="-1" matSuffix (click)="hideConfirmPassword = !hideConfirmPassword" >
                                        <mat-icon>{{hideConfirmPassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                        </button>
                                    <mat-error *ngIf="!!verificationForm.controls.otp?.errors?.required  && verificationForm.controls['confirmPassword'].touched">Please re-enter the password
                                    </mat-error>
                                    <mat-error *ngIf="verificationForm.controls['confirmPassword']?.errors?.mustMatch  && verificationForm.controls['confirmPassword'].touched"> Passwords must match </mat-error> 
                                </mat-form-field>
        
                                </div>
                                
                                <div class="text-center mt-2">
 
                                    <button (click)="doVerification(verificationForm.value)" type="button" class="btn btn-primary btn-block mt-2 mt-md-3 mt-lg-4"
                                        >VERIFY</button>
                                </div>
                                <div class="text-center mt-2">
                                            <button type="button" class="btn btn-link pr-5"
                                            (click)="backToLoginPage()">Back To Login</button>        
                                            <button (click)="forgotOtp()" class="btn btn-link pl-5" 
                                             >Get New Pin</button>
                                 </div>
    
                               


                   
                        </form>

                    </div>
                   

                    <div *ngIf="!isforgetPasswordView && !isVerification && !isforgetOtpView">
                        <h4 class="mt-0 mb-5 ml-3">Log in</h4>
                        <form [formGroup]="loginForm" (keyup.enter)="doLogin(loginForm.value)">
                            <div class="container-fluid">
                               
                                        <div *ngIf="sessionTimedOut && !openShop" class="row">
                                            <div class="session-timed-out col mb-2">
                                                <h6 class= "ml-4" style="color:red">Your session timed out. Please log in again </h6>
                                            </div>
                                        </div>
                                        <div *ngIf="!sessionTimedOut" class="row">
                                        <h4 class="ml-3">Welcome</h4>
                                        </div>
                                        <div class="mt-2">
                                        <mat-form-field appearance="outline">
                                            <mat-label>Email/Phone</mat-label>
                                            <input matInput placeholder="Email/Phone"
                                                (keydown.space)="$event.preventDefault();" formControlName="phoneNumber"
                                                required>
                                            <mat-icon tabindex="-1" matSuffix>account_circle</mat-icon>
                                            <mat-error *ngIf="!!loginForm.controls.phoneNumber?.errors?.required">Please Enter Phone/Email</mat-error>
                                                <mat-error
                                                *ngIf="loginForm.controls.phoneNumber?.errors?.pattern">
                                                    Invalid phone/Email</mat-error>
                                            </mat-form-field>
                                    </div>
                                
                            <div>
                            <mat-form-field class="mt-2" appearance="outline">
                                <mat-label>Password</mat-label>
                                <input matInput type="password" (keydown.space)="$event.preventDefault();"  placeholder="Enter Password" formControlName="otp" required [type]="hidePassword? 'password':'text'">
                                <button mat-icon-button tabindex="-1" matSuffix (click)="hidePassword = !hidePassword" >
                                    <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
                                    </button>
                                <mat-error *ngIf="!!loginForm.controls.otp?.errors?.required">Please enter your Password
                                </mat-error>
                            </mat-form-field>
                        </div>
                            <div class="text-center mt-2">

                                <button #focusableSubmit type="submit" class="btn btn-primary btn-block mt-2 mt-md-3 mt-lg-4"
                                    (click)="doLogin(loginForm.value)">LOGIN</button>
                                </div>    
                                <div class="text-center mt-2">
                                    <button type="button" class="btn btn-link  pr-5"
                                    (click)="forgotPassword()">Forgot Password?</button>

                                    <button type="button" class="btn btn-link pl-5"
                                    (click)="verifyOtp()">Verify and Set Password?</button>    
                                </div>
                            
                        
                        </div>
                        </form>

                    </div>
                    <div *ngIf="isforgetOtpView">

                        <h4 class="pt-3 pb-5">GET NEW PIN?</h4>
                        <form [formGroup]="resetOtpForm">
                            
                                <div>
                                    
                                        <mat-form-field class="mt-2" appearance="outline">
                                            <mat-label>Email/Phone</mat-label>
                                            <input matInput placeholder="Email/Phone"
                                                (keydown.space)="$event.preventDefault();" formControlName="phoneNumber"
                                                required>
                                            <mat-icon tabindex="-1" matSuffix>account_circle</mat-icon>
                                            <mat-error *ngIf="!!resetOtpForm.controls.phoneNumber?.errors?.required">
                                                Phone Number/Email is required</mat-error>
                                                <mat-error
                                                *ngIf="resetOtpForm.controls.phoneNumber?.errors?.pattern">
                                                    Invalid phone/Email</mat-error>
                                            </mat-form-field>
                                    
                                </div>
                           

                            <div class="text-center mt-2">
                                <button type="submit" class="btn btn-primary btn-block mt-2 mt-md-3 mt-lg-4"
                                    (click)="resetOtp()">GET NEW PIN</button>
                                <button type="button" class="btn btn-link btn-block forgotOtp text-left"
                                    (click)="backToLoginPage()">Back To Login</button>    
                                   
                            </div>
                        </form>
                    </div>
                    <div *ngIf="isforgetPasswordView">

                        <h4 class="pt-3 pb-5">Forgot password?</h4>
                        <form [formGroup]="resetOtpForm">
                            
                                <div>
                                    
                                        <mat-form-field class="mt-2" appearance="outline">
                                            <mat-label>Email/Phone</mat-label>
                                            <input matInput placeholder="Email/Phone"
                                                (keydown.space)="$event.preventDefault();" formControlName="phoneNumber"
                                                required>
                                            <mat-icon tabindex="-1" matSuffix>account_circle</mat-icon>
                                            <mat-error *ngIf="!!resetOtpForm.controls.phoneNumber?.errors?.required">
                                                Phone Number/Email is required</mat-error>
                                                <mat-error
                                                *ngIf="resetOtpForm.controls.phoneNumber?.errors?.pattern">
                                                    Invalid phone/Email</mat-error>
                                            </mat-form-field>
                                    
                                </div>
                           

                            <div class="text-center mt-2">
                                <button type="submit" class="btn btn-primary btn-block mt-2 mt-md-3 mt-lg-4"
                                    (click)="resetOtp()">GET NEW PIN</button>
                                <button type="button" class="btn btn-link btn-block forgotOtp text-left"
                                    (click)="backToLoginPage()">Back To Login</button>    
                                   
                            </div>
                        </form>
                    </div>
                </div> <!-- end .card-body -->
            </div> <!-- end .align-items-center.d-flex.h-100-->
            <footer class="footer footer-alt">
                     
            <div class="mat-toolbar pl-5">
                    <mat-toolbar>
                    <a [href]="websiteUrl" target="_blank" >
                        {{website}}</a>
                    <span class="example-spacer"></span>
                    <a [href]="twitterUrl" [ngStyle]="twitterStyle && {'pointer-events': 'none'}" target="_blank">
                        <img src="assets/images/logos/twitterIcon.png" alt="" matTooltip="Twitter"
                         matTooltipPosition="above">
                    </a>
                   
                    <a [href]="facebookUrl" [ngStyle]="facebookStyle && {'pointer-events': 'none'}" target="_blank">
                        <img src="assets/images/logos/facebookIcon.png"  alt="" matTooltip="Facebook" matTooltipPosition="above">
                    </a>
                   
                    <a [href]="linkedinUrl" [ngStyle]="linkedinStyle && {'pointer-events': 'none'}" target="_blank">
                        <img src="assets/images/logos/linkedinIcon.png" alt="" matTooltip="LinkedIn" matTooltipPosition="above">
                    </a>
                </mat-toolbar>   
               
            </div>
        </footer>
        </div>
        <!-- end auth-fluid-form-box-->

        <!-- Auth fluid right content -->

        <!-- end Auth fluid right content -->
    </div>
    <!-- end auth-fluid-->

    <!-- bundle -->

</div>

