import { Component, Input, } from '@angular/core';
import { StoreService } from '../../shared/services/store.service';
import { AccountLoginService } from '../account/services/account-login.service';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AvatarModule } from 'ngx-avatar';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() layout: 'classic'|'compact' = 'classic';
    warehouselogo: string;
    warehousename: string;
    retailername: string;
    openShop:boolean ;
    catalogPreview:boolean= false;

    constructor(public store: StoreService,public accountloginService: AccountLoginService) { 

        let userDatamodel : UserDataModel ={};
        userDatamodel = this.accountloginService.getLocalUser();
        this.warehouselogo = userDatamodel.warehouses[0].warehouseLogo;
        this.warehousename = userDatamodel.warehouses[0].warehouseName;
        this.retailername = userDatamodel.userInfo.firstName;

       
    }

    ngOnInit() {
        if(sessionStorage.getItem('openShop') ==="true")
        {
        this.openShop = true;
        
        }
        else
        {
        this.openShop =false;
        
        }

        if(sessionStorage.getItem('catalogId') !== null)
        {
        
        this.catalogPreview = true;
        }
        else
        this.catalogPreview = false;
    }
}
