import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

    constructor(public acountLoginService: AccountLoginService, public router: Router) { }
    
    canActivate(): boolean {
        if (!this.acountLoginService.isSignedIn()) {
            this.router.navigate(['login']);
            return false;
        }
        return true;
    }
}