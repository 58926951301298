import { Component,OnInit } from '@angular/core';
import { theme } from '../../../data/theme';
import { version } from '../../../../package.json';
import {MyVersion} from 'src/my-version';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
    theme = theme;
    website:string='';
    public version:string = version;
    curDate;
    ver:number;
    constructor() { }
    ngOnInit(){
        this.curDate = new Date().getFullYear().toString().substr(-2) + '.' + (new Date().getMonth() + 1).toString();
        this.ver=MyVersion.number;
        this.website=environment.website;
    }
}
