<div class="mobile-header">
    <div class="mobile-header__panel">
        <div class="container">
            <div class="mobile-header__body">
                <button class="mobile-header__menu-button" (click)="menu.open()">
                    <app-icon name="menu-18x14" size="18x14"></app-icon>
                </button>
                <a class="mobile-header__logo" routerLink="./home">
                    <!-- <div width="120px" height="20px">
                        <img class="logo mt-3" src="../../../../../assets/images/logos/storimart-icon.png">
                    </div> -->
                </a>
                <div class="mobile-header__search" appDropdown="mobile-header__search--opened" #search="appDropdown">
                    <form class="mobile-header__search-form" action="classic/shop/search-results">
                        <input class="mobile-header__search-input" name="search" placeholder="Search products" aria-label="Site search" type="text" autocomplete="off" (keydown.escape)="search.close()" #searchInput>
                        <button class="mobile-header__search-button mobile-header__search-button--submit" type="submit">
                            <app-icon name="search-20" size="20"></app-icon>
                        </button>
                        <button class="mobile-header__search-button mobile-header__search-button--close" type="button" (click)="search.close()">
                            <app-icon name="cross-20" size="20"></app-icon>
                        </button>
                        <div class="mobile-header__search-body"></div>
                    </form>
                </div>

                <div class="mobile-header__indicators">
                    <div class="indicator indicator--mobile-search indicator--mobile d-sm-none">
                        <button class="indicator__button" (click)="search.open(); searchInput.focus()">
                            <span class="indicator__area">
                                <app-icon name="search-20" size="20"></app-icon>
                            </span>
                        </button>
                    </div>

                    <div class="indicator indicator--mobile d-sm-flex d-none">
                        <a routerLink="./shop/wishlist" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="heart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ wishlist.count$|async }}</span>
                            </span>
                        </a>
                    </div>

                    <div class="indicator indicator--mobile">
                        <a routerLink="./shop/cart" class="indicator__button">
                            <span class="indicator__area">
                                <app-icon name="cart-20" size="20"></app-icon>
                                <span class="indicator__value">{{ cart.quantity$|async }}</span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>