import { Component, OnInit } from '@angular/core';
import {ValidateOtpModel} from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { AccountLoginService } from '../../services/account-login.service'
import { ValidateotpResponseModel } from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-open-shop',
  templateUrl: './open-shop.component.html',
  styleUrls: ['./open-shop.component.sass']
})
export class OpenShopComponent implements OnInit {

  loginForm ={
		phoneNumber:undefined,
		otp:undefined,
		
  };
  openShopToken ="";
  validateotpresponse : ValidateotpResponseModel;
  constructor(private accountloginService:AccountLoginService, 
    private router:Router,
    private route:ActivatedRoute,
    private toastr:ToastrService) { }

  ngOnInit() {
    this.openShopToken=this.route.snapshot.params.token;
   // console.log (this.openShopToken);
    sessionStorage.setItem('openShopToken',this.openShopToken);
    this.accountloginService.checkOpenShop().subscribe(response=>{
      if (response)
      { 
       
        sessionStorage.setItem('openShop','true');
        this.router.navigate(['/classic/home']); 
        this.toastr.success('', 'Welcome');
      } 
      else 
      { 
        this.toastr.error('', 'Error');
      }
    },
    (error) => {
      //this.toastr.error('', "Sorry, Please try again later");
    });
  }

}
