import { Component } from '@angular/core';
import { posts } from '../../../data/blog-posts';
import { brands } from '../../../data/shop-brands';
import { ProductService } from 'src/app/modules/shop/services/product.service';
import { CategoryModel } from 'src/app/shared/interfaces/block-category';
import { ProductModel } from 'src/app/shared/interfaces/product';
import { Category } from 'src/app/shared/interfaces/api/service-response/catalogue-response-model';
import * as _ from 'lodash';
import { Product } from 'src/app/shared/interfaces/api/service-response/loadoffers-response-model';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WishlistResponseModel } from 'src/app/shared/interfaces/api/service-response/wishlist-response-model';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import {ChangeDetectorRef} from '@angular/core'
import { map, takeUntil } from 'rxjs/operators';

interface WishlistData {
  items: ProductModel[];
}

@Component({
    selector: 'app-home',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {

   data: WishlistData = {
    items: []
};
    products : ProductModel[] = []
    categories : CategoryModel[];
    posts = posts;
    brands = brands;
    wishlistItems:any;
    dataLoaded=false;
    private destroy$: Subject<void> = new Subject();
    private itemsSubject$: BehaviorSubject<ProductModel[]> = new BehaviorSubject([]);
    readonly items$: Observable<ProductModel[]> = this.itemsSubject$.pipe(takeUntil(this.destroy$));
    // columns = [
    //     {
    //         header: 'Top Rated Products',
    //         products: products.slice(0, 3)
    //     },
    //     {
    //         header: 'Special Offers',
    //         products: products.slice(3, 6)
    //     },
    //     {
    //         header: 'Bestsellers',
    //         products: products.slice(6, 9)
    //     }
    // ];

    

    offerProducts = {
        loading: false,
        products: this.products.slice(),
    
        // groups: [
        //     {name: 'All', current: true},
        //     {name: 'Power Tools', current: false},
        //     {name: 'Hand Tools', current: false},
        //     {name: 'Plumbing', current: false}
        // ],

        // timeout: null, // only for demo

        // groupChange: group => {
        //     // only for demo
        //     this.featuredProducts.loading = true;

        //     clearTimeout(this.featuredProducts.timeout);

        //     this.featuredProducts.timeout = setTimeout(() => {
        //         const itemsArray = this.featuredProducts.products;
        //         const newItemsArray = [];
        //         while (itemsArray.length > 0) {
        //             const randomIndex = Math.floor(Math.random() * itemsArray.length);
        //             const randomItem = itemsArray.splice(randomIndex, 1)[0];
        //             newItemsArray.push(randomItem);
        //         }
        //         this.featuredProducts.products = newItemsArray;
        //         this.featuredProducts.loading = false;
        //     }, 1000);
        // }
    };
    offerProducts1={
      loading: false,
      products: this.products.slice(),
    }
    favouriteProducts={
      loading: false,
      products: this.products.slice()
    }
    favouriteProducts1:any;
  catalogPreview: boolean;

    // newArrivals = {
    //     loading: false,
    //     products: products.slice(),
    //     groups: [
    //         {name: 'All', current: true},
    //         {name: 'Power Tools', current: false},
    //         {name: 'Hand Tools', current: false},
    //         {name: 'Plumbing', current: false}
    //     ],

    //     timeout: null, // only for demo

    //     groupChange: group => {
    //         // only for demo
    //         this.newArrivals.loading = true;

    //         clearTimeout(this.newArrivals.timeout);

    //         this.newArrivals.timeout = setTimeout(() => {
    //             const itemsArray = this.newArrivals.products;
    //             const newItemsArray = [];
    //             while (itemsArray.length > 0) {
    //                 const randomIndex = Math.floor(Math.random() * itemsArray.length);
    //                 const randomItem = itemsArray.splice(randomIndex, 1)[0];
    //                 newItemsArray.push(randomItem);
    //             }
    //             this.newArrivals.products = newItemsArray;
    //             this.newArrivals.loading = false;
    //         }, 1000);
    //     }
    // };

    constructor(private ref: ChangeDetectorRef, private http: HttpClient,private productService: ProductService, public wishlist: WishlistService,private accountloginService:AccountLoginService) 

    {

      
      this.ref.markForCheck(); 
     
      
        if(sessionStorage.getItem("category") === null)
        {
            productService.getCatalogsOnly().subscribe(categories =>{
              console.log(categories);
            this.categories = categories.slice(0,6);
            this.categories.forEach(category => {
              if (category.image == "https://storimart.bytetale.com/images/products/no_Img.png") {
                category.image = "InvalidURL";
              }
            })
        },
        (error) => {
          console.log(error);
        });
    }
        else 
        this.getCategoryList();
  
        
        
        this.offerProducts.loading = true;
        productService.loadOfferProducts().subscribe(products =>{
            this.offerProducts.products = products;
            this.offerProducts.loading = false;
            this.addOfferSkus();
        },
        (error) => {
          console.log(error);   
        });

        if(sessionStorage.getItem('wishlistItems') === null)
        {
           this.syncWishList();
           
        
        
        }
       else
        {
        this.dataLoaded=true;
        this.favouriteProducts.products = JSON.parse(sessionStorage.getItem('wishlistItems'));
        }

       
       
       }
       
        
     ngOnInit(){
     
     }

     private save(): void {
      sessionStorage.setItem('wishlistItems', JSON.stringify(this.data.items));
        this.itemsSubject$.next(this.data.items);
        
    }

    syncWishList(){
      sessionStorage.removeItem('wishlistItems');
      this.data.items = [];
      this.favouriteProducts.loading=true;
      return this.http.get<WishlistResponseModel>(environment.serviceUrl + 'favprodsbyuser')
              .subscribe((response: WishlistResponseModel) => {
                  if (response.order.length > 0  && response.order[0].header.action != "ERRORED") {
                       this.data.items = [];
                       this.favouriteProducts.loading=false;
                        response.order[0].items.item.map(item => {
                          this.productService.getProductDetail(item.productId).subscribe(product =>{
                              
                              product.activeSku =  product.sku.find(x => x.skuId== item.skuId);
                              let wishlistItem: ProductModel = product;
                              wishlistItem.activeSku =  product.sku.find(x => x.skuId== item.skuId);
                              this.data.items.push(wishlistItem);
                              this.favouriteProducts.products.push(product);
                              this.save();
                             
                          });
                          
                      })
                  
                     
                   
              }
              this.dataLoaded=true;   
          });
          
    }
     
    addOfferSkus(){
      var k=0;
       for(var j=0;j<this.offerProducts.products.length;j++)
       {
      
        if(this.offerProducts.products[j].sku.length > 1){
          for(var i=0;i<this.offerProducts.products[j].sku.length;i++)
          {
          
            if(this.offerProducts.products[j].sku[i].skuPrice.offer!= null)
            {
              var shallow:ProductModel =_.clone(this.offerProducts.products[j]);
              shallow.isThisSku= this.offerProducts.products[j].sku[i].skuId;
              this.offerProducts1.products.push(shallow);
              //this.offerProducts1.products[k++].isThisSku=this.offerProducts.products[j].sku[i].skuId;
            }
          }
          
        }
        else{
          this.offerProducts1.products.push(this.offerProducts.products[j]);
          k++;
        }
      
      }
        //console.log(this.offerProducts1);
     }

    
  getCategoryList(){
        let category :CategoryModel[] = [];

        let response = JSON.parse(sessionStorage.getItem('category'));

        category = response.catalogue.find(cat=> cat.catHead.status=="A").
                                                              categories.category.filter(x=>x.status=="A").
                                                              map(category => 
                                                                {
                                                                      return this.tranformCatalogueCategoryToCategoryModel(category);
                                                                      
                                                                });
        this.categories=category.slice(0,6);
        this.categories.forEach(category => {
          if (category.image.includes("no_Img.png")) {
            category.image = "InvalidURL";
          }
        })
     }

     ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }         
    
tranformCatalogueCategoryToCategoryModel(category: Category) : CategoryModel
  {
    if(category) //if category exists
      return {
        title : category.categoryName,
        url : "/classic/shop/category/" + category.categoryId+'/'+ encodeURIComponent(category.categoryName),
        image : category.categoryImage,
        products : category["products"]?category["products"].product.length:0,
        subcategories : category.category!=undefined ?  category.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToCategoryModel(category)}): null    
      }
  }
}

