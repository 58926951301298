import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-infodialog',
  templateUrl: './infodialog.component.html',
  styleUrls: ['./infodialog.component.sass']
})
export class InfodialogComponent implements OnInit {
  message = '';
  message1 ="";
  constructor(private dialogRef: MatDialogRef<InfodialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: { message: string, message1:string }) {
    this.message = data ? data.message : '';
    this.message1 =data ? data.message1 : '';
    }
    
  ngOnInit() {
  }

}
