import { Injectable, NgModule } from '@angular/core';
import { CategoryModel } from '../../../shared/interfaces/block-category';
import { ProductModel, Sku } from '../../../shared/interfaces/product';
import { CatalogueResponseModel, Category, Product } from 'src/app/shared/interfaces/api/service-response/catalogue-response-model';
import { Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AccountLoginService } from '../../account/services/account-login.service';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { ProductdetailResponseModel } from 'src/app/shared/interfaces/api/service-response/productdetail-response-model';
import { LoadOffersResponseModel, Product as offerProduct } from 'src/app/shared/interfaces/api/service-response/loadoffers-response-model';
import { ProductSearchResponseModel, Product as searchProduct } from 'src/app/shared/interfaces/api/service-response/productsearch-response-model';
import {Sku  as OfferProductSku} from '../../../shared/interfaces/api/service-response/loadoffers-response-model';
import {Sku  as ProductModelSku} from '../../../shared/interfaces/api/service-response/productdetail-response-model';
import { catchError, map } from 'rxjs/operators';


@NgModule({
  declarations: [
      // components
      // pages
  ],
  imports: [
      // modules (angular)
  ]
})

@Injectable()
 export class ProductService {
   
  private products : ProductModel[] = []; 
  additionalImages:string[]=[];

  
    constructor(private http: HttpClient, 
                private accountService: AccountLoginService) { 
   
  }


  //#region Methods for Home Page popular categories

  getCatalogsOnly(){
    let retailerId =""
    let user: UserDataModel =  this.accountService.getLocalUser();
    
    if (user.shops.length === 0)
    retailerId = user.warehouses[0].wareHouseStoreId;
    else
    retailerId =user.shops[0].shopId;
    if(sessionStorage.getItem('catalogId') !== null)
    {
      var catalogId =sessionStorage.getItem("catalogId");
    
    //console.log(retailerId);
    return new Observable<CategoryModel[]>(observer => {
      this.http.get<any>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId=' + retailerId + '&catalogId='+ catalogId+'&searchType=ALLCATSONLY')
        .subscribe((response: any)=> {
						if (response !=null && response.catalogue.length> 0)
						{
              let categories :CategoryModel[] = [];
              console.log(response)
              categories = response.catalogue.find(cat=> cat.catHead.status=="A").
              categories.category.filter(x=>x.status=="A").
              map(category => 
                {
                      return this.tranformCatalogueCategoryToCategoryModel(category);
                      
                });
              observer.next(categories);
            }
              else{
                observer.error();
                } 
        },
				(error) => {
					observer.error();
         });
    });
  }
  else
  {
    return new Observable<CategoryModel[]>(observer => {
      this.http.get<any>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId=' + retailerId +'&searchType=ALLCATSONLY')
        .subscribe((response: any)=> {
						if (response !=null && response.catalogue.length> 0)
						{
              let categories :CategoryModel[] = [];
              console.log(response)
              categories = response.catalogue.find(cat=> cat.catHead.status=="A").
              categories.category.filter(x=>x.status=="A").
              map(category => 
                {
                      return this.tranformCatalogueCategoryToCategoryModel(category);
                      
                });
              observer.next(categories);
            }
              else{
                observer.error();
                } 
        },
				(error) => {
					observer.error();
         });
    }); 
  }
  }
  
  getProductCategory(){
    let retailerId =""
    let user: UserDataModel =  this.accountService.getLocalUser();
    
    
    if(sessionStorage.getItem("catalogId") === null)
    {
     
    return new Observable<CategoryModel[]>(observer => {

      this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId=' + user.shops[0].shopId)
        .subscribe((response: CatalogueResponseModel)=> {
						if (response !=null && response.catalogue.length> 0)
						{
              let categories :CategoryModel[] = [];
              categories = response.catalogue.find(cat=> cat.catHead.status=="A").
                                                                    categories.category.filter(x=>x.status=="A").
                                                                    map(category => 
                                                                      {
                                                                            return this.tranformCatalogueCategoryToCategoryModel(category);
                                                                            
                                                                      });
                                                                    observer.next(categories);
            }
              else{
                observer.error();
                } 
        },
				(error) => {
					observer.error();
         });
    });
  }

 else if(sessionStorage.getItem("catalogId") !== null)
  {

    var catalogId =sessionStorage.getItem("catalogId");
    if (user.shops.length !== 0)
    {
    return new Observable<CategoryModel[]>(observer => {

      this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId=' + user.shops[0].shopId + '&catalogId='+ catalogId)
        .subscribe((response: CatalogueResponseModel)=> {
						if (response !=null && response.catalogue.length> 0)
						{
              let categories :CategoryModel[] = [];
              categories = response.catalogue.find(cat=> cat.catHead.status=="A").
                                                                    categories.category.filter(x=>x.status=="A").
                                                                    map(category => 
                                                                      {
                                                                            return this.tranformCatalogueCategoryToCategoryModel(category);
                                                                            
                                                                      });
                                                                    observer.next(categories);
            }
              else{
                observer.error();
                } 
        },
				(error) => {
					observer.error();
         });
    });
  }
  else if (user.shops.length === 0){
    
    
    return new Observable<CategoryModel[]>(observer => {
     
      this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId  + '&catalogId='+ catalogId)
        .subscribe((response: CatalogueResponseModel)=> {
						if (response !=null && response.catalogue.length> 0)
						{
              let categories :CategoryModel[] = [];
              categories = response.catalogue.find(cat=> cat.catHead.status=="A").
                                                                    categories.category.filter(x=>x.status=="A").
                                                                    map(category => 
                                                                      {
                                                                            return this.tranformCatalogueCategoryToCategoryModel(category);
                                                                            
                                                                      });
                                                                    observer.next(categories);
            }
              else{
                observer.error();
                } 
        },
				(error) => {
					observer.error();
         });
    }); 
  }
  }
  }
  

  tranformCatalogueCategoryToCategoryModel(category: Category) : CategoryModel
  {
    if(category) //if category exists
      return {
        title : category.categoryName,
        url : "/classic/shop/category/" + category.categoryId+'/'+ encodeURIComponent(category.categoryName),
        image : category.categoryImage,
        products : category["products"]? category["products"].product.length : 0,
        subcategories : category.category!=undefined ?  category.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToCategoryModel(category)}): null    
      }
  }

  getProductListUnderCategory(categoryId: string) {
    let user: UserDataModel = this.accountService.getLocalUser();
    if (sessionStorage.getItem("catalogId") === null) {
      return this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+user.shops[0].shopId+'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1',)
    }
    else if (sessionStorage.getItem("catalogId") !== null) {
      var catalogId =sessionStorage.getItem("catalogId");
      if (user.shops.length !== 0) {
        return this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+user.shops[0].shopId+'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1'+'&catalogId='+ catalogId)
      }
      else if (user.shops.length === 0) {
        return this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId +'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1'+'&catalogId='+ catalogId)
      }    
    }

  }

  //#endregion

  //#region Get List of products in each category.
  getProductListInCategory(categoryId : string){ 
    
    this.products = [];

    let user: UserDataModel =  this.accountService.getLocalUser();
    
    if(sessionStorage.getItem("catalogId") === null){
      return new Observable<ProductModel[]>(observer => {
        this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+user.shops[0].shopId+'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1',)
          .subscribe((response: CatalogueResponseModel)=> {
                if (response)
                {
                  
                        response.catalogue[0].categories.category.map(category => 
                        {
                         this.tranformProductsInEachCategory(category);
                        });
                  
                        observer.next(this.products);
                        
                  }
                  else {
                          observer.error();
                      }
                  },
                  (error) => {
                    observer.error();
                   });
              });
    }
    else if(sessionStorage.getItem("catalogId") !== null)
    {
      var catalogId =sessionStorage.getItem("catalogId");
      if (user.shops.length === 0)
      {
      return new Observable<ProductModel[]>(observer => {
        this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId +'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1'+'&catalogId='+ catalogId)
          .subscribe((response: CatalogueResponseModel)=> {
                if (response)
                {
                  
                        response.catalogue[0].categories.category.map(category => 
                        {
                          this.tranformProductsInEachCategory(category);
                        });
                  
                        observer.next(this.products);
                        
                  }
                  else {
                          observer.error();
                      }
                  },
                  (error) => {
                    observer.error();
                   });
              });
            }
     else if (user.shops.length !== 0){
      return new Observable<ProductModel[]>(observer => {
        this.http.get<CatalogueResponseModel>(environment.serviceUrl+'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+user.shops[0].shopId+'&searchType=CATPRODS'+'&categoryId=' + categoryId +'&langId=1'+'&catalogId='+ catalogId)
          .subscribe((response: CatalogueResponseModel)=> {
                if (response)
                {
                  
                        response.catalogue[0].categories.category.map(category => 
                        {
                         this.tranformProductsInEachCategory(category);
                        });
                  
                        observer.next(this.products);
                        
                  }
                  else {
                          observer.error();
                      }
                  },
                  (error) => {
                    observer.error();
                   });
              });
     }
    }
               
  }

  tranformProductsInEachCategory(category: Category) 
  {
    if(category.productsExistDownCategoryTree)
    {
      category.products.product.map(product=>{
        this.products.push(this.tranformProductToProductModel(product));
        } 
      );
    }

    //If Sub category exists

    if(category.category!= undefined)
    {
      category.category.map(category=>
        {
          this.tranformProductsInEachCategory(category);
        }
      );
    }

  }

  tranformProductToProductModel(product: Product) : ProductModel
  {
    let user: UserDataModel =  this.accountService.getLocalUser();
    
    if(product)
    { //if product exists
      const productImages =[product.productImageLarge];
      const skuImage:string[]=[];
      if (product.additionalImages){
        product.additionalImages.forEach((obj) => {
          (obj != null) && productImages.push(obj.imageUrl)
        });    
      }
     
      if(product.sku){
        if(product.sku[0] && product.sku[0].skuImage != null){
          skuImage.push(product.sku[0].skuImage);
        }
        else if(product.productImage && product.sku[0] && product.sku[0].skuImage === null)
        {
          skuImage.push[product.productImageLarge];
        }
      }

     /*  if(product.sku){
        if(product.sku[0].additionalImages){
          (product.sku[0].additionalImages).forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
        else if(!product.sku[0].additionalImages && product.additionalImages){
          product.additionalImages.forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
      } */
      return {
        id: Number(product.productId),
        shopProductId:product.shopProductId,
        name: product.productShortName,
        price: product.productPrice,
        offerPrice: (product.sku && product.sku[0])?  ((product.sku[0].skuPrice.offer)? product.sku[0].skuPrice.offer: undefined) :  product.productPrice.offer ,
        actualPrice: (product.sku  && product.sku[0] && product.sku[0].skuPrice.actual)? product.sku[0].skuPrice.actual :  product.productPrice.actual ,
        taxIncluded : product.sku[0].skuPrice.taxIncluded, 
        currency: (product.productPrice.currency),
        description: product.productDescription,
        compareAtPrice: 0,
        images:(product.sku && (skuImage.length>0))? skuImage: productImages,
        productImage:[product.productImage],
        productImageLarge:product.productImageLarge,
        badges: ["new"],
        rating: 0,
        reviews: 0,
        availability:"available",
        sku: product.sku,
        activeSku: product.sku[0],
        additionalImages:product.additionalImages,
        applicableTaxPercentage : product.applicableTaxPercentage,
        uom: product["uom"],
        breadCrumb:product.breadCrumb,
        minimumquantity: Number(product["minimumquantity"]),
        features: [],
        options: []
      }
  }
}

  

  //#endregion

  //#region Methods for Product Page - /classic/shop/product/*  
  getProductDetail(productid : string): Observable<ProductModel>{

    
    let user: UserDataModel =  this.accountService.getLocalUser();
    
   
   
    if(sessionStorage.getItem("catalogId") === null){ 
      return new Observable<ProductModel>(observer => {
        this.http.get<ProductdetailResponseModel>(environment.serviceUrl + 'productdetail?shopId=' + user.warehouses[0].wareHouseStoreId   
                                                                         + '&retailShopId='+ user.shops[0].shopId 
                                                                         + '&productId=' + productid)          
             .subscribe((response: ProductdetailResponseModel)=> {
                      if(response.product)
                      {
                        let product : ProductModel;
                        product =  this.tranformProductDetailToProductModel(response.product[0]);
                        observer.next(product);
                      }
                      else 
                      {
                        observer.error();
                      }
                  },
                  (error) => {
                      observer.error();
                   });
              });
     }

     else  if(sessionStorage.getItem("catalogId") !== null){
      var catalogId =sessionStorage.getItem("catalogId");
      if (user.shops.length === 0)
      {
        return new Observable<ProductModel>(observer => {
          this.http.get<ProductdetailResponseModel>(environment.serviceUrl + 'productdetail?shopId=' + user.warehouses[0].wareHouseStoreId   
                                                                           +'&catalogId='+ catalogId
                                                                           + '&productId=' + productid)          
               .subscribe((response: ProductdetailResponseModel)=> {
                        if(response.product)
                        {
                          let product : ProductModel;
                          product =  this.tranformProductDetailToProductModel(response.product[0]);
                          observer.next(product);
                        }
                        else 
                        {
                          observer.error();
                        }
                    },
                    (error) => {
                        observer.error();
                     });
                });
      }
      else if (user.shops.length !== 0){
        return new Observable<ProductModel>(observer => {
          this.http.get<ProductdetailResponseModel>(environment.serviceUrl + 'productdetail?shopId=' + user.warehouses[0].wareHouseStoreId   
                                                                    + '&retailShopId='+ user.shops[0].shopId  +'&catalogId='+ catalogId
                                                                           + '&productId=' + productid)          
               .subscribe((response: ProductdetailResponseModel)=> {
                        if(response.product)
                        {
                          let product : ProductModel;
                          product =  this.tranformProductDetailToProductModel(response.product[0]);
                          observer.next(product);
                        }
                        else 
                        {
                          observer.error();
                        }
                    },
                    (error) => {
                        observer.error();
                     });
                });
      }
    
    }
  }

  tranformProductDetailToProductModel(product: Product) : ProductModel
  {

    let user:UserDataModel=this.accountService.getLocalUser();
 
    if(product) //if product exists
    {
      const productImages =[product.productImageLarge];
      const skuImage:string[]=[];
      if (product.additionalImages){
        product.additionalImages.forEach((obj) => {
          (obj != null) && (obj.status === "A") && productImages.push(obj.imageUrl)
        });    
      }
      if(product.sku){
        if(product.sku[0] && product.sku[0].skuImage != null){
          skuImage.push(product.sku[0].skuImage);
        }
        else if(product.productImage && product.sku[0] && product.sku[0].skuImage === null)
        {
          skuImage.push[product.productImageLarge];
        }
      }

    /*   if(product.sku){
        if(product.sku[0].additionalImages){
          (product.sku[0].additionalImages).forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
        else if(!product.sku[0].additionalImages && product.additionalImages){
          product.additionalImages.forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
      } */
    
      return {
        id: Number(product.productId),
        shopProductId:product.shopProductId,
        name: product.productShortName,
        price: product.productPrice,
        offerPrice: (product.sku && product.sku[0])?  ((product.sku[0].skuPrice.offer)? product.sku[0].skuPrice.offer: undefined) :  product.productPrice.offer ,
        actualPrice: (product.sku  && product.sku[0].skuPrice.actual)? product.sku[0].skuPrice.actual :  product.productPrice.actual ,
        taxIncluded : user.warehouses[0].applyTax === true? product.sku[0].skuPrice.taxIncluded: null, 
        currency: (product.productPrice.currency),
        description: product.productDescription,
        compareAtPrice: 0,
        images:(product.sku && (skuImage.length > 0))? skuImage: productImages,
        productImage:[product.productImage],
        productImageLarge:product.productImageLarge,
        additionalImages:product.additionalImages,
        badges: ["new"],
        rating: 0,
        reviews: 0,
        availability:"available",
        sku: product.sku,
        activeSku: product.sku[0],
        applicableTaxPercentage : product.applicableTaxPercentage,
        uom: product["uom"],
        breadCrumb:product.breadCrumb,
        minimumquantity: Number(product["minimumquantity"]),
        features: [],
        options: []
      }
    }
  }

  //#endregion

  loadOfferProducts(){
    this.products = [];
    let user: UserDataModel =  this.accountService.getLocalUser();

    return new Observable<ProductModel[]>(observer => {
      this.http.get<LoadOffersResponseModel>(environment.serviceUrl +'loadofferprods')
        .subscribe((response: LoadOffersResponseModel)=> {
					  	if (response)
						  {
                      response.product.map(product=> 
                      {
                        this.products.push(this.tranformOfferProductToProductModel(product));
                      });
                
                      observer.next(this.products);
                      
                }
                else {
                        observer.error();
                    }
                },
                (error) => {
                  observer.error();
                 });
            });
               
  }
  tranformOfferProductToProductModel(product: offerProduct) : ProductModel
  {
    let user:UserDataModel=this.accountService.getLocalUser();
    if(product) //if product exists
    {
      const productImages =[product.productImageLarge];
      const skuImage:string[]=[];
      if (product.additionalImages){
        product.additionalImages.forEach((obj) => {
          (obj != null) && (obj.status === "A")&& productImages.push(obj.imageUrl)
        });    
      }
      if(product.sku){
        if(product.sku[0] && product.sku[0].skuImage != null){
          skuImage.push(product.sku[0].skuImage);
        }
        else if(product.productImage && product.sku[0] && product.sku[0].skuImage === null)
        {
          skuImage.push[product.productImageLarge];
        }
      }

    /*   if(product.sku){
        if(product.sku[0].additionalImages){
          (product.sku[0].additionalImages).forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
        else if(!product.sku[0].additionalImages && product.additionalImages){
          product.additionalImages.forEach((obj) => {
            (obj != null) && skuImage.push(obj.imageUrl)
          }); 
        }
      } */

      return {
        id: Number(product.productId),
        shopProductId:product.shopProductId,
        name: product.productShortName,
        price: product.productPrice,
        offerPrice: (product.sku && product.sku[0])?  ((product.sku[0].skuPrice.offer)? product.sku[0].skuPrice.offer: undefined) :  product.productPrice.offer,
        actualPrice: (product.sku  && product.sku[0] && product.sku[0].skuPrice && product.sku[0].skuPrice.actual)? product.sku[0].skuPrice.actual :  product.productPrice.actual ,
        taxIncluded : user.warehouses[0].applyTax === true? product.sku[0].skuPrice.taxIncluded: null, 
        currency: product.productPrice.currency,
        description: product.productDescription,
        images:(product.sku && (skuImage.length > 0))? skuImage: productImages,
        productImage:[product.productImage],
        productImageLarge:product.productImageLarge,
        additionalImages:product.additionalImages,
        badges: ['offer'],
        compareAtPrice: 0, 
        rating: 0,
        reviews: 0,
        availability:"available",
        sku: this.transformOfferSkuToProductModelSku(product.sku),
        activeSku: this.transformOfferSkuToProductModelSku(product.sku).length>0? this.transformOfferSkuToProductModelSku(product.sku)[0]:undefined,
        applicableTaxPercentage : product.applicableTaxPercentage,
        
        uom: product.uom,
        minimumquantity: Number(product.minimumquantity),
        features: [],
        options: []
      }
    }
  }

transformOfferSkuToProductModelSku(offerSkus: OfferProductSku[]): ProductModelSku[] {
  let productSkus : ProductModelSku[] = [];
  if(offerSkus)
  {
    offerSkus.forEach(x=> { 
      productSkus.push({
        skuShortName: x.skuShortName,
        skuLongName: x.skuLongName,
        skuId: x.skuId,
        skuErpId:x.skuErpId,
        skuImage:x.skuImage,
        skuShopId: x.skuShopId,
        isFavorite: x.isFavorite,
        skuPrice: x.skuPrice,
        attributes: x.attributes,
        stock: x.stock,
      })
    });
  }
  return productSkus;
}

getSearchResults(prdName: string){
 
  this.products = [];

  let retailerId =""
    let user: UserDataModel =  this.accountService.getLocalUser();
    if (user.shops.length === 0)
    retailerId = user.warehouses[0].wareHouseStoreId;
    else
    retailerId =user.shops[0].shopId;
 // user.shops[0].shopId="";
 return new Observable<ProductModel[]>(observer => {
    this.http.get<ProductSearchResponseModel>(environment.serviceUrl + 'prdsearch?prdName='+ prdName + '&shopId=' +  user.warehouses[0].wareHouseStoreId + '&retailShopId='+ user.shops[0].shopId)
      .subscribe((response: ProductSearchResponseModel)=> {
           if(response.product.length>0){
                response.product.forEach(product =>{
                  this.products.push(
                    {
                      id: Number(product.productId),
                      shopProductId:product.shopProductId,
                      name: product.productShortName,
                      price: product.productPrice,
                      offerPrice: (product.sku && product.sku[0])?  ((product.sku[0].skuPrice.offer)? product.sku[0].skuPrice.offer: undefined) :  product.productPrice.offer ,
                      actualPrice: (product.sku  && product.sku[0].skuPrice.actual)? product.sku[0].skuPrice.actual :  product.productPrice.actual ,
                      taxIncluded : user.warehouses[0].applyTax === true? product.sku[0].skuPrice.taxIncluded: null, 
                      currency: product.productPrice.currency,
                      description: product.productDescription,
                      productImage:[product.productImage],
                      productImageLarge:product.productImageLarge,
                      images: (product.sku && (product.sku[0].skuImage!=null))?product.sku[0].skuImage:[product.productImageLarge],
                      badges: ["sale"],
                      compareAtPrice: 0,
                      rating: 0,
                      reviews: 0,
                      availability:"available",
                      sku: this.transformOfferSkuToProductModelSku(product.sku),
                      activeSku: this.transformOfferSkuToProductModelSku(product.sku).length>0? this.transformOfferSkuToProductModelSku(product.sku)[0]:undefined,
                      additionalImages:product.additionalImages,
                      applicableTaxPercentage : product.applicableTaxPercentage,
                      uom: product.uom,
                      minimumquantity: Number(product.minimumquantity),
                      features: [],
                      options: []
                    });
            });
              
            observer.next(this.products);
        }
        else {
              observer.next(this.products);
            }
        },
              (error) => {
                observer.error();
              });
          });
    }

    getSellingPrice(product: ProductModel){
      let sellingPrice = product.activeSku ? (product.activeSku.skuPrice.offer? product.activeSku.skuPrice.offer : product.activeSku.skuPrice.actual) : (product.price.offer?product.price.offer : product.price.actual ); 
      return  sellingPrice;
    }

    getOfferPrice(product: ProductModel){
      let OfferPrice = product.activeSku ? (product.activeSku.skuPrice.offer? product.activeSku.skuPrice.offer : product.price.offer) : ''; 
      return  OfferPrice;
    }

    getActualPrice(product: ProductModel){
   
      let actualPrice = product.activeSku ? (product.activeSku.skuPrice.actual? product.activeSku.skuPrice.actual : product.price.actual) : '';
      return  actualPrice;
    }
}