import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { CartService } from '../../../../shared/services/cart.service';
import { WishlistService } from '../../../../shared/services/wishlist.service';
import { RootService } from '../../../../shared/services/root.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {LoginDialogComponent} from 'src/app/shared/login-dialog/login-dialog.component'
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { DropcartComponent } from '../dropcart/dropcart.component';
import {MatDialog,MatDialogConfig, MatDialogRef} from '@angular/material/dialog'
import {map, startWith} from 'rxjs/operators';
import {ReactiveFormsModule} from '@angular/forms';
import { DepartmentsService } from '../../../../shared/services/departments.service';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-header-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss']
})
export class NavComponent {
    @Input() departments = true;
    @Input() logo = false;
    @Input() search = false;

    faCoffee = faCoffee;
    myForm: FormGroup;
    modalRef: BsModalRef;
    message: string; 
    openShop:boolean;
    typeahead: FormControl = new FormControl();
    suggestions: string[] = [];
    catalogPreview:boolean=false;
    noOfItemsInViewCart=this.cart.noOfItemsInCart;
    products:string[]=[];
    filteredOptions: Observable<string[]>;

    @ViewChild('cartDropdown',{static:false}) toggledata;
    constructor(
        public root: RootService,
        private router: Router,
        private toastr: ToastrService,
        public cart: CartService,
        public wishlist: WishlistService,
        private modalService: BsModalService,
        private accountloginService : AccountLoginService,
        private formBuilder: FormBuilder,
        public matDialog:MatDialog,
        private departmentsService: DepartmentsService,
    ) { 
        this.createForm();
    }


ngOnInit(){
  this.noOfItemsInViewCart=this.cart.noOfItemsInCart;
    if(sessionStorage.getItem('openShop') ==="true")
    this.openShop = true;
    else
    this.openShop =false;
    if(sessionStorage.getItem("catalogId") === null)
    this.catalogPreview=false;
    else this.catalogPreview=true;

    this.products=[];
   /*  if(localStorage.getItem("catalogId") === null)
    {
     this.departmentsService.getDepartmentsForSearch().subscribe(items =>{
      this.products = items;
      
},
(error) => {
console.log(error);
}); 
} */




    this.filteredOptions = this.typeahead.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filter(value))
      );
}

private createForm() {
    this.myForm = this.formBuilder.group({
      username: '',
      password: ''
    });
  }
  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.products.filter(option => option.toLowerCase().includes(filterValue));
  }
 
 openLogin(){

    const dialogRef = new MatDialogConfig;
    this.matDialog.open(LoginDialogComponent,dialogRef);

 }

    openLogoffModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {class: 'modal-sm'});
    }
     
    confirmLogoff(): void {
        this.logout();
    }
    
    cancelLogoff(): void {
        this.modalRef.hide();
    }



    logout () {

        this.accountloginService.logout().subscribe(response =>{
           if(response.status.success == true ) {
            this.accountloginService.removeLocalUser();
            this.router.navigate(['login']);
            this.modalRef.hide();
            this.toastr.success('', 'Logged Out Successfully');
           }
        },
        (error) => {
          console.log(error);
          this.toastr.error("",'Logging out failed, sorry');
        });
        
    }
    toggleCart(){
        this.toggledata.toggle()
    }

    goToSearchPage(value){
      this.router.navigate(["classic/shop/search-results"] ,{ queryParams: { search : value } }); 
  }
}
