import { Injectable, NgModule } from '@angular/core';
import { AddPaymentViewModel } from 'src/app/shared/interfaces/addpayment';
import { AddPaymentRequestModel } from 'src/app/shared/interfaces/api/service-request/addpayment-request-model';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AccountLoginService } from '../../account/services/account-login.service';
import { ViewPaymentRequestModel } from 'src/app/shared/interfaces/api/service-request/viewpayment-request-model';
import { ViewPaymentViewModel } from 'src/app/shared/interfaces/viewpayment';
import { ViewPaymentResponseModel } from 'src/app/shared/interfaces/api/service-response/viewpayment-response-model';
import { viewPaymentResponseData } from 'src/data/api/viewpayment-response';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { AddPaymentResponseModel } from 'src/app/shared/interfaces/api/service-response/addpayment-response-model';
import { Observable } from 'rxjs';


@NgModule({
  declarations: [
    // components
    // pages
  ],
  imports: [
    // modules (angular)
  ]
})

@Injectable()
export class PaymentService {

  payments: ViewPaymentViewModel[] = [];

  constructor(private accountloginService: AccountLoginService, private http: HttpClient) { }

  addPayment(addpaymentViewModel: AddPaymentViewModel, orderid: string) {

    let userDataModel: UserDataModel = {};
    userDataModel = this.accountloginService.getLocalUser();


    let addpaymentrequest: AddPaymentRequestModel = {
      
      orderId: orderid,
      paidAmount: addpaymentViewModel.paidAmount,
      paidCurrency: addpaymentViewModel.paidCurrency,
      paidDate: addpaymentViewModel.paidDate,
      paymentRef: addpaymentViewModel.paymentRef,
      paymentModeId: addpaymentViewModel.paymentModeId,
      paymentNote: ''
    }

    return new Observable<boolean>(observer => {
      this.http.post<AddPaymentResponseModel>(environment.serviceUrl + 'addpayment', addpaymentrequest)
        .subscribe((response: AddPaymentResponseModel) => {
          if (response.status.success === true) {
            observer.next(true);
          }
          else {
            observer.next(false);
          }
        },
          (error) => {
            observer.error();
          });
    })
  }

  viewPayments(orderId: string) {

    //clean payments array 
    this.payments = [];
    let userDataModel: UserDataModel = {};
    userDataModel = this.accountloginService.getLocalUser();

    let viewpaymentrequest: ViewPaymentRequestModel = {
      
      orderId: orderId
    }

  
    return new Observable<ViewPaymentViewModel[]>(observer => {
      this.http.post<ViewPaymentResponseModel>(environment.serviceUrl + 'viewpayment', viewpaymentrequest)
        .subscribe((response: ViewPaymentResponseModel) => {
          if (response.status.success === true) {
            response.payments.forEach(payments => {
              this.payments.push({
                paymentAmount: payments.paymentAmount,
                paymentModeId: payments.paymentModeId,
                paymentMode: (payments.paymentModeId == '1' ? "cash" :
                  (payments.paymentModeId == '2' ? "cheque" :
                    (payments.paymentModeId == '3' ? "demand draft" :
                      (payments.paymentModeId == '4' ? "Online Payment" : 'Unknown')))),
                paidDate: payments.paidDate,
                currency: payments.currency,
                paymentRef: payments.paymentRef,
                orderId : response.orderId
              });
            });
            observer.next(this.payments);
          }
          else {
            observer.error();
          }
        },
          (error) => {
            observer.error();
          });
    });
  }


  getTotalPaidAmount(orderId: string) {

    let userDataModel: UserDataModel = {};
    userDataModel = this.accountloginService.getLocalUser();

    let viewpaymentrequest: ViewPaymentRequestModel = {
      
      orderId: orderId
    }

  
    return new Observable<string>(observer => {
      this.http.post<ViewPaymentResponseModel>(environment.serviceUrl + 'viewpayment', viewpaymentrequest)
        .subscribe((response: ViewPaymentResponseModel) => {
          if (response.status.success === true) {
            observer.next(response.paidAmount);
          }
          else {
            observer.error();
          }
        },
        (error) => {
          observer.error();
        });
    });
  }

}