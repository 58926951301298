import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { UserDataModel } from '../interfaces/user';
import { HttpClient } from '@angular/common/http';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { environment } from 'src/environments/environment';
import { CatalogueResponseModel, Category, Categories } from 'src/app/shared/interfaces/api/service-response/catalogue-response-model';
import { MobileMenuItem } from '../interfaces/mobile-menu-item';
import { catalogueResponseData } from '../../../data/api/catalogue-response';

@Injectable({
    providedIn: 'root'
})
export class MobileMenuService {
    private openSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    isOpen$: Observable<boolean> = this.openSubject$.asObservable();
    private catalogueResponseModel : CatalogueResponseModel;
    links: MobileMenuItem[] 

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,private accountService: AccountLoginService,private http: HttpClient,
    ) {
        this.links= [];
        this.catalogueResponseModel = catalogueResponseData; 
     }

    open(): void {
        if (isPlatformBrowser(this.platformId)) {
            const bodyWidth = document.body.offsetWidth;

            document.body.style.overflow = 'hidden';
            document.body.style.paddingRight = (document.body.offsetWidth - bodyWidth) + 'px';

            this.openSubject$.next(true);
        }
    }

    close(): void {
        if (isPlatformBrowser(this.platformId)) {
            document.body.style.overflow = 'auto';
            document.body.style.paddingRight = '';

            this.openSubject$.next(false);
        }
    }

    toggle(): void {
        this.openSubject$.next(!this.openSubject$.value);
    }


    getDepartmentsForMobile(){
        let retailerId =""
        let user: UserDataModel =  this.accountService.getLocalUser();
        if (user.shops.length === 0)
        retailerId = user.warehouses[0].wareHouseStoreId;
        else
        retailerId =user.shops[0].shopId;
            
            return new Observable<MobileMenuItem[]>(observer => {
              this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+ retailerId )
                .subscribe((response: CatalogueResponseModel)=> {
                    if (response !=null && response.catalogue.length> 0)
                    {
                        let links: MobileMenuItem[] = []; 
    
                        links = response.catalogue.find(cat=> cat.catHead.status=="A").categories.category.filter(x=>x.status=="A").map(category => 
                        {
                            return this.tranformCatalogueCategoryToMobileLinkModel(category);
                        });
    
                       
                        observer.next(links);
                    }
                    else 
                    {
                            observer.error();
                    }
                },
                (error) => {
                        observer.error();
                });
            });
         
    } 

    tranformCatalogueCategoryToMobileLinkModel(currentCategory: Category) : MobileMenuItem
    {

        if(currentCategory) //if category exists
        return {
                label : currentCategory.categoryName,
                url :  "/classic/shop/category/" + currentCategory.categoryId+'/'+encodeURIComponent(currentCategory.categoryName),
                type: "link",
                children: currentCategory.category!= undefined?  currentCategory.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToNestedLInk(category)}) : null
                }          
        }
    

    tranformCatalogueCategoryToNestedLInk(category: Category) : MobileMenuItem
    {
        if(category) //if category exists
        return {
                label : category.categoryName,
                url :  "/classic/shop/category/" + category.categoryId+'/'+encodeURIComponent(category.categoryName),
                type:"link",
                children: category.category!=undefined ?  category.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToNestedLInk(category)}) : null
        }
        
    }
}
