<div class="footer-newsletter">
    <!--<h5 class="footer-newsletter__title">Newsletter</h5>
    <div class="footer-newsletter__text">
        Praesent pellentesque volutpat ex, vitae auctor lorem pulvinar mollis felis at lacinia.
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address">Email Address</label>
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" placeholder="Email Address...">

        <button class="footer-newsletter__form-button btn btn-primary">Subscribe</button>
    </form>-->

    <div *ngIf="socialNetwork" class="footer-newsletter__text footer-newsletter__text--social">
        Follow us on social networks
    </div>

    <ul class="footer-newsletter__social-links">
        <li *ngIf="facebookUrl" class="footer-newsletter__social-link footer-newsletter__social-link--facebook"><a [href]="facebookUrl" [ngStyle]="facebookStyle && {'pointer-events': 'none'}" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
    
        <li  *ngIf="twitterUrl" class="footer-newsletter__social-link footer-newsletter__social-link--twitter"><a [href]="twitterUrl" [ngStyle]="twitterStyle && {'pointer-events': 'none'}" target="_blank"><i class="fab fa-twitter"></i></a></li>
        
        <li *ngIf="youtubeUrl" class="footer-newsletter__social-link footer-newsletter__social-link--youtube"><a [href]="youtubeUrl" [ngStyle]="youtubeStyle && {'pointer-events': 'none'}" target="_blank"><i class="fab fa-youtube"></i></a></li>
       
        <li *ngIf="instagramUrl"  class="footer-newsletter__social-link footer-newsletter__social-link--instagram"><a [href]="instagramUrl" [ngStyle]="instagramStyle && {'pointer-events': 'none'}" target="_blank"><i class="fab fa-instagram"></i></a></li>
       
        <li *ngIf="linkedinUrl" class="footer-newsletter__social-link footer-newsletter__social-link--linkedIn"><a [href]="linkedinUrl" [ngStyle]="linkedinStyle && {'pointer-events': 'none'}" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
       
       <!--  <li class="footer-newsletter__social-link footer-newsletter__social-link--whatsapp"><a href="https://api.whatsapp.com/send?phone=17206162680" ><i class="fab fa-whatsapp fa-lg" aria-hidden="true"></i></a></li> -->
    </ul>
</div>
