import { MobileMenuItem } from '../app/shared/interfaces/mobile-menu-item';

export const mobileMenu: MobileMenuItem[] = [
    {type: 'link', label: 'Home', url: '/classic/home', children:[]},

    {type: 'button', label: 'Categories', children: []},
 {type: 'button', label: 'Account', children: [
       
        {type: 'link', label: 'User Profile',       url: './account/dashboard'},
        {type: 'link', label: 'Order History',   url: './account/orderhistory'},
        {type: 'link', label: 'Log Out',   url: '/login'},
        
    ]},
 
 {type: 'button', label: 'Pages', children: [
       
        {type: 'link', label: 'FAQ',                  url: './site/faq'},
        
    ]},

    

   
];
