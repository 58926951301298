import { Component, Input,OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DirectionService } from '../../../shared/services/direction.service';
import {AccountLoginService} from '../../account/services/account-login.service';
import {Banner,ServerBanner,Image} from '../../../shared/interfaces/banner'
import { SlideComponent } from 'ngx-bootstrap';
import * as _ from 'lodash';

@Component({
    selector: 'app-block-slideshow',
    templateUrl: './block-slideshow.component.html',
    styleUrls: ['./block-slideshow.component.scss']
})
export class BlockSlideshowComponent {
    

    @Input() withDepartments = false;

    options = {
        nav: false,
        dots: true,
        loop: true,
        autoplay:true,
        autoplayTimeout:2000,
        autoplayHoverPause:true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    tempslides :Banner[] = [];
    slides :Banner[] = [];

        /* {
            title: '',
            text: '',
            image_classic: 'assets/images/slides/slide-1.jpg',
            image_full: 'assets/images/slides/slide-1-full.jpg',
            image_mobile: 'assets/images/slides/slide-1-mobile.jpg'
        }
        // ,
        // {
        //     title: 'Screwdrivers<br>Professional Tools',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
        //     image_classic: 'assets/images/slides/slide-2.jpg',
        //     image_full: 'assets/images/slides/slide-2-full.jpg',
        //     image_mobile: 'assets/images/slides/slide-2-mobile.jpg'
        // },
        // {
        //     title: 'One more<br>Unique header',
        //     text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
        //     image_classic: 'assets/images/slides/slide-3.jpg',
        //     image_full: 'assets/images/slides/slide-3-full.jpg',
        //     image_mobile: 'assets/images/slides/slide-3-mobile.jpg'
        // }
    ]; */


    

    constructor(
        public sanitizer: DomSanitizer,
        private direction: DirectionService,
        private accountLoginService: AccountLoginService
    ) { 

    }

   ngOnInit(){
    this.accountLoginService.getBanners().subscribe(response =>{
        if(!_.isEmpty(response)){
        (response as ServerBanner).images.forEach (( image: Image) => {
            const uiImage: Banner = {
                title:image.title,
                text: image.overlayText,
                image_mobile :image.imageBannerMobile,
                image_classic:image.imageBannerWeb,
                image_full:image.imageBannerFull
            };
            this.tempslides.push(uiImage);
        });
        }
        this.slides=this.tempslides;
        if(this.slides.length === 0)
        {
            this.slides =[{
                title: '',
                text: '',
                image_classic: 'assets/images/slides/slide-1.jpg',
                image_full: 'assets/images/slides/slide-1-full.jpg',
                image_mobile: 'assets/images/slides/slide-1-mobile.jpg'
            }]
        }
        
    });
   }
}
