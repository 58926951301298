import { Price } from './api/service-response/orderhistory-response-model';

export interface ProductFeature {
    name: string;
    value: string;
}

export interface ProductFeaturesSection {
    name: string;
    features: ProductFeature[];
}

export interface ProductReview {
    avatar: string;
    author: string;
    rating: number;
    date: string;
    text: string;
}

export interface Uom {
    description: string;
    isqttyfloat: boolean;
    value: string;
}

export interface ProductPrice {
    actual: number;
    offer?: number;
    currency: string;
    taxIncluded?:boolean;
    priceDescription?:string;
}

export interface SkuPrice {
    actual: number;
    offer?: number;
    currency: string;
    taxIncluded?:boolean;
    priceDescription?:string;
}

export interface Attribute {
    name: string;
    skuDefining: boolean;
    value: string;
}

export interface Attributes {
    attribute?: Attribute[];
}

export interface Sku {
        status?: string;
        skuShortName?: string;
        skuId?: string;
        skuErpId?:string;
        skuShopId?: string;
        isFavorite: boolean;
        basePrice?:string;
        pricingUom?:string;
        puMultiple?:string;
        skuPrice?: ProductPrice;
        attributes?: Attributes;
        skuLongName?: string;
        skuIcon?:string;
        skuImage?:string,
        skuImageLarge?:string,
        stock?:Stock;
        uom?:Uom;
        additionalImages?:SkuAdditionalImages[];
}

export interface SkuAdditionalImages{
    imageUrl: string;
    skuId:string;
    skuImageId:string;
    status:string;
}

export interface Stock{
    value:string;
    description:string;
}

export interface AdditionalImages {
    productId: string;
    imageUrl: string;
    productImageId:string;
    status:string;
}

export interface Status2 {
    value: string;
    message: string;
}

export interface BreadCrumb{
    id: number;
    name: string;
}



export class ProductModel {
    id: number;
    shopProductId?:string;
    name: string;
    price: ProductPrice;
    offerPrice: number;
    actualPrice: number;
    currency: string;
    description: string;
    compareAtPrice: number|null;
    images: string[];
    productImage?:string[];
    productImageLarge?:string;
    badges: ('sale'|'new'|'hot' | 'offer')[];
    rating: number;
    reviews: number;
    availability: string;
    sku?: Sku[];  
    activeSku?: Sku;
    applicableTaxPercentage: number;
    additionalImages?:AdditionalImages[];
    uom: any;
    minimumquantity:number;
    features: ProductFeature[];
    options: Array<SkuAttributeOption>;
    isThisSku?:string;
    status?:Status2;
    breadCrumb?:BreadCrumb[];
    taxIncluded?:boolean
    // private _sellingPrice: number;
    // get sellingPrice(): number {
    //     return this.activeSku ? (this.activeSku["sku-price"].offer? this.activeSku["sku-price"].offer : this.activeSku["sku-price"].actual) : (this.price.offer?this.price.offer : this.price.actual );
    // }

}

export interface SkuAttributeOption {
    name: string;
    skuDefining: boolean;
    value: string[];
}
