import { Injectable, NgModule } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationLinkModel } from '../../shared/interfaces/navigation-link';
import { CatalogueResponseModel, Category, Categories } from 'src/app/shared/interfaces/api/service-response/catalogue-response-model';
import { catalogueResponseData } from '../../../data/api/catalogue-response';
import { NestedLink } from '../interfaces/nested-link';
import { UserDataModel } from '../interfaces/user';
import { HttpClient } from '@angular/common/http';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { environment } from 'src/environments/environment';

@NgModule({
    declarations: [
        // components
        // pages
    ],
    imports: [
        // modules (angular)
    ]
  })


@Injectable()
    export class DepartmentsService {

    private areaElementSubject$: BehaviorSubject<HTMLElement> = new BehaviorSubject(null);

    areaElement$: Observable<HTMLElement> = this.areaElementSubject$.asObservable();

    private items: NavigationLinkModel[];
    private catalogueResponseModel : CatalogueResponseModel;
    productList:string[]=[];
    constructor(private http: HttpClient, 
        private accountService: AccountLoginService) {
        this.items= [];
        this.catalogueResponseModel = catalogueResponseData;  
    }

getDepartmentsForSearch(){
       
    let retailerId =""
    let user: UserDataModel =  this.accountService.getLocalUser();
    if (user.shops.length === 0)
    retailerId = user.warehouses[0].wareHouseStoreId;
    else
    retailerId =user.shops[0].shopId;
    let response = JSON.parse(localStorage.getItem('category'));
        if(response){
        for(var i=0;i<response.catalogue[0].categories.category.length;i++)
        for(var j=0;j<response.catalogue[0].categories.category[i].products.product.length;j++)
        if(response.catalogue[0].categories.category[i].products.product[j].productShortName)
        this.productList.push(response.catalogue[0].categories.category[i].products.product[j].productShortName)
        }
        return this.productList;

    
}



    
getDepartments(){

        
    
    let user: UserDataModel =  this.accountService.getLocalUser();

    if(sessionStorage.getItem("catalogId") === null)
       { 
        return new Observable<NavigationLinkModel[]>(observer => {
          this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId + '&retailShopId='+ user.shops[0].shopId+'&searchType=ALLCATSONLY' )
            .subscribe((response: CatalogueResponseModel)=> {
                if (response !=null && response.catalogue.length> 0)
                {
                    var categoryResponse = JSON.stringify(response);
                    sessionStorage.setItem("category",categoryResponse);
                    let items: NavigationLinkModel[] = []; 

                    items = response.catalogue.find(cat=> cat.catHead.status=="A").categories.category.filter(x=>x.status=="A").map(category => 
                    {
                        return this.tranformCatalogueCategoryToNavigationLinkModel(category);
                    });

                   
                    observer.next(items);
                }
                else 
                {
                        observer.error();
                }
			},
			(error) => {
				    observer.error();
            });
        });
     }
    else if(sessionStorage.getItem("catalogId") !== null)
    {
        var catalogId =sessionStorage.getItem("catalogId");
        if (user.shops.length !== 0)
        {
            return new Observable<NavigationLinkModel[]>(observer => {
                this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId +'&retailShopId=' + user.shops[0].shopId + '&catalogId='+ catalogId+'&searchType=ALLCATSONLY')
                  .subscribe((response: CatalogueResponseModel)=> {
                      if (response !=null && response.catalogue.length> 0)
                      {
                          var categoryResponse = JSON.stringify(response);
                          sessionStorage.setItem("category",categoryResponse);
                          let items: NavigationLinkModel[] = []; 
      
                          items = response.catalogue.find(cat=> cat.catHead.status=="A").categories.category.filter(x=>x.status=="A").map(category => 
                          {
                              return this.tranformCatalogueCategoryToNavigationLinkModel(category);
                          });
      
                         
                          observer.next(items);
                      }
                      else 
                      {
                              observer.error();
                      }
                  },
                  (error) => {
                          observer.error();
                  });
              });
        }
        else if (user.shops.length === 0){
            return new Observable<NavigationLinkModel[]>(observer => {
                this.http.get<CatalogueResponseModel>(environment.serviceUrl + 'catalog?shopId=' + user.warehouses[0].wareHouseStoreId +'&catalogId='+ catalogId+'&searchType=ALLCATSONLY' )
                  .subscribe((response: CatalogueResponseModel)=> {
                      if (response !=null && response.catalogue.length> 0)
                      {
                          var categoryResponse = JSON.stringify(response);
                          sessionStorage.setItem("category",categoryResponse);
                          let items: NavigationLinkModel[] = []; 
      
                          items = response.catalogue.find(cat=> cat.catHead.status=="A").categories.category.filter(x=>x.status=="A").map(category => 
                          {
                              return this.tranformCatalogueCategoryToNavigationLinkModel(category);
                          });
      
                         
                          observer.next(items);
                      }
                      else 
                      {
                              observer.error();
                      }
                  },
                  (error) => {
                          observer.error();
                  });
              });
        
        }   
    }

     }
    

    tranformCatalogueCategoryToNavigationLinkModel(currentCategory: Category) : NavigationLinkModel 
    {

        if(currentCategory) //if category exists
        return {
                label : currentCategory.categoryName,
                url :  "/classic/shop/category/" + currentCategory.categoryId+'/'+ encodeURIComponent(currentCategory.categoryName),
                menu : {
                    type: "menu",
                    items: currentCategory.category!= undefined?  currentCategory.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToNestedLInk(category)}) : null
                }          
        }
    }

    tranformCatalogueCategoryToNestedLInk(category: Category) : NestedLink 
    {
        if(category) //if category exists
        return {
                label : category.categoryName,
                url :  "/classic/shop/category/" + category.categoryId+'/'+encodeURIComponent(category.categoryName),
                
                items: category.category!=undefined ?  category.category.filter(x=>x.status=="A").map(category => {return this.tranformCatalogueCategoryToNestedLInk(category)}) : null
        }
        
    }
    
    setAreaElement(value: HTMLElement): void {
        this.areaElementSubject$.next(value);
    }

    getproductNames(){
        let user: UserDataModel =  this.accountService.getLocalUser();
        return this.http.get<any>(environment.serviceUrl +'autocompletionprodnamesearch?shopId='+ user.warehouses[0].wareHouseStoreId+'&retailShopId=' + user.shops[0].shopId)
    }
}

