
<!-- <div *ngIf="!product"  class="sk-fading-circle">
        <div class="sk-circle1 sk-circle"></div>
        <div class="sk-circle2 sk-circle"></div>
        <div class="sk-circle3 sk-circle"></div>
        <div class="sk-circle4 sk-circle"></div>
        <div class="sk-circle5 sk-circle"></div>
        <div class="sk-circle6 sk-circle"></div>
        <div class="sk-circle7 sk-circle"></div>
        <div class="sk-circle8 sk-circle"></div>
        <div class="sk-circle9 sk-circle"></div>
        <div class="sk-circle10 sk-circle"></div>
        <div class="sk-circle11 sk-circle"></div>
        <div class="sk-circle12 sk-circle"></div>
      </div> -->
<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery">
            <div class="product-gallery" *ngIf="showGallery">
                <div class="product-gallery__featured">
                    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
                        <ng-container *ngFor="let image of images">
                            
                           
                             <!-- <ng-template carouselSlide [id]="image.id">
                                <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                    <ngx-avatar src="{{image.url}}" #imageElement size="500" name="{{removeSpecialCharacters(product.activeSku.skuShortName)}}" [round]="false" initialsSize="3"></ngx-avatar>
      
                                </a>
                            </ng-template>  -->

                            <ng-template carouselSlide [id]="image.id">
                                <a appClick (click)="openPhotoSwipe($event, image);" [href]="image.url" target="_blank">
                                    <img *ngIf="!((image.url).includes('invalidURL'))" [src]="image.url" alt="" #imageElement>
                                   <ngx-avatar *ngIf="(image.url).includes('invalidURL')" size="500" name="{{removeSpecialCharacters(product.activeSku.skuShortName)}}" [round]="false" initialsSize="3"></ngx-avatar>
                                </a>
                            </ng-template> 
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <div *ngIf="images.length > 5" class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions" [appFakeSlides]="images.length" #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                <span class="product-gallery__carousel-item"
                                   [ngClass]="{'product-gallery__carousel-item--active': image.active}"
                                   appClick (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                   <ngx-avatar src="{{image.url}}" name="{{removeSpecialCharacters(product.activeSku.skuShortName)}}" [round]="false" size="75" initialsSize="3"></ngx-avatar>
                                    
                                </span>
                            </ng-template>
                        </ng-container>
                        <ng-container *ngFor="let i of fakeSlides.slides">
                            <ng-template carouselSlide [id]="'fake-slide-'+i"></ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
                <div *ngIf="images.length <= 5" class="product-gallery__carousel">
                    <owl-carousel-o [options]="thumbnailsCarouselOptions1" [appFakeSlides]="images.length" #fakeSlides="appFakeSlides" appOwlPreventClick #thumbnailsCarousel>
                        <ng-container *ngFor="let image of images">
                            <ng-template carouselSlide [id]="image.id">
                                <span class="product-gallery__carousel-item"
                                   [ngClass]="{'product-gallery__carousel-item--active': image.active}"
                                   appClick (click)="featuredCarousel.to(image.id); setActiveImage(image)">
                                   <ngx-avatar src="{{image.url}}" name="{{removeSpecialCharacters(product.activeSku.skuShortName)}}" [round]="false" size="75" initialsSize="3"></ngx-avatar>
                                    
                                </span>
                            </ng-template>

                        </ng-container>
                         <ng-container *ngFor="let i of fakeSlides.slides">
                            <ng-template carouselSlide [id]="'fake-slide-'+i"></ng-template>
                        </ng-container>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div *ngIf="!openShop" class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Wishlist"
                        appClick (click)="addToWishlist()"
                        [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
            </div>
            <h1 *ngIf="product.activeSku" class="product__name">{{ product.activeSku.skuShortName}}</h1>
            <div class="product__description">{{ product.description}}</div>
            <ul class="product__meta">
                <li *ngIf="product.activeSku && product.activeSku.skuShopId">Id: {{ product.activeSku.skuShopId }} </li>
                <li *ngIf="product.activeSku && !product.activeSku.skuShopId && product.activeSku.skuErpId">Id: {{ product.activeSku.skuErpId }} </li>
                <li *ngIf="product.activeSku && !product.activeSku.skuShopId && !product.activeSku.skuErpId">Id: {{ product.activeSku.skuId }} </li>
                <li *ngIf="product.minimumquantity">Min Qty: {{ product.minimumquantity }} </li>
                
             </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="outofstock" *ngIf="showInventory">
                <span>{{stockInfo}}</span>
            </div>

           
            <div *ngIf="product.activeSku" class="product__prices">
                <div *ngIf="product.activeSku.skuPrice.offer">
                    <!-- Sku with offer -->
                    <span class="product-card__new-price"> {{ product.currency }} {{ product.activeSku.skuPrice.offer | number:'1.2-2':'en-IN'}}</span>{{ ' ' }}
                    <span class="product-card__old-price"> {{ product.currency }} {{ product.activeSku.skuPrice.actual | number:'1.2-2':'en-IN' }}</span>
                </div>
                <div *ngIf="!product.activeSku.skuPrice.offer">
                    <!-- Sku without offer -->
                    <span> {{ product.currency }} {{ product.activeSku.skuPrice.actual | number:'1.2-2':'en-IN' }}</span>
                </div> 
                <div *ngIf="taxIncluded === true" style="font-size:.5em;color:black">Incl tax.</div>
                <div *ngIf="taxIncluded === false" style="font-size:.5em;color:black">Excl tax.</div>
            </div>
            <div *ngIf="product.activeSku.basePrice!=null" class="pb-1" >{{product.currency}} {{product.activeSku.basePrice}}/{{product.activeSku.pricingUom}}</div>
            
            <div *ngIf="!product.activeSku" class="product__prices">
                <div *ngIf="product.price.offer">
                    <!-- Product with Offer -->
                    <span class="product-card__new-price"> {{ product.currency }} {{ product.price.offer | number:'1.2-2':'en-IN'}}</span>{{ ' ' }}
                    <span class="product-card__old-price"> {{ product.currency }} {{ product.price.actual | number:'1.2-2':'en-IN' }}</span>
                </div>
                <div *ngIf="!product.price.offer">
                    <!-- Product without Offer -->
                    <span> {{ product.price.currency }} {{ product.price.actual | number:'1.2-2':'en-IN' }}</span>  
                </div>
            <div *ngIf="taxIncluded === true" style="font-size:.5em;color:black">Incl tax.</div>
            <div *ngIf="taxIncluded === false" style="font-size:.5em;color:black">Excl tax.</div>

            </div>

            

            <!-- .product__options -->
            <form class="product__options">
                <div *ngIf="product.options.length === 1">
                <div  class="form-group product__option" *ngFor="let option of product.options">
                    <label  class="product__option-label">{{option.name}}</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label *ngFor="let val of option.value">
                                <input type="radio" name="{{option.name}}" >
                                <span [ngClass]="{'bt-active': getFocosed(val)}" (click)="makeSkuActive(option.name,val)">{{val}}</span>
                            </label>
                        </div>
                    </div>
                </div>
             </div>
             <div *ngIf="product.options.length > 1">
                <div class="form-group product__option">
                    <label  class="product__option-label">{{product.options[0].name}}</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label  *ngFor="let val of product.options[0].value" >
                                <!-- <input type="radio" name="{{product.options[0].name}}" value="{{val}}"> -->
                                <span [ngClass]="{'bt-active': getFocosed(val)}" (click)="makeSkuActive1(product.options[0].name,val)">{{val}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                </div>

                 <div *ngIf="(product.options.length > 1) && multipleLevelSkus">
                    <div class="form-group product__option">
                        <label  class="product__option-label">{{product.options[1].name}}</label>
                        <div  class="input-radio-label">
                            <div class="input-radio-label__list">
                                <label *ngFor="let val of skuCombns">
                                <div  *ngIf="clickedVal === val.name">
                                    <!-- <input type="radio"  name="{{product.options[1].name}}"> -->
                                   <span [ngClass]="{'bt-active': getFocosed(val.value)}"  (click)="makeSkuActive(product.options[1].name,val.value)" >{{val.value}}</span>
                                </div>
                                </label>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                     
                  <!--   <div *ngIf="(product.options.length > 1) && multipleLevelSkus">
                       
                            <div class=" product__option-label pt-2">{{product.options[1].name}} </div>
                           
                                    <div class="btn-group pt-2" *ngFor="let val of skuCombns"  >
                                      <div  *ngIf="clickedVal === val.name" >
                                        <button  class="blue"  (click)="makeSkuActive(product.options[1].name,val.value)" >{{val.value}}</button>
                                    </div>
                                        
                                    </div>
                               
                        
                    </div> -->
                   



                <div class="form-group product__option">
                    <label class="product__option-label">Quantity</label>
                    <div class="product__actions">
                        <div class="product__actions-item">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="product.minimumquantity==0? 1: product.minimumquantity" [max]="showInventory ? product.activeSku.stock: 999999" [formControl]="quantity"  (ngModelChange)="updateCart(product,quantity.value)"></app-input-number>
                            <mat-error style="font-size:xx-small;" *ngIf="(quantity.value < product.minimumquantity) || quantity.value == 0">Min Qty:{{product.minimumquantity==0? 1: product.minimumquantity}}</mat-error>
                           
                            <mat-error style="font-size:xx-small;" *ngIf="((quantity.value == product.activeSku.stock) && (!addToCartBtn) && showInventory)">Maximum Quantity reached!</mat-error>
                            <mat-error style="font-size:xx-small;" *ngIf="((quantity.value > product.activeSku.stock) && (!addToCartBtn) && showInventory)">Specified Quantity Not Available</mat-error>
                        </div>
                        <div  class="product__actions-item product__actions-item--addtocart">
                            <button *ngIf="catalogPreview" class="btn btn-primary btn-lg">Add To Cart</button>
                            <button  type="button" *ngIf="quantityInCart === 0 && !catalogPreview " class="btn btn-primary btn-lg" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}" [disabled]="(showInventory && (quantity.value) > product.activeSku.stock)" [disabled]="(quantity.value < product.minimumquantity)" [disabled]="((showInventory) && ((addToCartBtn) || (+product.activeSku.stock < (product.minimumquantity))))">Add To Cart</button>
                            <button  type="button" *ngIf="quantityInCart > 0 && !catalogPreview"class="btn btn-primary btn-lg" appClick (click)="removeFromCart(product)" [ngClass]="{'btn-loading': removingFromCart}">Remove</button>
                        </div>
                    
                        <div *ngIf="!openShop" class="product__actions-item product__actions-item--wishlist">
                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Wishlist"
                                    [ngClass]="{'btn-loading': togglingWishlist , 'active': productInWishlist}"
                                    appClick (click)="toggleWishlist()">
                                <app-icon name="wishlist-16" size="16"></app-icon>
                          </button>
                        </div>
                    </div>
                </div>
            </form>
    </div>
  </div>
</div>