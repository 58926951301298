import { Component } from '@angular/core';
import { theme } from '../../../../../data/theme';
import {AccountLoginService} from '../../../account/services/account-login.service'

@Component({
    selector: 'app-footer-newsletter',
    templateUrl: './newsletter.component.html',
    styleUrls: ['./newsletter.component.scss']
})
export class NewsletterComponent {
    theme = theme;
    facebookUrl:string="";
    instagramUrl:string="";
    linkedinUrl:string="";
    twitterUrl:string="";
    youtubeUrl:string="";
    facebookStyle:boolean=true;
    instagramStyle:boolean=true;
    linkedinStyle:boolean=true;
    twitterStyle:boolean=true;
    youtubeStyle:boolean=true;

    socialNetwork:boolean=false;
    constructor(private accountLoginService:AccountLoginService) { }

    ngOnInit(){

        this.accountLoginService.getFooterDetails().subscribe((data: any) => {
           
            if(data){
                if(data.facebookUrl)
                    this.facebookUrl = data.facebookUrl;
                if(data.instagramUrl)
                    this.instagramUrl= data.instagramUrl;
                if(data.linkedinUrl)
                    this.linkedinUrl = data.linkedinUrl;
                if(data.twitterUrl)
                    this.twitterUrl = data.twitterUrl;
                if(data.youtubeUrl)
                    this.youtubeUrl= data.youtubeUrl;

                if(this.facebookUrl != ""){
                    this.facebookStyle =false;
                }
                if(this.instagramUrl != ""){
                    this.instagramStyle =false;
                }
                if(this.linkedinUrl != ""){
                    this.linkedinStyle =false;
                }
                if(this.twitterUrl != ""){
                    this.twitterStyle =false;
                }
                if(this.youtubeUrl != ""){
                    this.youtubeStyle =false;
                }

                this.socialNetwork=(!this.facebookStyle) || (!this.instagramStyle) || (!this.linkedinStyle) || (!this.twitterStyle)  ||  (!this.youtubeStyle)


            }
        });
    }
}

