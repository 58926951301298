<div class="site-footer">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4">
                    <app-footer-contacts></app-footer-contacts>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="Information" [links]="[
                        {label: 'About Us',             url: 'https://storimart.com/', target:'_blank'},
                        {label: 'Features',             url: 'https://storimart.com/', target:'_blank'},
                        {label: 'Download',             url: 'https://storimart.com/download.html', target:'_blank'},
                        {label: 'Privacy Policy',       url: 'https://storimart.com/privacy-policy/', target:'_blank'},
                        {label: 'Contact Us',           url: 'https://storimart.com/', target:'_blank'},
                        {label: 'Terms of Use',         url: 'https://storimart.com/terms-of-use/', target:'_blank'}
                
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-2">
                    <app-footer-links header="My Account" [links]="[
                        {label: 'Order History',  url: '#/classic/account/orderhistory'},
                        {label: 'Favourites',      url: '#/classic/shop/wishlist'},
                        {label: 'Offers',         url: '#/classic/home#offers'}
                    ]"></app-footer-links>
                </div>
                <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div>
            </div>
        </div>

        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                Powered by <a href={{website}} target="_blank">Storimart</a> 
            </div>
            Ver {{curDate}}.{{ver}}
        </div>
    </div>
</div>
