
<mat-card class="paddington2">
    <button mat-icon-button class="top-right" (click)="closeMe()" tabindex="-1"><mat-icon>close</mat-icon></button>
    <!-- <mat-card-header> -->
            <!-- <mat-card-title>LOGIN</mat-card-title> -->
    <!-- </mat-card-header> -->
        <mat-card-content>
            <div class="center">
                <h4>LOGIN</h4>
            </div>
            <form [formGroup]="loginForm">
                <div class="mt-2">
                    <mat-form-field appearance="outline" class="full-width" tabindex="-1">
                        <mat-label>Email/Phone</mat-label>
                        <input matInput placeholder="Email/Phone"
                            (keydown.space)="$event.preventDefault();" formControlName="phoneNumber"
                            required>
                        <mat-icon matPrefix>account_circle</mat-icon>
                        <mat-error *ngIf="!!loginForm.controls.phoneNumber?.errors?.required">Phone
                            number
                            is required</mat-error>
                            <mat-error
                            *ngIf="loginForm.controls.phoneNumber?.errors?.pattern">
                                Invalid phone/Email</mat-error>
                        </mat-form-field>
                </div>
                <div>
                    <mat-form-field class="mt-2" appearance="outline" class="full-width">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" placeholder="Enter the Password" formControlName="otp" required [type]="hide? 'password':'text'">
                        <button mat-icon-button matSuffix (click)="hide = !hide" >
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button>
                        <mat-icon matPrefix>lock</mat-icon>
                        <mat-error *ngIf="!!loginForm.controls.otp?.errors?.required">Password is required
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="center">
                    <button mat-button (click)="forgotPassword()"  color="primary" >Forgot password?</button>
                </div>
            </form>
        </mat-card-content>

        <mat-card-actions>
            <button  mat-raised-button (click)="doLogin()"  color="primary" class="full-width">Login</button>
        </mat-card-actions>
        <hr>
        <div class="center">
           <span class="paddington">Create an Account?</span>  <button mat-button (click)="doRegister()" class="no-padding" color="primary">Sign Up</button>
        </div>

</mat-card>