<app-block-slideshow [withDepartments]="true"></app-block-slideshow>

<!--<app-block-features></app-block-features>-->

<app-block-products-carousel *ngIf="offerProducts1.products && offerProducts1.products.length!==0" id="offers" header="Offer Products" layout="grid-4"
                             [loading]="offerProducts1.loading"
                             [products]="offerProducts1.products"></app-block-products-carousel>

 <app-block-products-carousel *ngIf="favouriteProducts.products && favouriteProducts.products.length!==0" id="Favourites" header="Favourite Products" layout="grid-4"
 [loading]="favouriteProducts.loading"
                             [products]="wishlist.items$ | async"></app-block-products-carousel>


                           

<!--<app-block-banner></app-block-banner>-->

<!-- <app-block-products header="Bestsellers" layout="large-first" [products]="products"></app-block-products> -->

<app-block-categories header="Categories" layout="classic" [categories]="categories"></app-block-categories>

<!--<app-block-products-carousel header="New Arrivals" layout="horizontal" [rows]="2"
                             [loading]="newArrivals.loading"
                             [products]="newArrivals.products"
                             [groups]="newArrivals.groups"
                             (groupChange)="newArrivals.groupChange($event)"></Arrivals>

<app-block-posts header="Latest News" layout="list-sm" [posts]="posts"></app-block-posts>

<app-block-brands [brands]="brands"></app-block-brands>

<app-block-product-columns [columns]="columns"></app-block-product-columns>-->
