import { Stock } from "./loadoffers-response-model"

    export interface CatHead {
        status: string;
        catName: string;
        catId: string;
        catOwnerName: string;
        catOwnerId: string;
    }
   

    export interface Uom {
        description: string;
        isqttyfloat: boolean;
        value: string;
    }

    export interface ProductPrice {
        actual: number;
        offer?: number;
        currency: string;
        priceDescription?:string;
        taxIncluded?:boolean;
    }

    export interface SkuPrice {
        actual: number;
        offer?: number;
        currency: string;
    }

    export interface Attribute {
        name: string;
        skuDefining: boolean;
        value: string;
    }

    export interface Attributes {
        attribute?: Attribute[];
    }

    export interface Sku {
        status?: string;
        skuShortName?: string;
        skuId?: string;
        skuErpId?:string;
        skuShopId?: string;
        isFavorite: boolean;
        basePrice?:string;
        pricingUom?:string;
        puMultiple?:string;
        skuPrice?: ProductPrice;
        attributes?: Attributes;
        skuLongName?: string;
        skuIcon?:string;
        skuImage?:string,
        skuImageLarge?:string,
        stock?:Stock;
        uom?:Uom;
        additionalImages?:SkuAdditionalImages[];
    }
    
    export interface SkuAdditionalImages{
        imageUrl: string;
        skuId:string;
        skuImageId:string;
        status:string;
    }
    

    export interface AdditionalImages {
        productId: string;
        imageUrl: string;
        productImageId:string;
        status:string;
    }

    export interface Product {
        status?: string;
        productShortName: string;
        productLongName: string;
        productId: string;
        shopProductId?:string;
        productMfrPartnum: string;
        showProductIcon: boolean;
        productIcon: string;
        productImage: string;
        productImageLarge: string;
        productDescription: string;
        hasFavoriteSku: boolean;
        isFavoriteProduct: boolean;
        applicableTaxPercentage: number;  
        additionalImages?:AdditionalImages[];      
        productShippingCost: number;
        uom: Uom;
        productPrice: ProductPrice;
        minimumquantity: string;
        sku?: Sku[];      
        ingredient: any[];
        attributes?:any[];
        breadCrumb?:BreadCrumb[]
    }
    export interface BreadCrumb{
        id: number;
        name: string;
    }

    export interface Products {
        product: Product[];
    }

    export interface Category {
        status: string;
        categoryName: string;
        categoryId: string;
        categoryImage: string;
        categoryDescription?:string;
        productsExistDownCategoryTree: boolean;
        products: Products;
        category?: Category[];
    }

    export interface Categories {
        category: Category[];
    }

    export interface Catalogue {
        catHead: CatHead;
        categories: Categories;
    }

    export interface CatalogueResponseModel {
        catalogue: Catalogue[];
    }

    /* export interface  CatalogueResponseModel {
        catalogues: Catalogues;
    } */


