// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    name:'sit',
    production: false,
    serviceUrl: 'https://storimart.bytetale.com/storimartservices/',
    appName: 'storimartRSSA',
    source :"EUAPP",
    logo:"../../../../../assets/images/logos/storimart.png",
    website:'https://storimart.com/',
    twitterUrl : 'https://twitter.com/',
    linkedinUrl : 'https://www.linkedin.com/company/storimart-b2b',
    facebookUrl : 'https://www.facebook.com/storimart'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
