import { Component } from '@angular/core';
import { StoreService } from '../../../../shared/services/store.service';
import {AccountLoginService} from '../../../account/services/account-login.service';
import { UserDataModel } from 'src/app/shared/interfaces/user';
import { AvatarModule } from 'ngx-avatar';

@Component({
    selector: 'app-footer-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent {

    aboutUs:String="";
    warehouselogo: string;
    warehousename: string;
    retailername: string;
    adLine1: string;
    adLine2:string;
    addressId: string;
    city: string;
    country: string;
    email:string;
    phone: string;
    state: string;
    street: string;
    zip: string;
    openShop:boolean;


    

        
    
    constructor(public store: StoreService,private accountLoginService:AccountLoginService) { 
       
        
        
    }

    ngOnInit(){

        if(sessionStorage.getItem('openShop') ==="true")
        this.openShop = true;
        else
        this.openShop =false;
        
        let userDatamodel : UserDataModel ={};
        
        userDatamodel = this.accountLoginService.getLocalUser();
        this.warehouselogo = userDatamodel.warehouses[0].warehouseLogo;
        this.warehousename = userDatamodel.warehouses[0].warehouseName;
    
        this.adLine1=userDatamodel.warehouses[0].address.adLine1;
        this.adLine2=userDatamodel.warehouses[0].address.adLine2;
        this.city=userDatamodel.warehouses[0].address.city;
        this.state=userDatamodel.warehouses[0].address.state;
        this.country=userDatamodel.warehouses[0].address.country;
        this.street=userDatamodel.warehouses[0].address.street;
        this.zip=userDatamodel.warehouses[0].address.zip;
        this.phone=userDatamodel.warehouses[0].address.phone;
        this.email=userDatamodel.warehouses[0].address.email;
        
        this.phone="https://api.whatsapp.com/send?phone="+this.phone;
        this.accountLoginService.getFooterDetails().subscribe((data: any) => {
            //console.log("This is Footer" + data);
            this.aboutUs = data.aboutUs;
        });
    }


}
