<div class="site-header">
   <!-- <app-header-topbar></app-header-topbar> -->

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="row">
            <div class="site-header__logo" *ngIf="warehouselogo!='https://storimart.bytetale.com/images/mart/2/shop_logo.png'">
                <a routerLink="/classic/home">
                    
                        <img src={{warehouselogo}} alt=""  style="max-width:100px;height:auto;" text-align= "left"
                        onerror="this.src='/assets/images/logos/warehouse_logo.jpg'"> <br>
                       <!--  {{warehouselogo}} -->
      
                </a>
            </div>

            <div class="site-header__logo padding" *ngIf="warehouselogo === 'https://storimart.bytetale.com/images/mart/2/shop_logo.png'">
                <ngx-avatar name={{warehousename}} value="28%" initialsSize="3" bgColor="#000000" fgColor="#58D3F7" size="60"
                textSizeRatio="2.5" [round]="true"></ngx-avatar>
            </div>

            <div class="site-header__name p-4">
                <div >
                    <a routerLink="/classic/home">
                         <h4><b>{{ warehousename }}</b></h4>
                    </a>
                    <div *ngIf="!openShop" class="pl-4">Welcome {{ retailername }}</div>
                    <div *ngIf="openShop">Welcome Guest</div>
                 </div>     
            </div>
        </div>
        
        <div class="site-header__search">
            <app-header-search></app-header-search>
        </div>
        <!--<div class="site-header__phone">
            <div class="site-header__phone-title">Customer Service</div>
            <div class="site-header__phone-number">{{ store.primaryPhone }}</div>
        </div>-->
    </div>
    <div *ngIf="catalogPreview && !openShop">
    <mat-toolbar class="custom-toolbar"><span class="title-center">THIS IS A PREVIEW</span></mat-toolbar>
    </div>
    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'"></app-header-nav>
    </div>
</div>
