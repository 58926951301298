import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ProductModel } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { AddToCartRequestModel } from '../interfaces/api/service-request/addtocart-request-model';
import { AddToCartResponseModel } from '../interfaces/api/service-response/addtocart-response-model';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { UserDataModel } from '../interfaces/user';
import { RemoveFromCartRequestModel } from '../interfaces/api/service-request/removefromcart-request-model';
import { RemoveFromCartResponseModel } from '../interfaces/api/service-response/removefromcart-response-model';
import { ProductService } from 'src/app/modules/shop/services/product.service';
import { ViewCartResponseModel } from '../interfaces/api/service-response/viewcart-response-model';
import { EditQuantityRequestModel } from '../interfaces/api/service-request/editquantity-request-model';
import { EditQuantityResponseModel } from '../interfaces/api/service-response/editquantity-response-model';
import { CheckoutOrderResponseModel } from '../interfaces/api/service-response/checkoutorder-response-model';
import { CheckoutOrderRequestModel } from '../interfaces/api/service-request/checkoutorder-request-model';
import { CheckoutViewModel } from '../../shared/interfaces/checkoutview';
import { AddNewAddressRequestModel } from '../interfaces/api/service-request/addnewaddress-request-model';
import { AddressViewModel } from '../interfaces/addnewaddressview';
import { AddNewAddressResponseModel } from '../interfaces/api/service-response/addnewaddress-response-model';
import { ChooseAddressResponseModel } from '../interfaces/api/service-response/chooseaddress-response-model';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { error } from 'console';


interface CartTotal {
    title: string;
    price: number;
    currency: string;
    type: 'shipping' | 'tax';
}

interface CartData {
    items: CartItem[];
    currency: string;
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {

    public noOfItemsInCart =0;
    private data: CartData;

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject([]);
    private currencySubject$: BehaviorSubject<string> = new BehaviorSubject('');
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(0);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
    public  inActiveSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject([]);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(0);
    private onAddingSubject$: Subject<ProductModel> = new Subject();
   
    currency: string;
    inActiveProduct: boolean = false;
    noOfInactivePdts: number =0;

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly currency$: Observable<string> = this.currencySubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly inActive$:Observable<boolean> = this.inActiveSubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();
    readonly onAdding$: Observable<ProductModel> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        private accountloginService: AccountLoginService,
        private productService: ProductService,
        private http: HttpClient,
        private toastr: ToastrService
    ) {
        this.initializeCart();

        if (isPlatformBrowser(this.platformId)) {
            this.load();
            this.calc();
        }
    }

    saveAddress(addressViewModel: AddressViewModel): Observable<AddressViewModel> {

        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let addnewaddressrequest: AddNewAddressRequestModel = {
            details: {
                firstName: "",
                lastName: "",
                email: addressViewModel.email
            },
            header: {
                phone: addressViewModel.phone,
                countryCode: addressViewModel.countryCode,
                action: "EDIT",
                success: false,
                message: null
            },
            address: [],
            shops: []
        };

        addnewaddressrequest.address.push({
            adNameFirst: addressViewModel.adNameFirst,
        	adNameLast: addressViewModel.adNameLast,
            adLine1: addressViewModel.adLine1,
            adLine2: addressViewModel.adLine2,
            street: addressViewModel.street,
            zip: addressViewModel.zip,
            city: addressViewModel.city,
            state: addressViewModel.state,
            country: addressViewModel.country,
            landmark: addressViewModel.landmark,
            addressId: addressViewModel.addressId,
            addressType: addressViewModel.addressType
        })
        
        return new Observable<AddressViewModel>(observer => {
            this.http.post<AddNewAddressResponseModel>(environment.serviceUrl + 'saveaddress', addnewaddressrequest).subscribe((response: AddNewAddressResponseModel) => {
                
                if (response.header.success === true) {
                    observer.next(addressViewModel)
                }
                else {
                    observer.error();
                }
            },
                (error) => {
                    observer.error();
                });
        });
    }

    chooseAddress(): Observable<AddressViewModel[]> {

        let user: UserDataModel = {};
        user = this.accountloginService.getLocalUser();

        return new Observable<AddressViewModel[]>(observer => {
            this.http.get<ChooseAddressResponseModel>(environment.serviceUrl + 'chooseaddress')
                .subscribe((response: ChooseAddressResponseModel) => {
                    if (response.header.success === true) {
                        let addressViewModel: AddressViewModel[] = [];

                        response.address.forEach(address => {
                            addressViewModel.push({
                                adNameFirst: address.adNameFirst,
                                adNameLast: address.adNameLast,
                                countryCode: address.country,
                                phone: address.adPhone,
                                email: address.adEmail,
                                adLine1: address.adLine1,
                                adLine2: address.adLine2,
                                street: address.street,
                                zip: address.zip,
                                city: address.city,
                                state: address.state,
                                country: address.country,
                                status: address.status,
                                landmark: address.landmark,
                                addressId: address.addressId,
                                addressType: address.addressType
                            })
                        });
                        observer.next(addressViewModel);
                    }
                    else {
                        observer.error();
                    }
                },
                    (error) => {
                        observer.error();
                    });
        });
    }

    getProductQuantityInCart(product: ProductModel){
        
        if(product.activeSku)
        {
            let item = this.items.find(eachItem => ((eachItem.product.activeSku) && (eachItem.product.activeSku.skuId == product.activeSku.skuId)));
            if(item)
                return item.quantity;
            else
                return 0;
            }
        else
        {
            let item = this.items.find(eachItem => eachItem.product.id === product.id);
            if(item)
                return item.quantity;
            
            else
                return 0;
        }

    }
    
    add(product: ProductModel, quantity: number, options: { name: string; value: string }[] = []): Observable<CartItem> {
        return new Observable<CartItem>(observer => {
            this.addItemToServer(product, quantity).subscribe((response: ViewCartResponseModel) => {
                if (response.order.length > 0  && response.order[0].header.status.value != "FAILED") {
                    this.onAddingSubject$.next(product);
                    let item = this.items.find(eachItem => {

                        if(product.activeSku)
                        {
                            if (eachItem.product.activeSku.skuId !== product.activeSku.skuId) {
                                return false;
                            }
                        }
                        else
                        {
                            if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                                return false;
                            }
                        }

                        return true;
                    });

                    if (item) {
                        item.quantity += quantity;

                    } else {
                        item = { product, quantity, options };

                        this.data.items.push(item);
                    }

                    this.save();
                    this.calc();

                    observer.next(item);
                }
                else {
                    this.toastr.error("Oops some issue while adding to cart. Please try again later.")
                    observer.error();
                   
                }
            },
            (error) => {
                observer.error();
            });
        });
    }

    addItemToServer(product: ProductModel, quantity: number): Observable<ViewCartResponseModel> {
        
        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();
        let addtocartrequest: AddToCartRequestModel = {
            retailerShopId:userDataModel.shops[0].shopId,
            qty: quantity.toString(),
            skuId:product.activeSku ?  product.activeSku.skuId: product.id.toString(),   
            currency: product.currency,
            skuPrice: this.productService.getSellingPrice(product).toString(),
            uom: product.uom.value
        };

        return this.http.post<ViewCartResponseModel>(environment.serviceUrl + 'addtocart/new', addtocartrequest, {});
    }


    update(updates: { item: CartItem, quantity: number }[]): Observable<void> {
         return new Observable<void>(observer => {
            this.updateQuantityInServer(updates).subscribe((response: ViewCartResponseModel) => {
                if (response.order[0].header.status.value != "FAILED") {
                    updates.forEach(update => {
                        const item = this.items.find(eachItem => eachItem === update.item);

                        if (item) {
                            item.quantity = update.quantity;
                        }
                    });

                    this.save();
                    this.calc();

                    observer.complete();
                }
                else {
                    this.toastr.error("Failed to update the quantity")
                    observer.error();
                }
            },
            (error) => {
                observer.error();
            });
        });
    }

    updateQuantityInServer(updates: { item: CartItem, quantity: number }[]) {
        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let editquantityrequest: EditQuantityRequestModel =
        {
            
            items: []
        }

        updates.forEach(update => {
            const item = this.items.find(eachItem => eachItem === update.item);

            if (item) {
                editquantityrequest.items.push({
                    skuId: item.product.activeSku.skuId,
                    qty: update.quantity
                })
            }
        });

        return this.http.post<ViewCartResponseModel>(environment.serviceUrl + 'editquantity/new', editquantityrequest, {});
    }

    updateLocalFromViewcart(item,quantity){
        return new Observable<void>(observer => {
            this.updateServerFromViewCart(item,quantity).subscribe((response: ViewCartResponseModel) => {
                if (response.order[0].header.status.value != "FAILED") {
                    
                        const item1 = this.items.find(eachItem => eachItem.product.name === item.productShortName);
                        const item2 = this.items.find(eachItem => eachItem.product.activeSku.skuShortName === item.productShortName);

                        if (item1) {

                            item1.quantity = quantity;
                        }
                        if(item2){
                        item2.quantity = quantity;
                        }
                    

                    this.save();
                    this.calc();

                    observer.complete();
                }
                else {
                    this.toastr.error("Failed to update the quantity")
                    observer.error();
                }
            },
            (error) => {
                observer.error();
            });
        });


    }

    updateServerFromViewCart(item,quantity){
        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let editquantityrequest: EditQuantityRequestModel =
        {
            
            items: []
        }

        
                editquantityrequest.items.push({
                    skuId: item.skuId,
                    qty:quantity
                })
           

        return this.http.post<ViewCartResponseModel>(environment.serviceUrl + 'editquantity/new', editquantityrequest, {});
    }

    removeFromViewCart(item: any): Observable<void> {
       
        if(item.isAvailable === false)
            this.noOfInactivePdts--;
        if(this.noOfInactivePdts === 0)
            this.inActiveSubject$.next(false);
        return new Observable<void>(observer => {
            this.removeFromViewCartServer(item).subscribe((response: ViewCartResponseModel) => {

                if (response.order[0].header.status.value != "FAILED") {
                       
                    if(!item.skuId)    
                    this.data.items = this.data.items.filter(eachItem => eachItem.product.name !== item.productShortName);

                    if(item.skuId){
                    this.data.items = this.data.items.filter(eachItem => eachItem.product.activeSku.skuShortName !== item.productShortName);
                    }

                    
                    this.save();
                    this.calc();
                    
                    observer.complete();
                }
                else {
                    observer.error();
                    this.toastr.error(response.order[0].header.status.message)
                }
            },
            (error) => {
                observer.error();
            });
        });

    }


    removeFromViewCartServer(item){
        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let removefromcartrequest: RemoveFromCartRequestModel = {
            skuId: item.skuId
        };

        return this.http.post<ViewCartResponseModel>(environment.serviceUrl + 'removefromcart/new', removefromcartrequest, {});
    }

    remove(item: CartItem): Observable<void> {
        
        return new Observable<void>(observer => {
            this.removeItemFromServer(item).subscribe((response: ViewCartResponseModel) => {

                if (response.order[0].header.status.value != "FAILED") {
                    this.data.items = this.data.items.filter(eachItem => eachItem !== item);
                    
                    this.save();
                    this.calc();
                    observer.complete();
                }
                else {
                    observer.error();
                }
            },
            (error) => {
                observer.error();
            });
        });

    }
    removeItemFromServer(item: CartItem) {


        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();

        let removefromcartrequest: RemoveFromCartRequestModel = {
            skuId: item.product.activeSku? item.product.activeSku.skuId: item.product.id.toString()
        };

        return this.http.post<ViewCartResponseModel>(environment.serviceUrl + 'removefromcart/new', removefromcartrequest, {});
    }

    viewServerCartItems(): Observable<CheckoutViewModel> {

        let user: UserDataModel = {};
        user = this.accountloginService.getLocalUser();

        return new Observable<CheckoutViewModel>(observer => {
            this.http.get<ViewCartResponseModel>(environment.serviceUrl + 'viewcart')
                .subscribe((response: ViewCartResponseModel) => {
                    if (response.order.length > 0  && response.order[0].header.action != "ERRORED") {
                        this.noOfItemsInCart = response.order[0].items.item.length;
                        /* this.quantitySubject$.next(response.order[0].items.item.length); */
                        let checkoutViewModel: CheckoutViewModel = {
                           
                            order: []
                        };
                        response.order.forEach(order => {
                    
                            checkoutViewModel.order.push(
                                {
                                    erpEnabled:order.erpEnabled,
                                    items: order.items,
                                    header: order.header,
                                    shipMethod: order.shipMethod, 
                                    orderUrlForErp:order.orderUrlForErp,
                                    creditStatusEnabled:order.creditStatusEnabled,
                                    creditStatusUrlForErp:order.creditStatusUrlForErp,
                                    creditCheck:order.creditCheck
                                });
                        });

                        observer.next(checkoutViewModel);
                    }
                    else {
                        observer.error();
                    }
                },
                    (error) => {
                        observer.error();
                    });
        });
    }

    checkoutOrderInServer(preferedDeliveryDateTime: Date, orderNotes: string, selectedAddressId: string,attachment): Observable<CheckoutOrderResponseModel> {

        let userDataModel: UserDataModel = {};
        userDataModel = this.accountloginService.getLocalUser();
        
        return new Observable(observer => {
            this.viewServerCartItems().subscribe((response: CheckoutViewModel) => {
                if (response.order[0].header.action != "ERRORED") { //Viewcart service successful
                    this.noOfItemsInCart = 0;
                    this.inActiveSubject$.next(false);
                    let checkoutorderrequest: CheckoutOrderRequestModel = {
                        order: []
                    };

                    checkoutorderrequest.order.push({
                       
                        header: {
                            
                            action: "ADD",
                            warehouse: {
                                warehouseId: userDataModel.warehouses[0].wareHouseStoreId,
                            },
                            retailerShop: {
                                retailerShopId: response.order[0].header.retailerShop.retailerShopId,
                            },
                            deliveryTime: (moment(preferedDeliveryDateTime).format("YYYY/MM/DDTHH:mm:ss.SSS"))+"+0000",
                            status: {
                                message: "",
                                value: "NEW"
                            },
                            attachments: [
                               attachment
                            ],
                            orderId: response.order[0].header.orderId,
                            orderType: "ORDER",
                            cancellable: false,
                            shipToAddressId: selectedAddressId,
                            shipMethod: response.order[0].shipMethod,
                            userNotes: orderNotes

                        }
                    });

                    this.http.post<CheckoutOrderResponseModel>(environment.serviceUrl + 'checkout', checkoutorderrequest).subscribe(checkoutResponse => {
                        observer.next(checkoutResponse);
                    },
                        (error) => {
                            observer.error();
                        });
                }
            })
        });
    }


    syncServerCartToLocal() {
        
        this.initializeCart();        

        this.viewServerCartItems().subscribe(response => {
            if(response.order[0].items.item)
            {
                 
            response.order[0].items.item.map(item => {
                if(item.isAvailable === false)
                {
                    this.noOfInactivePdts++;
                this.inActiveSubject$.next(true);
                }
                this.productService.getProductDetail(item.productId).subscribe(product =>{
                   
                    if(!product)
                    {
                       
                    this.inActiveSubject$.next(true);
                    this.toastr.error("Some of the products in your order are inactive")
                    }
                    product.activeSku =  product.sku.find(x => x.skuId== item.skuId);
                    if(!product.activeSku)
                    {
                       
                    this.inActiveSubject$.next(true);
                    return;
                    }
                    let cartItem: CartItem =
                    {
                        product:product,
                        options: [],
                        quantity: item.quantity
                    }
                    
                    this.data.items.push(cartItem);
               
                            
                    this.save();
                    this.calc();
                   
                });
                 
            })
            
            this.quantitySubject$.next(response.order[0].items.item.length);  
        }
        },(err => console.log(err)));
        
    }

    transformItemToCartItem(item) {

        this.productService.getProductDetail(item.productid).subscribe(product =>{

            product.activeSku =  product.sku.find(x => x.skuId== item.skuId);
            
            let cartItem: CartItem =
            {
                product:product,
                options: [],
                quantity: item.quantity
            }
            
            return cartItem;
        });
    }

    private initializeCart(): void {
        this.inActiveSubject$.next(false);
        this.data = this.initialCart();
        this.itemsSubject$.next(this.data.items)
        this.currencySubject$.next(this.data.currency);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);

    }
    private initialCart(): CartData {
        this.inActiveSubject$.next(false);
        return {
            items: [],
            currency: '',
            quantity: 0,
            subtotal: 0,
            totals: [],
            total: 0
        };
    }

    calcAfterSync(){
        
    }

     calc(): void {
        
        let quantity = 0;
        let subtotal = 0;
        let currency : string;

        const totals: CartTotal[] = [];

        this.data.items.forEach(item => {

            quantity = quantity+1;
        /* if(item.product.price.taxIncluded === true)
            {
            subtotal += this.productService.getSellingPrice(item.product) * item.quantity;
            subtotal = subtotal - ((this.productService.getSellingPrice(item.product) * item.quantity) * (item.product.applicableTaxPercentage/100));
            }
        else */
            subtotal += this.productService.getSellingPrice(item.product) * item.quantity;
            currency = item.product.currency;
        });

        let shippingInfo = JSON.parse(sessionStorage.getItem('shippingInfo'));  

        if(shippingInfo!= null)
        {
            totals.push({
                title: 'Shipping',
                price: shippingInfo? parseInt(shippingInfo.shippingCost) : 0,
                currency: currency,
                type: 'shipping'
            });
        }  

        let totalTax=0;
        this.data.items.forEach(item => {
            
            totalTax = totalTax + ((item.product.applicableTaxPercentage>0) ? ((this.productService.getSellingPrice(item.product) * item.quantity) * (item.product.applicableTaxPercentage/100)) : 0);  
        });

        if(totalTax > 0)
        {
            totals.push({
                title: 'Tax',
                price: parseFloat(totalTax.toFixed(2)),  
                currency: currency,
                type: 'tax'
            });
        }


        const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);
    
        this.data.currency = currency;
        this.data.quantity = quantity;
        this.data.subtotal = parseFloat(subtotal.toFixed(2));
        this.data.totals = totals;
        this.data.total = parseFloat(total.toFixed(2));

        this.itemsSubject$.next(this.data.items);
        this.currencySubject$.next(this.data.currency);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);

    }

    private save(): void {
        sessionStorage.setItem('cartItems', JSON.stringify(this.data.items));
    }

    private load(): void {
        const items = sessionStorage.getItem('cartItems');

        if (items) {
            this.data.items = JSON.parse(items);
        }
    }

    public clearCart(): void {
    
        sessionStorage.removeItem('cartItems'); 
        this.data.items = [];
        this.save();
        this.calc();
    }

    public getShippingCost(){

        this.viewServerCartItems().subscribe(response => {

            let shippingInfo; 
            if(response.order.length>0 && response.order[0].header.action != "ERRORED"){
                shippingInfo = {
                    shippingCost : response.order[0].header.shippingCost
                }        
            }
            else if(response.order.length == 0 && response.order[0].header.action != "ERRORED")
            {
                shippingInfo = {
                    shippingCost : 0
                }          
            }
            sessionStorage.setItem('shippingInfo', JSON.stringify(shippingInfo));
            this.calc();
        },

        (error) => {
            console.log(error);
        });
    }


}
 