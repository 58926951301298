import { Injectable, NgModule } from '@angular/core';
import { UserDataModel } from '../../../shared/interfaces/user';
import { Country, CountryResponseModel,TimeZone,TimeZoneResponseModel } from '../../../shared/interfaces/api/service-response/country-response-model';
import { ValidateotpResponseModel, Address, Warehouse, PaymentMode, Shop, Status, ValidateOtpModel } from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { ResetOtpResponseModel } from 'src/app/shared/interfaces/api/service-response/resetotp-response-model';
import { OrderHistoryResponseModel } from '../../../shared/interfaces/api/service-response/orderhistory-response-model';
import { OrderHistoryViewModel } from 'src/app/shared/interfaces/orderhistory';
import { HttpClient, HttpHeaders,HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import * as moment from 'moment';
import { OrderNoteRequestModel, OrderStatusResponseModel } from 'src/app/shared/interfaces/api/service-response/orderstatus-response-model';
import { LogoutResponseModel } from 'src/app/shared/interfaces/api/service-response/logout-response-model';
import { OrderCancelResponseModel } from 'src/app/shared/interfaces/api/service-response/ordercancel-response-model';
import {ToastrService} from 'ngx-toastr'


@NgModule({
	declarations: [
		// components
		// pages
	],
	imports: [
		// modules (angular)
	]
})


@Injectable()
export class AccountLoginService {
	countries: Country[] = [];
	orders: OrderHistoryViewModel[] = [];
	orderhistoryresponse: OrderHistoryResponseModel;
	validateotpresponse: ValidateotpResponseModel;
	orderNoteRequestModel={} as OrderNoteRequestModel;
	resetotpresponse: ResetOtpResponseModel;
	userdatamodel: UserDataModel;
	errorMessage:string="";
	validateOtpModel :ValidateOtpModel ={
		phone:undefined,
		otp:undefined,
		appName:undefined,
		password:undefined,
		source:undefined,
		webPassword:undefined,
		email:undefined,
		isWeb:undefined,
	};
	httpOptions = {
		headers: new HttpHeaders({ 'Content-Type': 'application/json' },
		),
		observe: 'response' as 'response'
	  };

	constructor(private http: HttpClient, private toastr:ToastrService) {
		this.orders = [];
	}

	
	getCountryCodes() {
		return this.http.get<CountryResponseModel>(environment.serviceUrl + 'cntryphne');
	}

	getTimeZoneInfo(){
		return this.http.get<TimeZoneResponseModel>(environment.serviceUrl + 'timezone');
	}

	checkOpenShop(): Observable<Boolean>
	{
		return new Observable<Boolean>(observer => 
			{
			this.http.post<any>(environment.serviceUrl +'login/openshop',{},this.httpOptions)
		.subscribe((response: HttpResponse<any>) => {
			//console.log(response);
			if (response.body.status.success) {
				sessionStorage.setItem("AuthorizationToken",response.headers.get("Authorization"))
				sessionStorage.setItem("openShopAuthorizationToken",response.headers.get("Authorization"))
				let userDataModel: UserDataModel = {};
						userDataModel = this.tranformValidateotpResponseModelToUserDataModel(response.body);
						this.setLocalUser(userDataModel);
						return observer.next(true); //Login successful
			} 
			else
			return observer.next(false); //Login unsuccessful
		});
		
	});
}

previewCatalog(): Observable<Boolean>
	{
		return new Observable<Boolean>(observer => 
			{
			this.http.post<any>(environment.serviceUrl +'userdetails',{},this.httpOptions)
		.subscribe((response: HttpResponse<any>) => {
			//console.log(response);
			if (response.body.status.success) {
				sessionStorage.setItem("AuthorizationToken",response.headers.get("Authorization"))
				
				let userDataModel: UserDataModel = {};
						userDataModel = this.tranformValidateotpResponseModelToUserDataModel(response.body);
						this.setLocalUser(userDataModel);
						return observer.next(true); //Login successful
			} 
			else
			return observer.next(false); //Login unsuccessful
		});
		
	});
}

	validateOtp(loginRequest): Observable<Boolean> {
		var randomPassword = Math.random().toString(36).slice(-8);
		loginRequest.password =randomPassword;
		
		return new Observable<Boolean>(observer => {
		this.http.post<any>(environment.serviceUrl + 'user/login/password' ,loginRequest,this.httpOptions)
				.subscribe((response: HttpResponse<any>) => {
					//console.log(response);
					if (response.body.status.success === true) {
						this.removeLocalUser();
						sessionStorage.setItem("AuthorizationToken",response.headers.get("Authorization"))
						sessionStorage.setItem('openShop','false');
						let userDataModel: UserDataModel = {};
						userDataModel = this.tranformValidateotpResponseModelToUserDataModel(response.body);
						//set password in user object
						userDataModel.status.password = randomPassword;
						this.setLocalUser(userDataModel);
						observer.next(true); //Login successful
					}
					
					else {
						this.toastr.error(response.body.status.message,"ERROR");
						observer.next(false); //Login Failure
						
					}
				},
					(error) => {
						this.errorMessage = error.error.status.message;
						//console.log(this.errorMessage)
						
						this.toastr.error(this.errorMessage,"LOGIN FAILED");
						observer.error()
					});
				});
	}


	tranformValidateotpResponseModelToUserDataModel(response: ValidateotpResponseModel): UserDataModel {

		let userDataModel: UserDataModel = {};
		{
			let address: Address = response.address;
			if (address) {
				userDataModel.userInfo = {

					firstName: address.firstName,
					lastName: address.lastName,
					adLine1: address.adLine1,
					adLine2: address.adLine2,
					street: address.street,
					city: address.city,
					state: address.state,
					country: address.country,
					zip: address.zip,
					userEmail: address.userEmail,
					userPhone: address.userPhone,
					addressId: address.addressId
				}
			}

			let status: Status = response.status;
			if (status) {
				userDataModel.status = status;
			}

			let warehouses: Warehouse[] = response.status.warehouses;
			if (warehouses) {
				userDataModel.warehouses = warehouses;
			}

			let paymentModes: PaymentMode[] = response.paymentModes;
			if (paymentModes) {
				userDataModel.paymentModes = paymentModes;
			}

			let shops: Shop[] = response.shops;
			if (shops) {
				userDataModel.shops = shops;
			}
			//console.log(userDataModel);
			return userDataModel;
		}
	}

	resetOtp(loginform) {
		return this.http.post<ResetOtpResponseModel>(environment.serviceUrl + 'resetotp?phone=' + loginform.phone +'&email='+loginform.email ,'');
	}

	verifyOtp(otpValidate): Observable<Boolean>{

		//console.log("inside verify Otp");
		return new Observable<Boolean>(observer => {
			this.http.post<any>(environment.serviceUrl + 'setpassword',otpValidate,this.httpOptions)
			.subscribe((response: HttpResponse<any>) => {
				if (response.body.status === '200') {
						
					sessionStorage.setItem("AuthorizationToken",response.headers.get("Authorization"))
					sessionStorage.setItem('openShop','false');
					observer.next(true); //Login successful
					}
					else {
						this.toastr.error(response.body.message,"ERROR");
						observer.next(false); //Login Failure
						
					}
				},
					(error) => {
						this.errorMessage = error.error.message;
						console.log(this.errorMessage)
						
						this.toastr.error(this.errorMessage,"VERIFICATION FAILED");
						observer.error()
					});
		});
	}


	registerBuyer(registerBuyerForm){
      return this.http.post<any>(environment.serviceUrl +'openshop/retailer/add', registerBuyerForm)
	}

	public setLocalUser(userdatamodel: UserDataModel) {
		sessionStorage.setItem('userData', JSON.stringify(userdatamodel));
		//console.log(localStorage.getItem('userData'))
		
	}

	public getUserName(): string {
		let loggedinUSer: UserDataModel = this.getLocalUser();
		let userName = loggedinUSer.userInfo.firstName + " " + loggedinUSer.userInfo.lastName;
		return userName;
	}

	public getLocalUser(): UserDataModel {
		return JSON.parse(sessionStorage.getItem('userData'));
	}

	public removeLocalUser() { 
		sessionStorage.removeItem('userData');
		sessionStorage.removeItem('cartItems');
		sessionStorage.removeItem('wishlistItems');
		sessionStorage.removeItem('shippingInfo');
		sessionStorage.removeItem('"AuthorizationToken');
		sessionStorage.removeItem('openShop');
		sessionStorage.removeItem('category');
		
		
	}

	public hasLocalUser(): boolean {
		return !!this.getLocalUser()
	}


	public logout() {
		
		let user: UserDataModel = {};
		user = this.getLocalUser();
        return this.http.post<LogoutResponseModel>(environment.serviceUrl + 'logout' ,'')
	}

	public isSignedIn(): boolean {
		return this.hasLocalUser();
	}

	updateToken(token: string ) {
		if (token && token.length) {
			sessionStorage.setItem('AuthorizationToken', token)
		}}

	getOrderDetail(orderId){
		this.orders= [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyids?orderId='+orderId)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {
						
						this.orders.push({
							totalCount:response.totalCount,
                            status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName:order.header.orderDisplayName,
							notes: order.notes,
							user:order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount : parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount:parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance : ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});
	}

	 getOrderHistory(index,offset) {

		let user: UserDataModel = {};
		user = this.getLocalUser();
		
       
		this.orders= [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?offset='+offset+'&limit='+index+'&allOrders=true&retailerShopId='+user.shops[0].shopId)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {
						this.orders.push({
							totalCount:response.totalCount,
                            status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName:order.header.orderDisplayName,
							notes: order.notes,
							user:order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount : parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount:parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance : ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});
	}

	searchOrderHistoryByDate(startDate,endDate,status) {
		let user: UserDataModel = {};
		user = this.getLocalUser();
		this.orders= [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?allOrders=true&retailerShopId='+user.shops[0].shopId+'&startDate='+startDate+'&endDate='+endDate+'&status='+status)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {
						this.orders.push({
							totalCount:response.totalCount,
                            status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName:order.header.orderDisplayName,
							notes: order.notes,
							user:order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount : parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount:parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance : ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});
			
		}

		searchOrderHistoryByStatus(status)
		{
		let user: UserDataModel = {};
		user = this.getLocalUser();
		this.orders= [];
		return new Observable<OrderHistoryViewModel[]>(observer => {
			this.http.get<OrderHistoryResponseModel>(environment.serviceUrl + 'ordersbyuser?allOrders=true&retailerShopId='+user.shops[0].shopId+'&status='+status)
				.subscribe((response: OrderHistoryResponseModel) => {
					response.order.forEach(order => {
						this.orders.push({
							totalCount:response.totalCount,
                            status: order.header.status.value,
							orderId: order.header.orderId,
							orderDisplayName:order.header.orderDisplayName,
							notes: order.notes,
							user:order.header.user,
							orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
							deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
							totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
							netTaxAmount : parseFloat(order.header.netTaxAmount).toFixed(2),
							grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
							discountAmount:parseFloat(order.header.discountAmount).toFixed(2),
							currency: order.header.totalAmount.currency,
							paidAmount: order.paymentDetails.paidAmount,
							item: order.items.item,
							shippingCost: order.header.shippingCost,
							shipToAddress: order.header.shipToAddress,
							balance : ''
						})
					});
					observer.next(this.orders);
				},
					(error) => {
						observer.error()
					});
		});

		}

		searchOrderHistoryById(orderId){
			console.log(orderId)
			this.orders= [];
			return new Observable<OrderHistoryViewModel[]>(observer => {
				this.http.get<OrderHistoryResponseModel>(environment.serviceUrl +'ordersbyids?orderId='+ orderId)
					.subscribe((response: OrderHistoryResponseModel) => {
						response.order.forEach(order => {
							
							this.orders.push({
								totalCount:response.totalCount,
								status: order.header.status.value,
								orderId: order.header.orderId,
								orderDisplayName:order.header.orderDisplayName,
								notes: order.notes,
								user:order.header.user,
								orderedDate: moment(order.header.orderedDate, 'YYYY/MM/DD').format('DD MMM YYYY'),
								deliveryTime: moment(order.header.deliveryTime, 'YYYY/MM/DD').format('DD MMM YYYY'),
								totalAmount: parseFloat(order.header.totalAmount.value).toFixed(2),
								netTaxAmount : parseFloat(order.header.netTaxAmount).toFixed(2),
								grandTotal: parseFloat(order.header.grandTotal).toFixed(2),
								discountAmount:parseFloat(order.header.discountAmount).toFixed(2),
								currency: order.header.totalAmount.currency,
								paidAmount: order.paymentDetails.paidAmount,
								item: order.items.item,
								shippingCost: order.header.shippingCost,
								shipToAddress: order.header.shipToAddress,
								balance : ''
							})
						});
						observer.next(this.orders);
					},
						(error) => {
							observer.error()
						});
			});
		}

	addOrdernote(newOrdernote: string, orderId) 
	{
		let user: UserDataModel = {};
		user = this.getLocalUser();
		this.orderNoteRequestModel.orderId = orderId;
		this.orderNoteRequestModel.orderNote = newOrdernote;
		this.orderNoteRequestModel.replytoNoteId = "-1";
		return this.http.post<string>(environment.serviceUrl + 'addordernote' ,this.orderNoteRequestModel)
				
	}

	reOrder(orderId:number){
		let user: UserDataModel = {};
		user = this.getLocalUser();
		return this.http.post<any>(environment.serviceUrl + 'reorder/' + orderId +'?retailerShopId='+user.shops[0].shopId,{})
	}

	
	cancelOrder(orderId)
	{
		let user: UserDataModel = {};
		user = this.getLocalUser();
		return this.http.post<OrderCancelResponseModel>(environment.serviceUrl + 'order/cancel', {
			
			orderId: orderId,
			
		  });
				
	}

	getBanners(){
		let user: UserDataModel = {};
		user = this.getLocalUser();
		let tenantId = user.warehouses[0].tenantId;
		let shopId = user.warehouses[0].wareHouseStoreId;
		let params = new HttpParams();
        params = params.append('tenantId',tenantId );
        params = params.append('shopId', shopId);
		return this.http.get(environment.serviceUrl +"bannerimages",{params: params})
	}

	getFooterDetails(){
		let user: UserDataModel = {};
		user = this.getLocalUser();
		let shopId:string = (user.warehouses[0].wareHouseStoreId).toString();
		let params = new HttpParams();
        params = params.set('shopId', shopId);
		return this.http.get(environment.serviceUrl +"sellerdetails",{params: params})
	}

	addOrderStatus(orderId){
		let user: UserDataModel = {};
		user = this.getLocalUser();

		return new Observable<OrderStatusResponseModel>(observer => {
			this.http.get<OrderStatusResponseModel>(environment.serviceUrl + 'retrordrstat?orderId=' + orderId)
				.subscribe((response: OrderStatusResponseModel) => {
					if(response.success == "SUCCESS")
					      observer.next(response);
				},
					(error) => {
						observer.error()
					});
		});
	
	}

}
