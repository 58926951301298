<div class="search">
    <div class="search__form" >
        <input class="search__input" name="search" #search placeholder="Search products" [formControl]="typeahead"  matInput  
        [matAutocomplete]="auto" aria-label="Site search"  (keyup.enter)="goToSearchResults(search.value)" type="text">
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)='goToSearchResults(search.value)'>
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        <button class="search__button" (click)="goToSearchResults(search.value)">
            <app-icon name="search-20" size="20"></app-icon>
        </button>
        
        <div class="search__border"></div>
    </div>
   
    
</div>