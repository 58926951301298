import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ValidateotpResponseModel,LoginRequestModel } from 'src/app/shared/interfaces/api/service-response/validateotp-response-model';
import { CartService } from 'src/app/shared/services/cart.service';
import { WishlistService } from 'src/app/shared/services/wishlist.service';
import {MatDialogRef} from '@angular/material/dialog';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.sass']
})
export class LoginDialogComponent implements OnInit {
  loginForm: FormGroup;
  hide =true;
  isForgotPassword=false;
  loginRequest : LoginRequestModel ={
    phone:undefined,
    appName:undefined,
    password:undefined,
    source:undefined,
    webPassword:undefined,
    email:undefined,
    isWeb:undefined,
  };
  validateotpresponse : ValidateotpResponseModel;
  constructor(public formBuilder: FormBuilder,public accountloginService: AccountLoginService,
    public router: Router,
    public activateRoute: ActivatedRoute, 
    private toastr: ToastrService,
    public wishlist: WishlistService,
    public cart: CartService,
    public dialogRef:MatDialogRef<LoginDialogComponent>) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      
      'phoneNumber': ['', Validators.compose([Validators.required,Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)])],
      'otp': ['', Validators.compose([Validators.required])]
    })
  }


  doLogin() {
    this.dialogRef.close();
    if (this.loginForm.valid)
    {

      let email = this.loginForm.get('phoneNumber').value;
      if(email.indexOf("@") > 0)
      {
        this.loginRequest.email = email;
        this.loginRequest.phone ="";
      }
      else{
        this.loginRequest.email="";
        this.loginRequest.phone=this.loginForm.get('phoneNumber').value;
      }
      
		
		
		this.loginRequest.appName=environment.appName;
		
		this.loginRequest.webPassword =this.loginForm.get('otp').value;
    this.loginRequest.source =environment.source;
    this.loginRequest.isWeb = "true";
    
    this.accountloginService.validateOtp(this.loginRequest).subscribe(response=>{
      if (response)
      { 
       
        this.cart.syncServerCartToLocal();
        this.wishlist.syncWishlist();

        this.router.navigate(['/classic/home']); 
        window.location.reload();
        this.toastr.success('', 'Logged In Successfully');
      } 
      else 
      { 
        this.toastr.error('', 'Oops! Please check the credentials entered');
      }
    },
    (error) => {
      //this.toastr.error('', "Sorry, Please try again later");
    });
  }
}
forgotPassword(){
  this.dialogRef.close();
  this.router.navigate(['/login']);
}

 doRegister(){
  this.dialogRef.close();
  this.router.navigate(['/account/dasboard']);
 }
 closeMe(){
  this.dialogRef.close();
 }

}
