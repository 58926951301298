<!-- .block-categories -->
<div class="block block--highlighted block-categories block-categories--layout--{{ layout }}">
    <div class="container">
        <app-block-header [header]="header"></app-block-header>

        <div class="block-categories__list">
            <div *ngFor="let category of categories" class="block-categories__item category-card category-card--layout--{{ layout }}">
                <div class="category-card__body">
                    <div *ngIf="category.image.includes('InvalidURL')" class="category-card__image">
<!--                         <a [routerLink]="category.url"><img [src]="category.image" width="70" alt=""></a> -->
<ngx-avatar [routerLink]="category.url" name="{{removeSpecialCharacters(category.title)}}" initialsSize="3" class="pointer" src="{{category.image}}" size="70"></ngx-avatar>
                    </div>
                    <div *ngIf="!category.image.includes('InvalidURL')" class="category-card__image">
                                   <a [routerLink]="category.url"><img [src]="category.image" width="70" alt=""></a>
                        
                                            </div>
                    <div class="category-card__content">
                        <!-- <div class="category-card__name">
                            <a [routerLink]="category.url">{{ category.title }}</a>
                        </div> -->
                         <!-- <ul class="category-card__links">
                            <li *ngFor="let category of category.subcategories"><a [routerLink]="category.url">{{ category.title }}</a></li>
                        </ul> -->
                        <!-- <div class="category-card__all">
                            <a [routerLink]="category.url">Show Products</a>
                        </div> -->
                        <!-- <div class="category-card__products">
                            {{ category.products }} Products
                        </div> -->
                        <nav class="  navbar-expand-lg ">
                            <div class="container-fluid"> 
                             <div class="collapse navbar-collapse" id="main_nav">
                              <ul class="navbar-nav">
                                   <li class="nav-item dropdown">
                                      <a *ngIf="category.subcategories" class="nav-link  dropdown-toggle" [routerLink]="category.url" data-bs-toggle="dropdown"> {{ category.title }}  </a>
                                      <a *ngIf="!category.subcategories" class="nav-link " [routerLink]="category.url" > {{ category.title }}  </a>
                                       <ul *ngIf="category.subcategories" class="dropdown-menu">
                            <li *ngFor="let categori of category.subcategories">
                                <a [routerLink]="categori.url" [queryParams]="{catName:category.title}" class="dropdown-item" href="#"> {{ categori.title }}</a></li>
                                      
                                       </ul>
                                   </li>
                              </ul>
                             </div> <!-- navbar-collapse.// -->
                            </div> <!-- container-fluid.// -->
                           </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- .block-categories / end -->
