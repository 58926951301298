import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators' 
import { AccountLoginService } from 'src/app/modules/account/services/account-login.service';

@Injectable()
export class AppInterceptors implements HttpInterceptor {
   constructor(private authService: AccountLoginService,  public router: Router){}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = sessionStorage.getItem("AuthorizationToken");
    const openShopToken = sessionStorage.getItem("openShopToken");
    const catalogToken = sessionStorage.getItem("catalogToken");
    const openShopSessionToken = sessionStorage.getItem("openShopSessionToken")
    //console.log(openShopToken);
    if(req.url.endsWith('/openshop')){

    req = req.clone({ headers: req.headers.set('Authorization',openShopToken) });
    } 

    else if (catalogToken && req.url.endsWith('/userdetails')) {
      req = req.clone({ headers: req.headers.set('Authorization', catalogToken) });
    }
    
    

   else if( openShopSessionToken && req.url.endsWith('/password')){
      req = req.clone({ headers: req.headers.set('Authorization', openShopSessionToken)});
    }
    else if (token && !req.url.endsWith('setpassword')) {
      req = req.clone({ headers: req.headers.set('Authorization', token) });
    }
    

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
   /*  return next.handle(req);
 */
    return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
       const tempToken = event.headers.get('Authorization')
        if (tempToken && tempToken.length) {
          this.authService.updateToken(tempToken)
        }
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) { 
        console.log('inteceptor')
          if (err.status === 401 ) {
          // this.appService.signOut()
    // this.authService.logout()
          this.router.navigate(['/login'])
        }
      }
    }))


  }

}